import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalContent, ProgressBar } from 'mw-style-react';
import { DEL_MODAL } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './FormEditorIframe.scss'; // NOSONAR

function FormEditorIframe(props) {
  const { data, callback, dispatch, onClose, visibility } = props;
  const accId = useSelector((state) => state.accounts.active);
  const [loading, setLoader] = useState(true);

  /**
   * Processing messages in iFrame
   */
  const handleIframeMessage = ({ type, data: fData }) => {
    switch (type) {
      case 'FORM_SAVED_IFRAME':
        callback(fData);
        dispatch({ type: DEL_MODAL, payload: 'FormEditorIframe' });
        break;
      case 'REMOVE_FORM_IFRAME':
        dispatch({ type: DEL_MODAL, payload: 'FormEditorIframe' });
        break;
      default:
        break;
    }
  };

  const renderIframe = () => {
    const url = AppUtils.makeUrl(`/form/${accId}/edit/${data.formId}`, {
      ...data,
      iFrame: true,
    });
    return (
      <iframe
        id={`formIframe_${data.formId || 0}`}
        styleName="sLocal.content__iframe"
        title={data.formId}
        src={url}
        onLoad={() => setLoader(false)}
      />
    );
  };

  useEffect(() => {
    window.addEventListener('message', (message) => {
      const { protocol, host } = document.location;
      if (message.origin !== `${protocol}//${host}`) return;
      handleIframeMessage(message.data);
    });
  }, []);

  return (
    <Modal
      styleName="sLocal.titleHead"
      size="xlarge"
      visibility={visibility}
      onClose={onClose}
    >
      <ModalContent styleName="sModal.modal__content sLocal.content">
        <div
          style={loading ? {} : { display: 'none' }}
          styleName="sLocal.content__iframe__loader"
        >
          <ProgressBar type="circle" size="large" />
        </div>
        {renderIframe()}
      </ModalContent>
    </Modal>
  );
}

FormEditorIframe.propTypes = {
  data: PropTypes.object,
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default FormEditorIframe;
