import { useEffect, useState } from 'react';
import { createLocalTracks, Track } from 'livekit-client';

/**
 * Custom hook to create a local video track for previewing visual effects
 */
export function usePreviewVideoTrack() {
  const [videoTrack, setVideoTrack] = useState(null);
  const [isCameraEnabled, setIsCameraEnabled] = useState(true);

  useEffect(() => {
    // Create a local video track when the component mounts
    async function createVideoTrack() {
      try {
        const tracks = await createLocalTracks({
          video: true,
          audio: false,
        });
        const track = tracks.find((track) => track.kind === Track.Kind.Video);
        if (track) {
          setVideoTrack(track);
        }
      } catch (error) {
        setIsCameraEnabled(false);
      }
    }

    createVideoTrack();

    // Clean up the video track when the component unmounts
    return () => {
      if (videoTrack) {
        videoTrack.stop();
      }
    };
  }, []);

  return { videoTrack, isCameraEnabled };
}
