import { CorezoidLightTheme as theme } from 'mw-style-react';
import { NODES_COLORS_PALETTE } from '@control-front-end/common/constants/graphActors';
import { GRAPH_CELL_SIZE, TEXT_NODE_DEFAULT_FONT_SIZE } from 'constants';
import AppUtils from '../utils';

/**
 * Убрать прозрачные пиксели из canvas
 * @param c
 * @returns {HTMLCanvasElement}
 */
function canvasTrimTransparentPixels(c) {
  const ctx = c.getContext('2d');
  const copy = document.createElement('canvas').getContext('2d');
  if (!c.width || !c.height) return c;
  const pixels = ctx.getImageData(0, 0, c.width, c.height);
  const l = pixels.data.length;
  const bound = {
    top: null,
    left: null,
    right: null,
    bottom: null,
  };

  for (let i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] === 0) continue;
    const x = (i / 4) % c.width;
    const y = Math.floor(i / 4 / c.width);
    if (bound.top === null) bound.top = y;
    if (bound.left === null || x < bound.left) bound.left = x;
    if (bound.right === null || bound.right < x) bound.right = x;
    if (bound.bottom === null || bound.bottom < y) bound.bottom = y;
  }

  const trimHeight = bound.bottom - bound.top;
  const trimWidth = bound.right - bound.left;
  if (!trimWidth || !trimHeight) return copy.canvas;
  const trimmed = ctx.getImageData(
    bound.left,
    bound.top,
    trimWidth,
    trimHeight
  );
  copy.canvas.width = trimWidth;
  copy.canvas.height = trimHeight;
  copy.putImageData(trimmed, 0, 0);
  return copy.canvas;
}

function hexToRgba(hex, opacity = 1) {
  const rgb = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
  return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity})`;
}

/**
 * Calculate the width of the text string based on the current font and style settings
 * in the canvas's 2D context without rendering (use canvas for graph grid if exist)
 */
function measureTextWidth({
  text,
  fontSize = theme.label.fontSize.medium,
  fontFamily = 'Open Sans,sans-serif',
  fontWeight = theme.label.fontWeight.semibold,
}) {
  const canvas =
    document.getElementById('gridRender') || document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
  return ctx.measureText(text).width;
}

/**
 * Wraps text into multiple lines based on the maximum width.
 * If the text exceeds the maximum height, an ellipsis ('...') is added.
 * @param {Object} params - Parameters object.
 * @param {Object} params.node - Graph node.
 * @param {string} params.text - The text to wrap.
 * @param {number} params.maxWidth - Maximum width for the text block.
 * @param {number} params.maxHeight - Maximum height for the text block.
 * @param {number} params.fontSize - Font size.
 * @param {string} params.fontFamily - Font family.
 * @param {string} params.fontWeight - Font weight.
 * @returns {string[]} - Array of wrapped text lines with ellipsis if needed.
 */

const wrappedTextCache = {
  cache: new Map(),
  maxSize: 1000,

  getKey({ text, maxWidth, scale = 1 }) {
    const roundedMaxWidth = Math.round(maxWidth * 100) / 100;
    const roundedScale = Math.round(scale * 100) / 100;
    return `${text}:${roundedMaxWidth}:${roundedScale}`;
  },

  get(key) {
    return this.cache.get(key);
  },

  set(key, value) {
    if (this.cache.size >= this.maxSize) {
      const oldestKey = this.cache.keys().next().value;
      this.cache.delete(oldestKey);
    }
    this.cache.set(key, value);
  },

  clear() {
    this.cache.clear();
  },
};

/**
 * Wraps text into multiple lines based on the maximum width with caching
 * @param {Object} params - Parameters
 * @param {string} params.text - Text to wrap
 * @param {number} params.maxWidth - Maximum width
 * @param {number} params.fontSize - Font size (for measurement)
 * @param {string} params.fontFamily - Font family (for measurement)
 * @param {number} params.scale - Scale factor (default: 1)
 * @returns {string[]} - Array of wrapped text lines
 */
function getWrappedText({
  text,
  maxWidth,
  fontSize = TEXT_NODE_DEFAULT_FONT_SIZE,
  fontFamily = 'Open Sans,sans-serif',
  scale = 1,
}) {
  if (!text) return [];

  const cacheKey = wrappedTextCache.getKey({ text, maxWidth, scale });

  const cachedResult = wrappedTextCache.get(cacheKey);
  if (cachedResult) {
    return cachedResult;
  }

  // Calculate effective maxWidth considering the scale
  const effectiveMaxWidth = maxWidth / scale;

  const lines = [];

  const rawLines = text.split('\n');

  for (const rawLine of rawLines) {
    let currentLine = '';
    if (rawLine.trim() === '') {
      lines.push('');
      continue;
    }

    for (const char of rawLine) {
      const testLine = currentLine + char;
      const testWidth = measureTextWidth({
        text: testLine,
        fontSize,
        fontFamily,
      });

      if (testWidth > effectiveMaxWidth && currentLine) {
        lines.push(currentLine);
        currentLine = char;
      } else {
        currentLine = testLine;
      }
    }

    if (currentLine) lines.push(currentLine);
  }

  wrappedTextCache.set(cacheKey, lines);

  return lines;
}

// Експортуємо функцію очищення кешу для можливості керування кешем ззовні
function clearWrappedTextCache() {
  wrappedTextCache.clear();
}

/**
 * Возвращает картингу polygon
 * @param polygon
 * @param color
 * @param zoom
 * @param opacity
 * @returns {{img: string, width: number, height: number}}
 */
function createImgByPolygon({ polygon, color: propColor, opacity = 0.4 }) {
  const color = propColor || NODES_COLORS_PALETTE.Simulator;
  const canvas = document.createElement('canvas');
  const xCoords = polygon.map((point) => point[0]);
  const yCoords = polygon.map((point) => point[1]);
  const minX = Math.min(...xCoords);
  const minY = Math.min(...yCoords);
  canvas.width = Math.max(...xCoords) - minX;
  canvas.height = Math.max(...yCoords) - minY;
  const ctx = canvas.getContext('2d');
  ctx.translate(-minX, -minY);
  ctx.beginPath();
  for (const position of polygon) {
    ctx.lineTo(position[0], position[1]);
  }
  ctx.lineWidth = 1;
  ctx.strokeStyle = hexToRgba(color, opacity);
  ctx.stroke();
  ctx.closePath();
  ctx.fillStyle = hexToRgba(color, opacity);
  ctx.fill();
  ctx.save();
  const trimCanvas = canvasTrimTransparentPixels(canvas);
  const img = trimCanvas.toDataURL('image/png');
  return {
    img,
    width: trimCanvas.width,
    height: trimCanvas.height,
    type: 'area',
  };
}

/**
 * Получить предельные координаты polygon
 * @param polygon
 * @returns {{minX: number, maxY: number, maxX: number, minY: number}}
 */
function getPolygonLimitCoord(polygon) {
  const xCoords = [];
  const yCoords = [];
  polygon.forEach((point) => {
    xCoords.push(point[0]);
    yCoords.push(point[1]);
  });
  const maxX = Math.max(...xCoords);
  const maxY = Math.max(...yCoords);
  const minX = Math.min(...xCoords);
  const minY = Math.min(...yCoords);
  return { maxX, maxY, minX, minY };
}

function getPolygonCenterCoord(polygon) {
  const { minX, minY, maxY, maxX } = this.getPolygonLimitCoord(polygon);
  const centerX = minX + (maxX - minX) / 2;
  const centerY = minY + (maxY - minY) / 2;
  return { x: centerX, y: centerY };
}

function snapPolygonToNewCenter(polygon, newPolygonCenter) {
  const prevPolygonCenter = this.getPolygonCenterCoord(polygon);
  const positionDiff = {
    x: newPolygonCenter.x - prevPolygonCenter.x,
    y: newPolygonCenter.y - prevPolygonCenter.y,
  };

  return polygon.map((point) => [
    point[0] + positionDiff.x,
    point[1] + positionDiff.y,
  ]);
}

function checkPointInsidePolygon(position, polygon, tolerance = 0) {
  const { minX, minY, maxX, maxY } = this.getPolygonLimitCoord(polygon);
  const { x, y } = position;
  return (
    x >= minX - tolerance &&
    x <= maxX + tolerance &&
    y >= minY - tolerance &&
    y <= maxY + tolerance
  );
}

function checkBoundingBoxInsidePolygon({ x1, x2, y1, y2 }, polygon, tolerance) {
  return (
    this.checkPointInsidePolygon({ x: x1, y: y1 }, polygon, tolerance) &&
    this.checkPointInsidePolygon({ x: x2, y: y2 }, polygon, tolerance)
  );
}

function getPolygonSize(polygon) {
  const { maxX, maxY, minX, minY } = this.getPolygonLimitCoord(polygon);
  return {
    width: Math.abs(maxX - minX),
    height: Math.abs(maxY - minY),
  };
}

function makeRectanglePolygon({ x1, y1, x2, y2 }) {
  return [
    [x1, y1],
    [x1, y2],
    [x2, y2],
    [x2, y1],
  ];
}

function makeDefaultPolygon(position = {}) {
  const { x = 0, y = 0 } = position;
  return AppUtils.makeRectanglePolygon({
    x1: x - GRAPH_CELL_SIZE,
    y1: y - GRAPH_CELL_SIZE,
    x2: x + GRAPH_CELL_SIZE,
    y2: y + GRAPH_CELL_SIZE,
  });
}

function shiftPolygon(polygon, { x, y }) {
  return polygon.map((point) => [point[0] + x, point[1] + y]);
}

function makeBoundingBoxFromPolygon(polygon) {
  const xs = polygon.map((point) => point[0]);
  const ys = polygon.map((point) => point[1]);

  const x1 = Math.min(...xs);
  const x2 = Math.max(...xs);
  const y1 = Math.min(...ys);
  const y2 = Math.max(...ys);

  return {
    x1,
    x2,
    y1,
    y2,
    w: x2 - x1,
    h: y2 - y1,
  };
}

function renderedCoordinateToPosition(renderedCoordinate, zoom, panCoordinate) {
  return (renderedCoordinate - panCoordinate) / zoom;
}

function renderRectangle({
  ctx,
  x,
  y,
  width,
  height,
  borderRadius = 0,
  color,
}) {
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(x + borderRadius, y);
  ctx.lineTo(x + width - borderRadius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
  ctx.lineTo(x + width, y + height - borderRadius);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - borderRadius,
    y + height
  );
  ctx.lineTo(x + borderRadius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius);
  ctx.lineTo(x, y + borderRadius);
  ctx.quadraticCurveTo(x, y, x + borderRadius, y);
  ctx.closePath();
  ctx.fill();
}

function drawSlice(ctx, color, x, y, radius, startAngle, endAngle) {
  ctx.beginPath();
  ctx.fillStyle = color;
  ctx.moveTo(x, y); // Move to the center of the pie
  ctx.arc(x, y, radius, startAngle, endAngle); // Draw the arc
  ctx.closePath();
  ctx.fill();
}

function renderPieChart(ctx, colors, position, radius) {
  if (!ctx || !Array.isArray(colors) || colors.length === 0) {
    return;
  }
  const { x, y } = position;
  const totalSlices = colors.length;
  const anglePerSlice = (2 * Math.PI) / totalSlices;
  // Start the chart at the top (12 o'clock position)
  const startAngle = -Math.PI / 2;
  ctx.save();
  colors.forEach((color, index) => {
    drawSlice(
      ctx,
      color,
      x,
      y,
      radius,
      startAngle + index * anglePerSlice,
      startAngle + (index + 1) * anglePerSlice
    );
  });
  ctx.restore();
}

function drawLine(ctx, coordinates) {
  const [x1, y1, x2, y2] = coordinates;
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();
}

function drawRectangleGrid({
  ctx,
  width,
  height,
  cellSize = GRAPH_CELL_SIZE,
  color,
}) {
  ctx.strokeStyle = color; // Grid line color
  ctx.lineWidth = 1;
  for (let x = 0; x <= width; x += cellSize) {
    drawLine(ctx, [x, 0, x, height]);
  }
  for (let y = 0; y <= height; y += cellSize) {
    drawLine(ctx, [0, y, width, y]);
  }
}

function hexToFullRgb(hex) {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
}

export default {
  canvasTrimTransparentPixels,
  createImgByPolygon,
  getPolygonLimitCoord,
  getPolygonCenterCoord,
  snapPolygonToNewCenter,
  getPolygonSize,
  makeRectanglePolygon,
  shiftPolygon,
  makeBoundingBoxFromPolygon,
  checkPointInsidePolygon,
  checkBoundingBoxInsidePolygon,
  hexToRgba,
  hexToFullRgb,
  renderedCoordinateToPosition,
  renderRectangle,
  renderPieChart,
  measureTextWidth,
  getWrappedText,
  clearWrappedTextCache, // Додаємо функцію очищення кешу
  drawRectangleGrid,
  makeDefaultPolygon,
};
