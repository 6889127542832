import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SET_MODAL,
  MANAGE_ACCESS_RULES,
  ACCESS_RULES_ACTIONS,
  OBJECT_TYPE,
} from 'constants';
import {
  STARRED_ACTOR,
  BULK_ADD_TO_GRAPH,
  GET_ACTOR_BY_REF,
} from '@control-front-end/common/constants/graphActors';
import {
  GET_LAYER,
  GET_ACTOR_LAYER,
  GET_TREE_LAYER,
  GET_ALL_LAYERS,
  SET_LAYER_SETTINGS,
  LAYER_ACCESS,
  CLOSE_LAYER,
  GET_LAYER_BALANCE,
  ADD_LAYER,
  GET_TRANSFERS_EDGES,
  TYPE_LAYER,
  SYSTEM_LAYERS_NAMES,
} from '@control-front-end/common/constants/graphLayers';
import { MANAGE_GRAPH_FOLDER } from '@control-front-end/common/constants/graphFolders';
import { SUBSCRIBE_GRAPH } from '@control-front-end/common/constants/graphRealtime';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import AppUtils from '@control-front-end/utils/utils';
import history from '@control-front-end/app/src/store/history';
import { makeGetActiveLayer, checkUserPermissions } from 'selectors';
import useActorsGraphActions from './useActorsGraphActions';
import { DEFAULT_NAME } from '../constants';

const SEARCH_SEPARATOR = '&aId=';

/**
 * Functions for working with layers
 */
const useLayerActions = ({ togglePanel }) => {
  const dispatch = useDispatch();
  const activeLayer = useSelector(makeGetActiveLayer) || {};
  const accId = useSelector((state) => state.accounts.active);
  const graphLayers = useSelector((state) => state.graphLayers);
  const graphFolders = useSelector((state) => state.graphFolders);
  const systemFormsLayers =
    useSelector((state) => state.systemForms.layers) || {};
  const systemForms = useSelector((state) => state.systemForms);
  const layersFormId = systemForms.layers.id;
  const checkPermissions = useSelector(checkUserPermissions);
  const canCreateLayers = checkPermissions([PERMISSIONS.ACTORS_MANAGEMENT]);
  const [loadLayerError, setLoadLayerError] = useState(null);

  const { handleCreateActor, handleUpdateActor, handleRemoveActor } =
    useActorsGraphActions({});

  const getNextLayerNumber = () => {
    const list = graphLayers.list.filter((i) => !i.isSystem);
    return list.length + 1;
  };

  /**
   * Load the actor layer
   */
  const handleGetActorLayer = (id, callback) => {
    dispatch({
      type: GET_ACTOR_LAYER.REQUEST,
      payload: {
        actorId: id,
        edgeType: 'hierarchy',
      },
      callback,
    });
  };

  /**
   * Load the actor tree layer
   */
  const handleGetTreeLayer = ({ id, edgeTypeId }, callback) => {
    dispatch({
      type: GET_TREE_LAYER.REQUEST,
      payload: {
        actorId: id.split('_')[0],
        edgeTypeId,
      },
      callback,
    });
  };

  /**
   * Close the layer
   */
  const handleCloseLayer = (id) => {
    dispatch({ type: CLOSE_LAYER.REQUEST, payload: { layerId: id } });
  };

  const handleAddLayer = (layer, graphFolderId) => {
    const isAlreadyInList = !!graphLayers?.list?.find((i) => i.id === layer.id);
    if (isAlreadyInList) return;
    dispatch({ type: ADD_LAYER.REQUEST, payload: { layer, graphFolderId } });
  };

  /**
   * Load the layer
   */
  const handleLoadLayer = ({
    id,
    graphFolderId,
    actorId,
    graphMode = TYPE_LAYER.layers,
    replaceUrl = false,
  }) => {
    if (!id) return;
    togglePanel?.(false);
    const graphId = graphFolderId || graphLayers.graphFolderId || '0';
    // save URL search params for iframe mode
    let search = document.location.search;
    const baseGraphUrl = `/actors_graph/${accId}/graph/${graphId}`;
    if (SYSTEM_LAYERS_NAMES[id]) {
      history.push(`${baseGraphUrl}/layers/system/${id}${search}`);
      return;
    }

    if (graphMode === TYPE_LAYER.trees) {
      const [aId, edgeTypeId] = id.split('_');
      history.push(`${baseGraphUrl}/trees/${aId}/${edgeTypeId}${search}`);
    } else {
      if (actorId && actorId !== id) search += `${SEARCH_SEPARATOR}${actorId}`;
      const layerUrl = `${baseGraphUrl}/${graphMode}/${id}${search}`;
      if (replaceUrl) {
        history.replace(layerUrl);
      } else {
        history.push(layerUrl);
      }
    }
  };

  /**
   * Add/remove graph layers
   */
  const handleManageGraphFolder = ({
    graphFolderId,
    layerId,
    action,
    callback,
    layerData,
    addLayer = false,
  }) => {
    dispatch({
      type: MANAGE_GRAPH_FOLDER.REQUEST,
      payload: { action, layerId, graphFolderId },
      callback: () => {
        if (action === 'delete') {
          handleCloseLayer(layerId);
        } else if (addLayer) {
          handleAddLayer(layerData, graphFolderId);
        } else {
          handleLoadLayer({ id: layerId, graphFolderId });
        }
        if (callback) callback();
      },
      errorCallback: () => {},
    });
  };

  /**
   * Create a new actor-layer
   */
  const handleCreateLayer = (layerProps) => {
    const {
      name,
      graphFolderId,
      ref,
      formData = {},
      callback,
      addLayer,
    } = layerProps;
    if (!formData.type) formData.type = 'graph';
    handleCreateActor({
      ref,
      form: systemFormsLayers,
      formData,
      title: name || `${DEFAULT_NAME.LAYER} ${getNextLayerNumber()}`,
      manageLayer: false,
      callback: (newActor) => {
        handleManageGraphFolder({
          graphFolderId,
          layerId: newActor.id,
          action: 'create',
          layerData: newActor,
          addLayer,
          callback: () => {
            if (callback) {
              callback(newActor);
            } else {
              history.replace(
                AppUtils.makeUrl(
                  `/actors_graph/${accId}/graph/${graphFolderId}/layers/${newActor.id}`
                )
              );
            }
          },
        });
      },
    });
  };

  /**
   * Create layer and add it to active graph
   */
  const createLayerToGraphFolder = ({
    graphFolderId,
    name = DEFAULT_NAME.LAYER,
    ref,
    addToLayers = false,
    callback,
  }) => {
    handleCreateLayer({
      graphFolderId,
      name,
      ref,
      addToLayers,
      callback: (newLayer) => {
        if (callback) callback(newLayer);
        else handleLoadLayer({ id: newLayer.id, graphFolderId });
      },
    });
  };

  /**
   * Load a system layer
   */
  const handleLoadSystemLayer = (
    {
      id,
      graphFolderId,
      actorId,
      graphMode = TYPE_LAYER.layers,
      replaceUrl = false,
    },
    activeFolderModify
  ) => {
    if (!id) return;
    togglePanel?.(false);
    const graphId = graphFolderId || graphLayers.graphFolderId || '0';
    const systemLayerRef = `${graphId}_${id}`;
    const title = SYSTEM_LAYERS_NAMES[id];

    dispatch({
      type: GET_ACTOR_BY_REF.REQUEST,
      payload: {
        formId: layersFormId,
        ref: systemLayerRef,
      },
      callback: (actor) => {
        handleLoadLayer({
          id: actor.id,
          graphFolderId: graphId,
        });
      },
      errorCallback: (data) => {
        if (data.statusCode === 404) {
          if (activeFolderModify && canCreateLayers) {
            createLayerToGraphFolder({
              ref: systemLayerRef,
              graphFolderId: graphId,
              name: title,
              addToLayers: true,
              callback: (newLayer) => {
                handleLoadLayer({
                  id: newLayer.id,
                  graphFolderId: graphId,
                });

                if (!graphFolders.list.length) return;

                // Find the corresponding graph folder
                const graphFolder = graphFolders.list.find(
                  (i) => i.id === activeLayer.graphFolderId
                );
                if (!graphFolder) return;

                const { access: folderAccess } = graphFolder;
                const existingAccess = new Set(
                  newLayer.access.map((user) => user.userId)
                );
                const body = [];

                // Add missing users from the folder to the layer
                folderAccess.forEach((folderUser) => {
                  if (!existingAccess.has(folderUser.userId)) {
                    body.push({
                      ...folderUser,
                      privs: { ...folderUser.privs, view: true },
                      action: ACCESS_RULES_ACTIONS.create,
                    });
                  }
                });

                if (!body.length) return;

                dispatch({
                  type: MANAGE_ACCESS_RULES.REQUEST,
                  payload: {
                    body,
                    objId: newLayer.id,
                    objType: OBJECT_TYPE.actor,
                    recursive: true,
                  },
                });
              },
            });
          } else {
            handleLoadLayer({
              id,
              graphFolderId,
              actorId,
              graphMode,
              replaceUrl,
            });
          }
        }
      },
    });
  };

  /**
   * Load graph layers and navigate to the first one
   */
  const handleGetGraphFolderLayers = (activeFolder, layerId, callback) => {
    const { id: graphFolderId } = activeFolder;
    dispatch({
      type: GET_ALL_LAYERS.REQUEST,
      payload: { graphFolderId, limit: 50 },
      callback: (layers) => {
        if (graphFolderId && layerId === '0') {
          const findFirst = layers.find(
            (i) => i.graphFolderId === graphFolderId && !i.isSystem
          );
          if (findFirst) {
            handleLoadLayer({
              id: findFirst.id,
              graphFolderId,
              replaceUrl: true,
            });
          } else if (
            activeFolder &&
            activeFolder.privs.modify &&
            canCreateLayers
          ) {
            createLayerToGraphFolder({ graphFolderId });
          }
          return;
        }
        callback(layers);
      },
    });
    if (togglePanel) togglePanel();
  };

  /**
   * Get the layer along with actors
   */
  const handleGetLayer = ({ id, graphFolderId, callback }) => {
    if (setLoadLayerError) setLoadLayerError(null);
    if (!id) return;
    dispatch({
      type: GET_LAYER.REQUEST,
      payload: {
        layerId: id,
        graphFolderId,
        addToLayers: true,
      },
      callback,
      errorCallback: (error) =>
        setLoadLayerError({
          layerId: id,
          ...error,
        }),
    });
  };

  /**
   * Get layer transfers edges
   */
  const handleGetLayerTransfersEdges = (p) => {
    const {
      layerId,
      typeLayer,
      nameId,
      currencyId,
      accountName,
      currencyName,
      currencyParams,
      from,
      to,
    } = p;
    if (typeLayer !== 'layers') return;
    dispatch({
      type: GET_TRANSFERS_EDGES.REQUEST,
      payload: {
        layerId,
        nameId,
        currencyId,
        accountName,
        currencyName,
        currencyParams,
        from,
        to,
      },
    });
  };

  /**
   * Get actor balances of a layer
   */
  const handleGetLayerBalance = ({
    layerId,
    currencyId,
    nameId,
    from,
    to,
    currencyParams,
  }) => {
    dispatch({
      type: GET_LAYER_BALANCE.REQUEST,
      payload: {
        layerId,
        currencyId,
        nameId,
        from,
        to,
        currencyParams,
      },
    });
  };

  /**
   * Open a modal with layer information
   */
  const handleShowDetails = (title, details) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          id: 'layerDetails',
          extraTitle: title,
          content: details,
          buttons: [],
        },
      },
    });
  };

  /**
   * Save everything as a new layer
   */
  const handleSaveAsLayer = () => {
    const addElementsToLayer = (layer) => {
      const { nodes, edges } = activeLayer;
      dispatch({
        type: BULK_ADD_TO_GRAPH.REQUEST,
        payload: {
          layerId: layer.id,
          nodes: nodes.filter((i) => i.status !== 'removed').map((i) => i.data),
          edges: edges.filter((i) => i.status !== 'removed').map((i) => i.data),
        },
        callback: () =>
          handleLoadLayer({
            id: layer.id,
            graphFolderId: graphLayers.graphFolderId,
          }),
      });
    };
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'SaveWithName',
        data: {
          name: 'New layer',
          title: 'Save as a layer',
          btnText: 'Save and continue',
        },
        callback: (name) => {
          createLayerToGraphFolder({
            graphFolderId: graphLayers.graphFolderId,
            name,
            callback: addElementsToLayer,
          });
        },
      },
    });
  };

  /**
   * Update the layer (rename or add an image)
   */
  const handleUpdateLayer = (props) => {
    const formId = systemFormsLayers.id;
    handleUpdateActor({ ...props, formId });
  };

  /**
   * Update layer settings with an image
   */
  const handleSetLayerSettings = (settings = {}) => {
    dispatch({
      type: SET_LAYER_SETTINGS.REQUEST,
      payload: {
        layerId: activeLayer.id,
        settings,
      },
    });
  };

  /**
   * Delete the layer
   */
  const handleRemoveLayer = (id) => {
    handleRemoveActor(id);
  };

  /**
   * Pin/unpin layer in layers toolbar
   */
  const handlePinLayer = (id, starred) => {
    dispatch({
      type: STARRED_ACTOR.REQUEST,
      payload: { actorId: id, starred },
    });
  };

  /**
   * Grant access to the graph containing the layer
   */
  const shareGraphFolder = (layerAccess) => {
    if (!graphFolders.list.length) return;
    const graphFolder = graphFolders.list.find(
      (i) => i.id === activeLayer.graphFolderId
    );
    if (!graphFolder) return;
    const folderBody = [];
    const { access: folderAccess } = graphFolder;
    layerAccess.forEach((layerUser) => {
      const findUser = folderAccess.find((u) => u.userId === layerUser.userId);
      if (!findUser || !findUser.privs.view) {
        const newPrivs = findUser
          ? { ...findUser.privs, view: true }
          : {
              view: true,
              modify: false,
              remove: false,
            };
        const action = findUser ? 'update' : 'create';
        folderBody.push({ ...layerUser, ...newPrivs, action });
      }
    });
    if (!folderBody.length) return;
    dispatch({
      type: MANAGE_ACCESS_RULES.REQUEST,
      payload: {
        body: folderBody,
        objId: graphFolder.id,
        objType: 'actor',
        recursive: 'false',
      },
    });
  };

  /**
   * Open a modal to manage layer access
   */
  const handleShareLayer = (layer) => {
    let objType;
    let objId;
    if (layer.typeLayer === 'layers') {
      objType = 'actor';
      objId = layer.layerId;
    } else if (layer.typeLayer === 'trees') {
      objType = 'treeLayer';
      objId = layer.id.split('_')[0];
    }
    if (!objType) return;
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'ManageAccessRules',
        data: {
          objType,
          objId,
          rules: layer.access,
          ownerId: layer.user.id,
        },
        callback: (data) => {
          dispatch({
            type: LAYER_ACCESS.REQUEST,
            payload: { layerId: layer.id, access: data },
          });
          if (layer.typeLayer === 'layers') shareGraphFolder(data);
        },
      },
    });
  };

  /**
   * Subscribe/unsubscribe to real-time changes on the layer
   * @param id
   * @param params
   * @param status
   */
  const handleSubscribeRealtime = ({ id, params, status }) => {
    let obj;
    let subId;
    const graphMode = params.graphMode || TYPE_LAYER.layers;
    switch (graphMode) {
      case 'layers':
        obj = 'layer';
        subId = id;
        break;
      case 'actors':
        obj = 'actor';
        subId = id;
        break;
      case 'trees':
        obj = 'accountTree';
        subId = params.edgeTypeId;
        break;
      default:
    }
    dispatch({
      type: SUBSCRIBE_GRAPH.REQUEST,
      payload: { id: subId, obj, status },
    });
  };

  /**
   * Open the layer in a new browser tab
   */
  const handleOpenLayerNewTab = (id, formId) => {
    const accId = AppUtils.getAccountId(history.location.pathname);
    const isLayerActor = systemFormsLayers.id === formId;
    const graphMode = isLayerActor ? 'layers' : 'actors';
    const layerUrl = AppUtils.makeUrl(
      `/actors_graph/${accId}/graph/0/${graphMode}/${id}`
    );
    AppUtils.openTabWithNewId(layerUrl);
  };

  /**
   * Open the actor's layer
   */
  const handleOpenActorsLayer = ({ actorId }) => {
    const accId = AppUtils.getAccountId(history.location.pathname);
    if (!actorId || !accId) return;
    history.push(`/actors_graph/${accId}/graph/0/actors/${actorId}`);
  };

  const handleAddLayerTab = ({ layer, graphFolderId }) => {
    handleManageGraphFolder({
      graphFolderId,
      layerId: layer.id,
      layerData: { ...layer, isCustom: true },
      action: 'create',
      addLayer: true,
    });
  };

  return {
    handleGetGraphFolderLayers,
    handlePinLayer,
    handleGetLayer,
    handleCreateLayer,
    handleSaveAsLayer,
    handleShareLayer,
    handleLoadLayer,
    handleLoadSystemLayer,
    handleShowDetails,
    handleUpdateLayer,
    handleSetLayerSettings,
    handleRemoveLayer,
    handleCloseLayer,
    handleGetActorLayer,
    handleGetTreeLayer,
    handleManageGraphFolder,
    handleGetLayerBalance,
    handleSubscribeRealtime,
    handleOpenLayerNewTab,
    handleOpenActorsLayer,
    handleGetLayerTransfersEdges,
    createLayerToGraphFolder,
    loadLayerError,
    handleAddLayerTab,
  };
};

export default useLayerActions;
