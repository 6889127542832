import './publicPath';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from './store';
import AppContainer from './containers/AppContainer';
import localizationConf from '../../../bin/localizationConf';
import { INIT_TRANSLATIONS } from '../../common/constants/index';

// ========================================================
// Polyfill for structuredClone
// ========================================================
if (typeof window.structuredClone !== 'function') {
  import('buffer').then((m) => {
    window.Buffer = m.Buffer;
    import('structured-clone').then((module) => {
      window.structuredClone = module.default;
    });
  });
}

// ========================================================
// Localization language
// ========================================================
let locale = navigator.language;
if (localizationConf.locales.indexOf(locale) === -1) {
  locale = 'en';
}

// ========================================================
// Render settings
// ========================================================
const MOUNT_NODE = document.getElementById('root');
const ROOT_NODE = createRoot(MOUNT_NODE);

let render = (messages) => {
  // If there is no Intl - load polyfill
  const App = (
    <AppContainer store={store} messages={messages} locale={locale} />
  );
  if (!window.Intl) {
    require.ensure(
      [],
      (require) => {
        require('intl');
        require('intl/locale-data/jsonp/en.js');
        ROOT_NODE.render(App);
      },
      'intl'
    );
  } else {
    ROOT_NODE.render(App);
  }
};

// ========================================================
// Dynamic loading of the localization file
// ========================================================
store.dispatch({
  type: INIT_TRANSLATIONS.REQUEST,
  payload: { locale },
  callback: (messages) => {
    if (__DEV__) {
      // Development render functions
      const renderApp = render;
      const renderError = (error) => {
        const RedBox = require('redbox-react').default; // NOSONAR
        ROOT_NODE.render(<RedBox error={error} />);
      };

      // Render wrapper in try/catch
      render = (mes) => {
        try {
          renderApp(mes);
        } catch (error) {
          console.error(error); // eslint-disable-line no-console
          renderError(error);
        }
      };
    }
    // ========================================================
    // Application start
    // ========================================================
    render(messages);
  },
});
