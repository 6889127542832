import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { VirtualBackground, BackgroundBlur } from '@livekit/track-processors';
import AppUtils from '@control-front-end/utils/utils';
import { VIDEO_BACKGROUND_EFFECT } from '../../../constants/meeting';

const BLUR_RADIUS = 20;

/**
 * Manages the application of visual effects for a participant's video during a meeting.
 *
 * This function utilizes the participant's camera track and applies visual effects such as
 * virtual background images based on the current meeting settings. Effects are dynamically applied
 * or removed when meeting settings or camera state change.
 */
const useVisualEffects = ({ videoTrack, isCameraEnabled }) => {
  const { virtualBgImages, activeVirtualEffect, activeBgImageId } = useSelector(
    (state) => state.settings.meeting
  );

  const getBackgroundImgPath = () => {
    const activeBgImage = virtualBgImages.find(
      (file) => file.id === activeBgImageId
    );
    return activeBgImage ? AppUtils.makeUrl(activeBgImage.filePath) : null;
  };

  useEffect(() => {
    // Only apply effects on Chrome or Chromium-based browsers
    if (!AppUtils.isChromiumBased()) return;

    const applyEffects = debounce(async () => {
      try {
        if (activeVirtualEffect) {
          const videoTrackProcessor =
            activeVirtualEffect === VIDEO_BACKGROUND_EFFECT.BLUR
              ? BackgroundBlur(BLUR_RADIUS)
              : VirtualBackground(getBackgroundImgPath());
          await videoTrack.setProcessor(videoTrackProcessor);
        } else {
          await videoTrack.stopProcessor();
        }
      } catch (error) {
        console.error('Failed to apply video effects:', error); // eslint-disable-line
      }
    }, 100);

    if (videoTrack && isCameraEnabled) {
      applyEffects();
    }

    return () => {
      applyEffects.cancel(); // Cancel any pending calls
    };
  }, [videoTrack, isCameraEnabled, activeVirtualEffect, activeBgImageId]);

  useEffect(() => {
    return async () => {
      if (videoTrack) {
        try {
          if (videoTrack.processor) {
            await videoTrack.stopProcessor();
          }
        } catch (error) {
          console.error('Failed to cleanup video effects:', error); // eslint-disable-line
        }
      }
    };
  }, []);
};

export default useVisualEffects;
