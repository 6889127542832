import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  Label,
} from 'mw-style-react';
import useIntl from 'useIntl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './DiscardChanges.scss'; // NOSONAR
import mes from './intl';

function DiscardChanges(props) {
  const { visibility, onSubmit, onClose } = props;
  const t = useIntl();

  useEffect(() => {
    const modalBg = document.getElementById('confirmModal');
    if (modalBg && visibility) {
      modalBg.style.zIndex = 9999;
      modalBg.style.backgroundColor = 'rgba(57, 63, 70, 0.6)';
    }
  }, [visibility]);

  return (
    <Modal
      id="confirmModal"
      styleName="m.modal"
      size="small"
      visibility={visibility}
      onClose={onClose}
      label={t(mes.discardChanges)}
      dataTestId="modalDiscardChanges"
    >
      <ModalContent styleName="m.modal__content">
        <Label value={t(mes.discardQuestion)} />
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={t(mes.discard)}
          size="medium"
          fontWeight="normal"
          dataTestId="modalDiscardChanges-submit"
          onClick={onSubmit}
        />
      </ModalButtons>
    </Modal>
  );
}

DiscardChanges.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default DiscardChanges;
