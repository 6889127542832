import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import useIntl from 'useIntl';
import mes from './intl';
import './Balance.scss';

/**
 * Balance display
 */
function Balance(props) {
  const t = useIntl();
  const { debit, credit, currencyName } = props;
  const { currencyType: type, precision, symbol } = debit;
  const currencyParams = { type, precision, symbol };

  const renderIcon = (
    { incomeType, icon } // eslint-disable-line
  ) => (
    <div styleName="balance__icon">
      <div styleName={cn('balance__icon__inner', incomeType)}>
        <Icon size="micro" type={icon} />
      </div>
    </div>
  );

  const renderBalanceItem = (typeA) => (
    <TableRow styleName="table__row">
      <TableCell styleName="table__cell actor">{t(mes[typeA])}</TableCell>
      <TableCell styleName="table__cell">
        {AppUtils.formattedAmount(debit[typeA], currencyParams)} {currencyName}
      </TableCell>
      <TableCell styleName="table__cell">
        {AppUtils.formattedAmount(credit[typeA], currencyParams)} {currencyName}
      </TableCell>
      <TableCell styleName="table__cell">
        {AppUtils.formattedAmount(credit[typeA] - debit[typeA], currencyParams)}{' '}
        {currencyName}
      </TableCell>
    </TableRow>
  );

  return (
    <Table styleName="table" paddingSize="small">
      <TableHead>
        <TableRow styleName="table__row">
          <TableCell styleName="table__cell balance">{t(mes.type)}</TableCell>
          <TableCell styleName="table__cell">
            {renderIcon({ incomeType: 'debit', icon: 'arrow_thin' })}{' '}
            {t(mes.debit)}
          </TableCell>
          <TableCell styleName="table__cell">
            {renderIcon({ incomeType: 'credit', icon: 'arrow_thin' })}{' '}
            {t(mes.credit)}
          </TableCell>
          <TableCell styleName="table__cell">
            {renderIcon({ incomeType: 'total', icon: 'sum' })} {t(mes.total)}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renderBalanceItem('availableAmount')}
        {renderBalanceItem('amount')}
        {renderBalanceItem('holdAmount')}
      </TableBody>
    </Table>
  );
}

Balance.propTypes = {
  debit: PropTypes.object,
  credit: PropTypes.object,
  currencyName: PropTypes.string,
};

export default Balance;
