import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuItem,
  Button,
  DateUtils,
  Calendar,
  Popover,
  cr,
} from 'mw-style-react';
import cn from 'classnames';
import { DATE_FORMAT_2 } from 'constants';
import { DEFAULT_RANGES } from '@control-front-end/common/constants/graphActors';
import AppUtils from '@control-front-end/utils/utils';
import useIntl from 'useIntl';
import mes from './intl';
import './BalancePeriod.scss';

function MainMenu({ from, calendar, subMenu, rangeType, onClick }) {
  const t = useIntl();
  return (
    <Menu styleName="period__menu" onClick={onClick}>
      <MenuItem
        styleName="period__menu__item"
        id="all"
        value="all"
        label={t(mes.range_all)}
        leftIcon={null}
        rightIcon={rangeType === 'all' ? 'check' : null}
        activeItem={rangeType}
      />
      <MenuItem
        styleName={cn('period__menu__item', {
          active: calendar === 'date' && !subMenu,
        })}
        id="date"
        value="date"
        leftIcon="arrow"
        rightIcon={rangeType !== 'all' && !from ? 'check' : null}
        label={t(mes.balanceDate)}
      />
      <MenuItem
        styleName={cn('period__menu__item', {
          active: calendar === 'range' || subMenu,
        })}
        id="range"
        value="range"
        leftIcon="arrow"
        rightIcon={rangeType !== 'all' && !!from ? 'check' : null}
        label={t(mes.balanceRange)}
      />
    </Menu>
  );
}

/**
 * Date/range selection component for displaying account balances
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function BalancePeriod(props) {
  const { from, to, onChange, type = 'default' } = props;
  const t = useIntl();
  const [subMenu, toggleSubmenu] = useState(false);
  const [calendar, toggleCalendar] = useState(null);

  const initialRangeType = useMemo(() => {
    let result = 'all';
    if (from && to) {
      result =
        AppUtils.getTimeFrameName({ from: from / 1000, to: to / 1000 }) ||
        'custom';
    } else if (to) {
      result = 'custom';
    }
    return result;
  }, []);

  const [rangeType, setRangeType] = useState(initialRangeType);
  const todayDate = DateUtils.unixtime();

  /**
   * Apply range in ms
   */
  const applyFromTo = (value) => {
    const newRange = {};
    if (value.from) {
      newRange.from = value.from * 1000;
    }
    if (value.to) {
      newRange.to = value.to * 1000;
    }
    onChange(newRange);
  };

  /**
   * Select range from list
   */
  const handleChangeRange = (value) => {
    const newDateRange = value !== 'all' ? AppUtils.getRangeDate(value) : {};
    setRangeType(value);
    applyFromTo(newDateRange);
  };

  /**
   * Handle click on ranges
   */
  const handleClickSubMenu = ({ value, onClose }) => {
    if (value === 'custom') {
      toggleCalendar('range');
    } else {
      handleChangeRange(value);
      onClose();
    }
  };

  /**
   * Select custom date/range in calendar
   */
  const handleChangeDate = ({ value }) => {
    const newDateRange = {
      to: DateUtils.endOf(value.endDate * 1000, 'day') / 1000,
    };
    if (calendar === 'range') {
      newDateRange.from =
        DateUtils.startOf(value.startDate * 1000, 'day') / 1000;
    }
    setRangeType('custom');
    applyFromTo(newDateRange);
  };

  /**
   * Handle click in menu
   */
  const handleClickMenu = ({ value }) => {
    if (value === 'date') {
      toggleCalendar('date');
    } else if (value === 'range') {
      toggleCalendar(null);
      toggleSubmenu(true);
    } else {
      handleChangeRange(value);
    }
  };

  const renderCalendar = ({ type, onClose }) => {
    const startDate = from ? from / 1000 : todayDate;
    const endDate = to ? to / 1000 : todayDate;

    return (
      <Calendar
        styleName="period__calendar"
        size="small"
        dateRange={type === 'range'}
        value={from ? { startDate, endDate } : { startDate: endDate, endDate }}
        onChange={({ value }) => {
          handleChangeDate({ value });
          onClose();
        }}
      />
    );
  };

  const renderSubMenu = ({ onClose }) => (
    <Menu
      width={200}
      onClick={({ value }) => {
        handleClickSubMenu({ value, onClose });
      }}
    >
      {DEFAULT_RANGES.map((item) => (
        <MenuItem
          styleName={cn('period__menu__item', {
            active: item === 'custom' && calendar === 'range',
          })}
          key={item}
          id={item}
          value={item}
          label={t(mes[`range_${item}`])}
          leftIcon={item === 'custom' ? 'arrow' : null}
          rightIcon={rangeType === item ? 'check' : null}
        />
      ))}
    </Menu>
  );

  let label = t(mes[`range_${rangeType}`]);
  if (rangeType === 'custom') {
    label = from
      ? `${DateUtils.toDate(from / 1000, DATE_FORMAT_2)} - ${DateUtils.toDate(
          to / 1000,
          DATE_FORMAT_2
        )}`
      : DateUtils.toDate(to / 1000, DATE_FORMAT_2);
  }

  return (
    <div styleName={cn('period', type)}>
      <Popover
        topLevel
        padding={4}
        onClose={() => {
          toggleCalendar(null);
          toggleSubmenu(false);
        }}
        content={({ onClose }) =>
          cr(
            [calendar, () => renderCalendar({ type: calendar, onClose })],
            [subMenu, () => renderSubMenu({ onClose })],
            [
              true,
              <MainMenu
                from={from}
                calendar={calendar}
                subMenu={subMenu}
                rangeType={rangeType}
                onClick={handleClickMenu}
              />,
            ]
          )
        }
      >
        <Button
          styleName="period__btn"
          type={type === 'default' ? 'tertiary' : 'text'}
          size="smallplus"
          icon="calendar"
          label={label}
        />
      </Popover>
    </div>
  );
}

BalancePeriod.propTypes = {
  type: PropTypes.oneOf(['default', 'text']),
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

export default BalancePeriod;
