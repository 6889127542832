import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useIntl from 'useIntl';
import useOutsideClick from 'useOutsideClick';
import {
  Label,
  Menu,
  MenuItem,
  Icon,
  TextField,
  forwardRef,
} from 'mw-style-react';
import './FilterSelect.scss';
import mes from './intl';

const ForwardTextField = forwardRef(TextField);

/**
 * Filter
 */
function FilterSelect(props) {
  const {
    id,
    label,
    listLabel,
    value,
    type = 'default',
    visibility = 'visible',
    liveSearch = false,
    children,
    onSelect,
  } = props;
  const t = useIntl();
  const filtersBox = useRef();
  const [menu, toggleMenu] = useState(false);
  const [stateValue, setValue] = useState(value ? value.toString() : '');
  const [search, setSearch] = useState('');

  const handleCLoseMenu = () => {
    setSearch('');
    toggleMenu(false);
  };

  useOutsideClick({ ref: filtersBox, callback: handleCLoseMenu });

  const getTitle = () => {
    const findI = children.find(
      (i) => i.value.toString() === stateValue.toString()
    );
    return findI ? findI.title : value;
  };

  const handleSelect = (data) => {
    if (visibility !== 'visible') return;
    setValue(data.value);
    if (onSelect) onSelect(data);
    toggleMenu(false);
  };

  const handleToggleMenu = () => {
    if (visibility !== 'visible') return;
    toggleMenu(!menu);
  };

  /**
   * Filtering by search string
   */
  const filterList = () => {
    const searchString = search.trim();
    try {
      const searchRegExp = new RegExp(searchString.slice(0, 255), 'gi');
      return children.filter((item) => item.title.match(searchRegExp));
    } catch (e) {
      return [];
    }
  };

  const items = search.length ? filterList() : children;
  const itemsLabel = search.length
    ? `${t(mes.searchResults)}: ${items.length}`
    : listLabel;

  return (
    <div styleName={cn('fs', visibility)}>
      <div styleName={cn('fs__type', type)} onClick={handleToggleMenu}>
        <span styleName="fs__type__title">{label}:</span>
        <span styleName="fs__type__value">{getTitle()}</span>
        {visibility === 'visible' ? (
          <Icon type="dropdown" size="micro" />
        ) : null}
      </div>
      {menu ? (
        <div styleName="fs__wrap" ref={filtersBox}>
          <ForwardTextField
            id="search"
            styleName="fs__menu__search__field"
            size="small"
            leftIcon="search"
            value={search}
            placeholder={t(mes.search)}
            autoFocus={true}
            rightIcon={search.length ? 'close' : ''}
            onClickRightIcon={() => setSearch('')}
            onChange={(data) => setSearch(data.value)}
            visibility={liveSearch ? 'visible' : 'hidden'}
          />
          {itemsLabel ? (
            <Label
              styleName="fs__menu__title"
              fontWeight="semibold"
              value={itemsLabel}
            />
          ) : null}
          <Menu
            id={id}
            styleName="fs__menu"
            size="small"
            visibility={menu ? 'visible' : 'hidden'}
            onClick={(data) => handleSelect(data)}
          >
            {items.map((i) => (
              <MenuItem
                key={i.value}
                size="small"
                value={i.value}
                label={i.title}
                rightIcon={i.value.toString() === stateValue ? 'check' : null}
              />
            ))}
          </Menu>
        </div>
      ) : null}
    </div>
  );
}

FilterSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  listLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['default', 'compact']),
  children: PropTypes.array,
  visibility: PropTypes.oneOf(['visible', 'disabled']),
  liveSearch: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default FilterSelect;
