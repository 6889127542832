import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
  withForm,
  TabItem,
  Tab,
  Banner,
  RadioItem,
  Radio,
  Stack,
  cr,
} from 'mw-style-react';
import { useNotifications, useIntl } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import { DEL_MODAL, MANAGE_ACCESS_RULES, GET_ACCESS_RULES } from 'constants';
import {
  SAVE_ACCOUNT_PAIR,
  CREATE_ACTORS_ACCOUNT,
  UPDATE_ACTORS_ACCOUNT,
  BULK_CREATE_ACCOUNTS,
  SAVE_ACCOUNT_ACTORS,
  GET_ACCOUNT_ACTORS,
  CHECK_ACCOUNT_PAIR,
  PairStatus,
  ACCOUNT_TYPE,
} from '@control-front-end/common/constants/actorAccounts';
import { GET_ACTOR } from '@control-front-end/common/constants/graphActors';
import { UPDATE_FORM } from '@control-front-end/common/constants/forms';
import SelectActors from '@control-front-end/common/components/SelectActors';
import SelectAccountCurrency from '@control-front-end/common/components/SelectAccountCurrency';
import SelectAccountName from '@control-front-end/common/components/SelectAccountName';
import AccAdvancedOptions from '@control-front-end/common/components/AccAdvancedOptions';
import AccessRules from '@control-front-end/common/components/AccessRules';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import CreateFormFieldAccount from './CreateFormFieldAccount';
import { checkUserPermissions } from '../../../../selectors';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './CreateActorAccount.scss'; // NOSONAR

function CreateActorAccountBase(props) {
  const {
    values,
    handleOnChange,
    visibility,
    onClose,
    isSubmit,
    errors: initialErrors,
    data: {
      accounts = [],
      excludedCurrencies = [],
      mode = 'create',
      actorId,
      actors,
      accData = {},
      showActorField = false,
      forms,
      callback,
    },
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const { showNotification } = useNotifications();
  const [activeTab, setActiveTab] = useState('details');
  const [pairStatus, setPairStatus] = useState(null);
  const [tagsEdited, setTagsEdited] = useState(false);
  const [triggersEdited, setTriggersEdited] = useState(false);
  const [errors, setErrors] = useState(initialErrors || {});
  const [excludedList, setExcludedList] = useState([]);
  const [actorForms, setActorForms] = useState(forms);
  const checkPermissions = useSelector(checkUserPermissions);
  const systemForms = useSelector((state) => state.systemForms) || {};
  const systemFormsTags = systemForms.tags || {};
  const systemFormsTriggers = systemForms.accounttriggers || {};
  const { accountName, currency } = values;
  const pairSelected = accountName.id && currency.id;
  const { minLimit, maxLimit } = values;
  const noAccess = pairStatus === PairStatus.ACCESS_DENIED;
  const isActorSelected = actorId || actors || values.actor;

  const [formFieldAccount, setFormFieldAccount] = useState(null);

  const getModalTitle = () => {
    if (mode === 'create') {
      return accData.nameId && !accData.currencyId
        ? t(mes.createAccountCurrencyPair)
        : t(mes.createNewAccount);
    }
    return pairSelected ? t(mes.managePairActors) : t(mes.editAccount);
  };

  /**
   * Get account's actors (triggers & tags)
   */
  const getAccountAccountActors = ({ formId, callback }) => {
    if (mode === 'edit' && (values.tags.length || values.triggers.length))
      return;
    dispatch({
      type: GET_ACCOUNT_ACTORS.REQUEST,
      payload: { nameId: accountName.id, currencyId: currency.id, formId },
      callback: (data) => {
        const value = data.map((i) => ({ ...i, id: i.actorId }));
        callback(value);
      },
    });
  };

  /**
   * Get pair access rules
   */
  const getAccountPairAccess = () => {
    dispatch({
      type: GET_ACCESS_RULES.REQUEST,
      payload: {
        objType: 'account',
        objId: `${accountName.id}_${currency.id}`,
      },
      callback: (data) => handleOnChange({ id: 'access', value: data }),
    });
  };

  /**
   * Check existance of accounts pair
   */
  const checkAccPair = () => {
    dispatch({
      type: CHECK_ACCOUNT_PAIR.REQUEST,
      payload: {
        accountName: accountName.name,
        currencyName: currency.name,
      },
      callback: ({ status }) => {
        setPairStatus(status);
        if (status === PairStatus.EXIST) {
          getAccountPairAccess();
          getAccountAccountActors({
            formId: systemFormsTags.id,
            callback: (value) => {
              handleOnChange({ id: 'tags', value });
              getAccountAccountActors({
                formId: systemFormsTriggers.id,
                callback: (value) => handleOnChange({ id: 'triggers', value }),
              });
            },
          });
        }
      },
    });
  };

  useEffect(() => {
    if (!accountName.id || !currency.id) return;
    checkAccPair();
  }, [accountName.id, currency.id]);

  useEffect(() => {
    if (accounts.length && accountName.id) {
      const excludedCurrList = accounts
        .filter((i) => i.nameId === accountName.id)
        .map((i) => i.currencyId);
      setExcludedList(excludedCurrList);
    } else if (excludedCurrencies.length) {
      setExcludedList(excludedCurrencies.map((i) => i.id.toString()));
    }
  }, [accountName.id]);

  useEffect(() => {
    if (accounts.length && currency.id) {
      const excludedAccounts = accounts
        .filter((i) => i.currencyId.toString() === currency.id.toString())
        .map((i) => i.nameId);
      setExcludedList(excludedAccounts);
    }
  }, [currency.id]);

  useEffect(() => {
    if (
      !isActorSelected ||
      forms?.length ||
      values.valueType !== ACCOUNT_TYPE.formField
    )
      return;
    dispatch({
      type: GET_ACTOR.REQUEST,
      payload: { id: values.actor?.id },
      callback: (actor) => {
        if (!actor?.forms) return;
        setActorForms(actor.forms);
      },
    });
  }, [values.actor?.id, values.valueType]);

  const notifySuccess = () => {
    const obj = actors || values.addPlan ? 'Accounts' : 'Account';
    const action = mode === 'create' ? 'created' : 'updated';
    const text = `${obj} successfully ${action}`;
    showNotification('success', text);
  };

  /**
   * Save pair access rules changes
   */
  const managePairAccess = () => {
    const { access } = values;
    const hasAccessActions = access.some((i) => !!i.action);
    if (!hasAccessActions) return;
    const pairId = `${accountName.id}_${currency.id}`;
    dispatch({
      type: MANAGE_ACCESS_RULES.REQUEST,
      payload: { body: access, objId: pairId, objType: 'account' },
    });
  };

  /**
   * Save account actors (triggers/tags)
   */
  const saveAccountActors = ({ formId, actors }) => {
    const newActors = actors.map((i) => ({
      actorId: i.actorId || i.id,
      title: i.title,
    }));
    dispatch({
      type: SAVE_ACCOUNT_ACTORS.REQUEST,
      payload: {
        nameId: accountName.id,
        currencyId: currency.id,
        formId,
        actors: newActors,
      },
    });
  };

  const formatNumber = (value) => {
    if (typeof value !== 'string') return value;
    if (value === '') return null;
    return Number(
      value.replace(/^[-]$/g, '').replace(/[.,]$/g, '').replace(/^[.,]/g, '0.')
    );
  };

  const handleCreateAccounts = ({ type, target }) => {
    const { treeCalculation, search, valueType, multivalue, tags, triggers } =
      values;
    const isNumeric = valueType === ACCOUNT_TYPE.number;
    dispatch({
      type,
      payload: {
        ...target,
        actorData: values.actor,
        currencyId: currency.id,
        nameId: accountName.id,
        valueType,
        multivalue,
        treeCalculation,
        minLimit: isNumeric ? formatNumber(minLimit) : null,
        maxLimit: isNumeric ? formatNumber(maxLimit) : null,
        search,
        tags,
        triggers,
      },
      callback: () => {
        if (!values.addPlan || !isNumeric) {
          dispatch({ type: DEL_MODAL });
          notifySuccess();
          return;
        }
        dispatch({
          type,
          payload: {
            accountType: 'plan',
            currencyId: currency.id,
            nameId: accountName.id,
            ...target,
            actorData: values.actor,
          },
          callback: () => dispatch({ type: DEL_MODAL }),
        });
      },
      errorCallback: () =>
        showNotification('error', t(mes.accActorAccessDenied)),
    });
  };

  const saveActorsAccounts = () => {
    if (actors) {
      handleCreateAccounts({
        type: BULK_CREATE_ACCOUNTS.REQUEST,
        target: { actors },
      });
    } else if (actorId || values.actor) {
      handleCreateAccounts({
        type:
          mode === 'create'
            ? CREATE_ACTORS_ACCOUNT.REQUEST
            : UPDATE_ACTORS_ACCOUNT.REQUEST,
        target: { actorId: actorId || values.actor.id, key: values.key },
      });
    } else {
      notifySuccess();
      dispatch({ type: DEL_MODAL });
    }
  };

  const setIdErrors = (id, value) => {
    const newErrors = { ...errors };
    newErrors[id] = value;
    setErrors(newErrors);
  };

  const validateErrors = () => {
    if (
      minLimit &&
      maxLimit &&
      formatNumber(minLimit) > formatNumber(maxLimit)
    ) {
      setIdErrors('minLimit', 'Min limit cannot be greater than max limit');
      return false;
    }
    return true;
  };

  const handleNumericSubmit = () => {
    const allValid = validateErrors();
    if (!allValid) return;
    const saveAll = () => {
      managePairAccess();
      if (tagsEdited) {
        saveAccountActors({ formId: systemFormsTags.id, actors: values.tags });
      }
      if (triggersEdited) {
        saveAccountActors({
          formId: systemFormsTriggers.id,
          actors: values.triggers,
        });
      }
      saveActorsAccounts();
    };
    if (pairStatus === PairStatus.NOT_EXIST) {
      dispatch({
        type: SAVE_ACCOUNT_PAIR.REQUEST,
        payload: {
          accountName: accountName.name,
          currencyName: currency.name,
        },
        callback: saveAll,
      });
    } else {
      saveAll();
    }
  };

  const handleFormFieldSubmit = () => {
    dispatch({
      type: UPDATE_FORM.REQUEST,
      payload: {
        formId: formFieldAccount.id,
        form: formFieldAccount,
        access: [],
        actorsAccess: [],
      },
      formActions: {
        callback: (newForm) => {
          notifySuccess();
          onClose();
          callback?.({
            data: newForm,
            accountType: values.valueType,
          });
        },
      },
    });
  };

  const renderNoAccessError = () => {
    return (
      <Banner
        styleName="l.content__noAccess"
        size="medium"
        error={true}
        value={t(mes.noPairAccess)}
      />
    );
  };

  const renderDetails = () => {
    return (
      <Stack size={Stack.SIZE.xsmall}>
        {showActorField ? (
          <div styleName="l.content__actor">
            <SelectActors
              bordered={true}
              size="large"
              multiselect={false}
              type="modal"
              label={t(mes.selectAccActor)}
              value={values.actor}
              exclude={values.tags}
              onChange={({ value }) => handleOnChange({ id: 'actor', value })}
            />
          </div>
        ) : null}
        {isActorSelected ? (
          <Stack
            styleName="l.content__row"
            horizontal
            fullWidth
            alignItems="center"
          >
            <Radio
              id="valueType"
              value={values.valueType}
              align="horizontal"
              label={t(mes.type)}
              onChange={handleOnChange}
              visibility={mode === 'create' ? 'visible' : 'disabled'}
            >
              <RadioItem label={t(mes.numeric)} value={ACCOUNT_TYPE.number} />
              <RadioItem
                label={t(mes.formField)}
                value={ACCOUNT_TYPE.formField}
              />
            </Radio>
          </Stack>
        ) : null}
        {cr(
          [
            values.valueType === ACCOUNT_TYPE.number,
            <>
              <Stack
                styleName="l.content__row"
                horizontal
                fullWidth
                alignItems="center"
              >
                <SelectAccountName
                  placeholder={t(mes.enterAccName)}
                  bordered={true}
                  type="modal"
                  size="large"
                  label={t(mes.account)}
                  value={values.accountName}
                  exclude={excludedList}
                  isDisabled={!!accData.nameId}
                  onChange={(value) => {
                    const { id, name, title } = value;
                    handleOnChange({
                      id: 'accountName',
                      value: { id, name: name || title },
                    });
                  }}
                />
                <SelectAccountCurrency
                  placeholder={t(mes.enterAccCurrencyName)}
                  bordered={true}
                  type="modal"
                  size="large"
                  label={t(mes.currency)}
                  value={values.currency}
                  exclude={excludedList}
                  isDisabled={!!accData.currencyId || !values.accountName}
                  isNumeric={values.valueType === ACCOUNT_TYPE.number}
                  onChange={(value) => {
                    const { id, name, title, symbol, type, precision, isNew } =
                      value;
                    handleOnChange({
                      id: 'currency',
                      value: {
                        id,
                        name: name || title,
                        symbol,
                        type,
                        precision,
                        isNew,
                      },
                    });
                  }}
                />
              </Stack>
              <SelectActors
                bordered={true}
                size="large"
                multiselect={true}
                type="modal"
                label={t(mes.tags)}
                formId={systemFormsTags.id}
                value={values.tags}
                exclude={values.tags}
                onChange={({ value }) => {
                  handleOnChange({ id: 'tags', value });
                  setTagsEdited(true);
                }}
                isDisabled={noAccess || !pairSelected}
              />
              <SelectActors
                bordered={true}
                size="large"
                multiselect={true}
                type="modal"
                label={t(mes.triggers)}
                formId={systemFormsTriggers.id}
                value={values.triggers}
                exclude={values.triggers}
                onChange={({ value }) => {
                  handleOnChange({ id: 'triggers', value });
                  setTriggersEdited(true);
                }}
                isDisabled={noAccess || !pairSelected}
              />
            </>,
          ],

          [
            true,
            <CreateFormFieldAccount
              forms={actorForms}
              onChange={setFormFieldAccount}
            />,
          ]
        )}
        {noAccess ? renderNoAccessError() : null}
      </Stack>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'details':
        return renderDetails();
      case 'access':
        return (
          <div styleName="l.content__access">
            <AccessRules
              objId={`${accountName.id}_${currency.id}`}
              objType="account"
              styleType="embeded"
              rules={values.access}
              onChange={(data) => handleOnChange({ id: 'access', value: data })}
            />
          </div>
        );
      case 'options':
        return (
          <div styleName="l.content__options">
            <AccAdvancedOptions
              values={values}
              errors={errors}
              mode={mode}
              addPlan={mode === 'create'}
              handleOnChange={handleOnChange}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      id="createActorModal"
      styleName="m.modal"
      size="xlarge"
      onClose={onClose}
      label={getModalTitle()}
      visibility={visibility}
    >
      <ModalContent styleName="m.modal__content l.content">
        <Tab
          type="auto"
          value={activeTab}
          onChange={({ value }) => setActiveTab(value)}
          underline={true}
        >
          <TabItem label={t(mes.details)} value="details" />
          <TabItem
            label={t(mes.access)}
            value="access"
            visibility={
              !pairSelected ||
              noAccess ||
              !checkPermissions([PERMISSIONS.USERS_READONLY])
                ? 'disabled'
                : 'visible'
            }
          />
          <TabItem
            label={t(mes.advancedOptions)}
            value="options"
            visibility={
              isActorSelected && values.valueType === ACCOUNT_TYPE.number
                ? 'visible'
                : 'hidden'
            }
          />
        </Tab>
        <div styleName="l.content__wrap">{renderTabContent()}</div>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        {cr(
          [
            values.valueType === ACCOUNT_TYPE.number,
            <Button
              label={t(mes.save)}
              size="large"
              onClick={handleNumericSubmit}
              visibility={
                isSubmit ||
                !pairSelected ||
                noAccess ||
                (showActorField && !values.actor)
                  ? 'disabled'
                  : 'visible'
              }
            />,
          ],
          [
            values.valueType === ACCOUNT_TYPE.formField,
            <Button
              label={t(mes.save)}
              size="large"
              onClick={handleFormFieldSubmit}
              visibility={formFieldAccount ? 'visible' : 'disabled'}
            />,
          ]
        )}
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

CreateActorAccountBase.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  handleOnChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  data: PropTypes.object,
};

const CreateActorAccount = withForm(
  {
    mapPropsToValues(props) {
      const accData = props.data.accData || {};
      const { debit, tags, triggers } = accData;
      return {
        key: debit ? debit.key : null,
        treeCalculation:
          !debit || AppUtils.isUndefined(debit.treeCalculation)
            ? false
            : debit.treeCalculation,
        search:
          !debit || AppUtils.isUndefined(debit.search) ? true : debit.search,
        minLimit: debit ? debit.minLimit : null,
        maxLimit: debit ? debit.maxLimit : null,
        accountName: accData.accountName
          ? { id: accData.nameId, name: accData.accountName }
          : {},
        currency: accData.currencyId
          ? { id: accData.currencyId, name: accData.currencyName }
          : {},
        valueType: accData.valueType || ACCOUNT_TYPE.number,
        accountType: 'fact',
        addPlan: false,
        tags: (tags || []).map((i) => ({ id: i.actorId, ...i })),
        triggers: (triggers || []).map((i) => ({ id: i.actorId, ...i })),
        access: [],
      };
    },
  },
  CreateActorAccountBase
);

export default CreateActorAccount;
