import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import cn from 'classnames';
import {
  Icon,
  Label,
  PortalWrapper,
  Tab,
  TabItem,
  Tooltip,
  Utils,
} from 'mw-style-react';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import Form from '@control-front-end/forms';
import AppUtils from '@control-front-end/utils/utils';
import { ActorCardViewEditor } from 'components';
import { checkUserPermissions } from 'selectors';
import FormSectionsPanel from './components/FormSectionsPanel';
import FormsErrors from './components/FormErrors';
import FormMap from '../../../../../FormMap';
import useActorFormActions from './hooks/useActorFormsActions';
import { formUIPropTypes } from '../../propTypes';
import './FormsLayout.scss';
import mes from './intl';

/**
 * Component for displaying UAT forms with navigation
 */
function FormsLayout(props) {
  const {
    actor,
    mainData,
    type,
    forms = [],
    formsErrors = [],
    formRefs,
    extraSection,
    settings,
    onChange,
    onFormDataChange,
    onSubmit,
    onChangeForms,
    onFormItemLoading,
  } = props;
  const t = useIntl();
  const formsContainerRef = useRef();
  const [activeTab, setActiveTab] = useState('forms');
  const [activeSection, setActiveSection] = useState(null);
  const [containerEl, setContainerEl] = useState({});
  const [portalActionsEl, setPortalActionsEl] = useState(null);
  const [openAllSections, toggleOpenAll] = useState(null);
  const checkPermissions = useSelector(checkUserPermissions);
  const wrapSections = type === 'modal';
  const rootForm = actor.formId
    ? (forms || []).find((f) => f.id === actor.formId)
    : (forms || []).find((f) => !f.parentId);
  const { handleAddForms, handleGetFormWithRelations } = useActorFormActions({
    rootForm,
    forms,
    handleFormsChange: onChangeForms,
  });

  const [previewMode, setPreviewMode] = useState(false);

  const handleSwitchToPreview = useCallback(
    ({ value }) => setPreviewMode(value),
    []
  );

  useEffect(() => {
    if (forms.length && !activeSection) {
      const formId = forms[0].id;
      setActiveSection({ formId, index: 0 });
    }
  }, [forms]);

  useEffect(() => {
    setContainerEl(formsContainerRef.current);
    const container = document.getElementById('createActorModal');
    const portalActionsEl = container
      ? container.querySelector('#portalActions')
      : null;
    setPortalActionsEl(portalActionsEl);
  }, [formsContainerRef.current]);

  /**
   * Apply the color of the last selected template as the actor color
   */
  const applyFormColor = ({ color }) => {
    if (!color) return;
    onChange({ color });
  };

  const handleSelectNewForm = (newForm) => {
    const rootIsDefault = rootForm && rootForm.form && rootForm.form.isDefault;
    const newFormRoot = newForm.forms.find((i) => !i.parentId);
    if (
      !newFormRoot ||
      (rootForm && !rootIsDefault && newFormRoot.id !== rootForm.id)
    )
      return;
    applyFormColor({ color: newForm.color });
  };

  const handleAddForm = (formId) => {
    handleGetFormWithRelations({
      formId,
      callback: ({ color, forms }) => {
        handleAddForms(forms);
        applyFormColor({ color });
      },
    });
  };

  const renderExpandAll = () => {
    if (!wrapSections || !portalActionsEl) return null;
    return (
      <PortalWrapper root={portalActionsEl} position="inherit">
        <Tooltip
          topLevel
          value={openAllSections ? t(mes.closeAll) : t(mes.openAll)}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              setActiveSection(null);
              toggleOpenAll(!openAllSections);
            }}
          >
            <Icon
              styleName={cn('fl__openall', { open: openAllSections })}
              type="arrows"
            />
          </div>
        </Tooltip>
      </PortalWrapper>
    );
  };

  const isNoContentForm = (sections) => {
    if (!sections || !sections.length) return true;
    return sections.length === 1 && !sections[0].content.length;
  };

  const renderFormsContent = (f, index) => {
    if (f.accessDenied) return null;
    const noContent = isNoContentForm(f.form.sections);
    const isUAT = rootForm && f.id === rootForm.id;
    if (noContent && isUAT) return null;

    return (
      <Fragment key={f.id}>
        <Tooltip topLevel value={f.title}>
          <div styleName="fl__title">
            <Label fontWeight="semibold" value={f.title} />
            {noContent ? (
              <Label fontWeight="semibold" value={`(${t(mes.empty)})`} />
            ) : null}
          </div>
        </Tooltip>
        <Form
          ref={formRefs.current[index]}
          key={f.id}
          formId={f.id}
          type={type}
          form={f.form}
          updateTime={f.updateTime}
          onChange={(form) => onFormDataChange({ id: f.id, form })}
          onFormItemLoading={onFormItemLoading}
          onSubmit={onSubmit}
          activeSection={
            activeSection && activeSection.formId === f.id
              ? activeSection.index
              : null
          }
          openAllSections={openAllSections}
        />
      </Fragment>
    );
  };

  return (
    <div styleName="fl">
      {renderExpandAll()}
      {settings.UI.sectionsPanel ? (
        <FormSectionsPanel
          containerEl={containerEl}
          formRefs={formRefs}
          rootForm={rootForm}
          forms={forms}
          activeSection={activeSection}
          canManageForms={checkPermissions([PERMISSIONS.FORMS_MANAGEMENT])}
          handleSelectSection={(section) => setActiveSection(section)}
          handleSelectNewForm={handleSelectNewForm}
          handleFormsChange={onChangeForms}
        />
      ) : null}
      <div ref={formsContainerRef} styleName="fl__container">
        {extraSection}
        {forms.length ? (
          <Tab
            styleName="fl__container__tabs"
            value={activeTab}
            type="auto"
            underline={true}
            onChange={({ value }) => setActiveTab(value)}
          >
            <TabItem label={t(mes.formsTab)} value="forms" />
            <TabItem label={t(mes.actorsCard)} value="actorsCard" />
            <TabItem
              label={t(mes.actorsMap)}
              value="map"
              visibility={
                settings.UI.actorsMapTab &&
                checkPermissions([PERMISSIONS.FORMS_READONLY])
                  ? 'visible'
                  : 'hidden'
              }
            />
          </Tab>
        ) : null}
        {forms.length ? (
          <div styleName="fl__container__content">
            <div
              styleName={cn('fl__container__content__forms', {
                hidden: activeTab !== 'forms',
              })}
            >
              {forms.length
                ? forms.map((f, i) => renderFormsContent(f, i))
                : null}
            </div>
            {formsErrors.length ? (
              <FormsErrors
                formsErrors={formsErrors}
                containerEl={containerEl}
              />
            ) : null}
            {rootForm && activeTab === 'map' ? (
              <div styleName="fl__container__content__map">
                <FormMap
                  formId={rootForm.id}
                  form={rootForm}
                  activeIds={forms.map((i) => i.id)}
                  handleDoubleClickNode={(id) => handleAddForm(id)}
                />
              </div>
            ) : null}
            {activeTab === 'actorsCard' ? (
              <ActorCardViewEditor
                // If there is actor but viewData is empty, then wiat till viewData is loaded
                loading={actor?.id && !mainData.viewData}
                actor={Utils.pick(
                  {
                    ...actor,
                    ...AppUtils.omitBy(
                      mainData,
                      (value) => value === undefined
                    ),
                  },
                  ['picture', 'description', 'title', 'color', 'ref']
                )}
                viewData={mainData.viewData}
                forms={forms}
                previewMode={previewMode}
                togglePreviewMode={handleSwitchToPreview}
                onChange={onChange}
                {...settings.actorCardViewEditor}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

FormsLayout.propTypes = {
  type: PropTypes.oneOf(['page', 'single', 'panel', 'modal']),
  forms: PropTypes.array,
  formsErrors: PropTypes.array,
  extraSection: PropTypes.node,
  settings: PropTypes.shape({
    UI: formUIPropTypes,
    actorCardViewEditor: PropTypes.shape({
      forceDisplayWidgetSelector: PropTypes.bool,
    }),
  }),
  onChange: PropTypes.func,
  onFormDataChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onChangeForms: PropTypes.func,
  onFormItemLoading: PropTypes.func,
};

export default FormsLayout;
