import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useIntl from 'useIntl';
import { Icon, Avatar, Button, Label } from 'mw-style-react';
import cn from 'classnames';
import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import { ActorsPopup } from 'components';
import mes from './intl';
import './Actors.scss';

function Actors({ actors = [], onChange, openPanel, onToggle }) {
  const t = useIntl();
  const portalNode = useRef();
  const [openPopup, setOpenPopup] = useState(false);
  const actorsCount = actors.length ? `(${actors.length})` : '';
  const eventsFormId = useSelector((state) => state.systemForms.events.id);

  // show actor addition popup
  const togglePopup = () => {
    setOpenPopup(!openPopup);
  };

  const handleChangeActors = (actor, action) => {
    const newActors = actors.slice(0);
    if (action === 'add') {
      newActors.unshift(actor);
    } else {
      const findIndex = newActors.findIndex((i) => i.id === actor.id);
      newActors.splice(findIndex, 1);
    }
    onChange({ id: 'actors', value: newActors });
  };

  const renderList = () => {
    const list = actors.map((i) => (
      <div key={i.id}>
        <div styleName="actors__row" onClick={onToggle}>
          <div styleName="actors__row__info">
            <div styleName="actors__row__info__avatar">
              <ActorAvatar
                size="small"
                type="compact"
                formType={i.formType}
                formTitle={i.formTitle}
                pictureUrl={i.pictureUrl}
                colors={i.allColors}
                status={i.status}
                colorFilled={true}
              />
            </div>
            <Label value={i.title} visibility={open ? 'visible' : 'hidden'} />
          </div>
          <div
            styleName="actors__row__action"
            onClick={(e) => {
              e.stopPropagation();
              handleChangeActors(i, 'delete');
            }}
          >
            <Icon size="small" type="close" />
          </div>
        </div>
      </div>
    ));

    return (
      <div styleName={cn('actors__list', { popup: openPopup })}>{list}</div>
    );
  };

  const addActorBtn = (
    <div
      styleName="actors__row clickable action"
      onClick={() => {
        if (!openPanel) onToggle();
        togglePopup();
      }}
    >
      <div styleName="actors__row__info">
        <div styleName="actors__row__info__avatar__box">
          <Avatar styleName="actors__row__info__avatar" bgColor="#fff" src="">
            <Icon type="plus" size="small" />
          </Avatar>
        </div>
        <Label value={t(mes.linkActor)} />
      </div>
    </div>
  );

  const actorsContent = (
    <div
      styleName={cn('actors', { open: openPanel })}
      ref={(el) => {
        portalNode.current = el;
      }}
    >
      <div styleName="actors__title">
        <Label
          fontWeight="semibold"
          value={`${t(mes.linkedActors)} ${actorsCount}`}
        />
      </div>
      <div styleName="actors__field">
        {openPopup ? (
          <ActorsPopup
            exclude={actors}
            excludeFormIds={[eventsFormId]}
            handleSelect={(id, actor) => handleChangeActors(actor, 'add')}
            handleBlur={() => setOpenPopup(false)}
          />
        ) : (
          addActorBtn
        )}
      </div>
      {renderList()}
    </div>
  );

  return (
    <div styleName={cn('event__panel', { open: openPanel })}>
      <Icon styleName="event__panel__icon" type="arrows" onClick={onToggle} />
      <Button
        styleName="event__panel__btn"
        type="text"
        label={`${t(mes.actors)} ${actorsCount}`}
        onClick={onToggle}
      />
      {actorsContent}
    </div>
  );
}

Actors.propTypes = {
  actors: PropTypes.array,
  onChange: PropTypes.func,
  openPanel: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default Actors;
