import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Stack,
  Tooltip,
  Chip,
  Calendar,
  DateUtils,
  ProgressBar,
  Icon,
  cr,
} from 'mw-style-react';
import { useIntl, useOutsideClick } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import { CREATE_REACTION } from '@control-front-end/common/constants/reactions';
import GoldCoin from '@control-front-end/app/public/static/gold_coin.svg';
import mes from './intl';
import scss from './ActorChips.scss';

/**
 * Info chips for actor
 */
function ActorChips({
  actor,
  isEvent = true,
  showStat = true,
  showReadOnly = false,
  handleUpdateActor,
}) {
  const t = useIntl();
  const dispatch = useDispatch();
  const calendarRef = useRef();
  const [dateEditMode, setDateEditMode] = useState(false);
  const [signChipHovered, setSignHovered] = useState(false);
  const [isSigning, setSigning] = useState(false);
  const auth = useSelector((state) => state.auth);
  const {
    readonly,
    access,
    reactionsCount,
    reactionsStats = {},
    privs,
  } = actor;

  const canEdit = privs && privs.modify;
  const { startDate, endDate, scheduleMeeting } = actor.data;
  const isSigner = privs && privs.sign;
  const signersCount = access
    .filter((i) => i.privs.sign)
    .map((i) => i.userId.toString()).length;
  const signaturesCount = reactionsStats
    ? Object.keys(reactionsStats).filter((user) => reactionsStats[user].sign)
        .length
    : 0;
  const isSigned = reactionsStats[auth.id]?.sign;
  const isRejected = reactionsStats[auth.id]?.reject;

  useOutsideClick({
    ref: calendarRef,
    callback: () => setDateEditMode(false),
  });

  const toggleSignHover = () => {
    if (actor.freeze) return;
    setSignHovered((prevHovered) => !prevHovered);
  };

  const handleAddSignReaction = () => {
    setSigning(true);
    dispatch({
      type: CREATE_REACTION.REQUEST,
      payload: { type: 'sign', rootActorId: actor.id },
      callback: () => setSigning(false),
    });
    setSignHovered(false);
  };

  const startDateUnix =
    typeof startDate === 'object' ? startDate.startDate : startDate;
  const endDateUnix = typeof endDate === 'object' ? endDate.startDate : endDate;

  const renderCalendar = () => (
    <div ref={calendarRef} className={scss.calendarPopup}>
      <Calendar
        size="small"
        dateRange={startDateUnix !== endDateUnix}
        options={['dateRange']}
        time={true}
        timeZone={false}
        updateOnInputChange
        minDate={DateUtils.unixtime()}
        value={{
          startDate: startDateUnix,
          endDate: endDateUnix,
        }}
        onChange={({ value }) => {
          handleUpdateActor({
            formData: {
              ...actor.data,
              startDate: value.startDate,
              endDate: value.endDate,
            },
          });
          setDateEditMode(false);
        }}
      />
    </div>
  );

  const renderSignChip = () => {
    const toSign = isSigner && !isSigned && !isRejected;
    const signChip = (
      <Chip
        className={cn(scss.chipsItem, {
          [scss.toSign]: toSign && !actor.freeze,
          [scss.isSigned]: isSigned && !actor.freeze,
          [scss.disabled]: actor.freeze,
        })}
        type="rectangular"
        size="small"
        fontSize="small"
        fontWeight="normal"
        closeIcon={false}
        unspaced
        label={signChipHovered && toSign ? t(mes.sign) : signaturesCount}
        icon={isSigned ? 'check' : 'sign'}
        children={
          isSigning ? (
            <ProgressBar className={scss.loader} type="circle" size="small" />
          ) : null
        }
        onClick={(e) => {
          if (!toSign || isSigning || actor.freeze) return;
          e.stopPropagation();
          handleAddSignReaction();
        }}
        onMouseEnter={toggleSignHover}
        onMouseLeave={toggleSignHover}
      />
    );
    const rejectedChip = (
      <Chip
        className={cn(scss.chipsItem, scss.rejected)}
        type="rectangular"
        size="small"
        fontSize="small"
        fontWeight="normal"
        closeIcon={false}
        label={t(mes.rejected)}
        icon="close"
      />
    );
    return cr(
      [isRejected, rejectedChip],
      [toSign && !actor.freeze, signChip],
      [true, <Tooltip value={t(mes.signNumber)}>{signChip}</Tooltip>]
    );
  };

  const dateRange = startDate !== endDate;
  return (
    <Stack.H size={Stack.SIZE.small} alignItems="center">
      {cr([
        showReadOnly && readonly,
        <Chip
          className={cn(scss.chipsItem, scss.readOnly)}
          type="rectangular"
          size="small"
          label={t(mes.readOnly)}
          fontSize="small"
          fontWeight="normal"
          closeIcon={false}
          unspaced
        />,
      ])}
      <div className={scss.wrap}>
        {cr([dateEditMode, renderCalendar()])}
        {cr([
          isEvent,
          <Tooltip value={dateRange ? t(mes.startEndDate) : t(mes.endDate)}>
            <Chip
              className={cn(scss.chipsItem, {
                [scss.dateRange]: dateRange,
                [scss.editable]: canEdit && !readonly,
              })}
              label={AppUtils.getTextDate(startDateUnix, endDateUnix)}
              type="rectangular"
              size="small"
              fontSize="small"
              fontWeight="normal"
              icon="calendar"
              closeIcon={false}
              unspaced
              onClick={() => {
                if (!canEdit || readonly) return;
                setDateEditMode(true);
              }}
            />
          </Tooltip>,
        ])}
      </div>
      {cr([
        showStat,
        <>
          {cr(
            [
              reactionsCount > 0,
              <Tooltip value={t(mes.reactionsNumber)}>
                <Chip
                  className={scss.chipsItem}
                  type="rectangular"
                  size="small"
                  label={reactionsCount}
                  icon="comment"
                  fontSize="small"
                  fontWeight="normal"
                  closeIcon={false}
                  unspaced
                />
              </Tooltip>,
            ],
            [
              isEvent,
              <Tooltip value={t(mes.eventPrice)}>
                <Chip
                  className={cn(scss.chipsItem, scss.price)}
                  type="rectangular"
                  size="small"
                  label={access.length}
                  fontSize="small"
                  fontWeight="normal"
                  closeIcon={false}
                  unspaced
                  visibility={isEvent ? 'visible' : 'hidden'}
                >
                  <img src={GoldCoin} alt="" />
                </Chip>
              </Tooltip>,
            ],
            [
              scheduleMeeting || actor.activeMeeting,
              <Tooltip
                value={
                  actor.activeMeeting
                    ? t(mes.activeMeeting)
                    : t(mes.scheduleMeeting)
                }
              >
                <Chip
                  className={cn(scss.chipsItem, scss.price, 'tab:meeting')}
                  type="rectangular"
                  size="small"
                  fontSize="small"
                  fontWeight="normal"
                  closeIcon={false}
                  unspaced
                >
                  <Icon type="video" error={actor.activeMeeting} />
                </Chip>
              </Tooltip>,
            ],
            [signersCount, renderSignChip()],
            true
          )}
        </>,
      ])}
    </Stack.H>
  );
}

ActorChips.propTypes = {
  actor: PropTypes.object.isRequired,
  isEvent: PropTypes.bool,
  showStat: PropTypes.bool,
  showReadOnly: PropTypes.bool,
  handleUpdateActor: PropTypes.func,
};

export default ActorChips;
