import { useEffect } from 'react';
import AppUtils from '@control-front-end/utils/utils';

let lastFocusedElement = null;

export function useFocusTracker() {
  useEffect(() => {
    const handleFocus = (event) => {
      const target = event.target;

      if (AppUtils.isEditableElement(target)) {
        lastFocusedElement = target;
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && lastFocusedElement) {
        setTimeout(() => lastFocusedElement.focus(), 0);
      }
    };

    const handleWindowFocus = () => {
      if (lastFocusedElement) {
        setTimeout(() => lastFocusedElement.focus(), 0);
      }
    };

    window.addEventListener('focus', handleWindowFocus);
    document.body.addEventListener('focus', handleFocus, true);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      document.body.removeEventListener('focus', handleFocus, true);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
}
