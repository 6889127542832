import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ContentEditable,
  forwardRef,
  Cursor,
  PortalWrapper,
  Utils,
} from 'mw-style-react';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import getUrlAttachments from '@control-front-end/utils/getUrlAttachments';
import { SEARCH_SMART_CHIP_REGEXP } from '@control-front-end/common/constants/regExp';
import { SmartChipPopup, FilesDragZone } from 'components';
import mes from './intl';
import './DescriptionEditor.scss';

const ForwardDescr = forwardRef(ContentEditable);

function DescriptionEditor(props) {
  const { accId, refEdit, description, access, onChange, onUpload } = props;
  const t = useIntl();
  const [popupPos, setPopupPos] = useState(null);
  const [query, setQuery] = useState('');

  const _focus = () => {
    if (refEdit.current) {
      refEdit.current.focus();
      Cursor.moveCursorToEnd(refEdit.current, { scroll: { block: 'end' } });
      refEdit.current.scrollTop = 99999;
    }
  };

  useEffect(() => {
    _focus();
    const selectClickedImg = (e) => {
      if (e.target.tagName !== 'IMG') return;
      const range = document.createRange();
      range.setStartBefore(e.target);
      range.setEndAfter(e.target);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    };

    refEdit.current?.addEventListener('click', selectClickedImg);

    return () => {
      refEdit.current?.removeEventListener('click', selectClickedImg);
    };
  }, []);

  const handleOnChange = ({ value }) => {
    const el = document.querySelector('div.searchChip');
    setQuery(el ? el.innerText : '');
    const newDescription = AppUtils.htmlToBbCode(value, true);
    onChange(newDescription);
  };

  // Remove field for creating a smart chip
  const handleRemoveChipField = () => {
    const descrHtml = refEdit.current.innerHTML;
    const newDescr = descrHtml.replace(SEARCH_SMART_CHIP_REGEXP, '@$1');
    onChange(newDescr);
    setPopupPos(null);
    setTimeout(() => _focus(), 100);
  };

  const handleAddSmartChip = ({ objType, id, title }) => {
    const type = objType === 'graphFolder' ? 'graph' : objType;
    const elementId = AppUtils.createRid();
    const bbCode = `[${type}=${id} elementId=${elementId}]${Utils.stripHtml(
      title
    )}[/${type}]`;
    const descrHtml = refEdit.current.innerHTML;
    const newDescr = descrHtml.replace(SEARCH_SMART_CHIP_REGEXP, bbCode);
    onChange(AppUtils.htmlToBbCode(newDescr, true));
    setPopupPos(null);
    setTimeout(() => {
      AppUtils.scrollToElementEndSetCursor(elementId);
    }, 100);
  };

  // Add field for creating a smart chip
  const handleAddChipField = () => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const { x, y } = AppUtils.getRangePosition(selection, range);
    setPopupPos({ y: y + 24, x });
    setQuery('');
    AppUtils.addChipField(selection, range);
    const descrHtml = refEdit.current.innerHTML;
    onChange(AppUtils.htmlToBbCode(descrHtml, true));
  };

  const handleKeyEvent = (e) => {
    if (e.key === 'Escape') {
      handleRemoveChipField();
    } else if (e.key === '@') {
      setTimeout(handleAddChipField, 0);
    }
  };

  const descriptionHTML = AppUtils.bbCodeToHtml(description || '', accId);

  return (
    <>
      <FilesDragZone
        containerRef={refEdit}
        onUpload={(data) => onUpload({ value: data }, false)}
      />
      <ForwardDescr
        id="editor"
        ref={refEdit}
        styleName="editor__field"
        className="editor__field"
        type="html"
        lineNumbers={false}
        value={descriptionHTML}
        placeholder={t(mes.startToWriteHere)}
        onChange={handleOnChange}
        onKeyPress={handleKeyEvent}
        onUpload={onUpload}
      />
      {getUrlAttachments(descriptionHTML, {
        youtube: {
          opts: {
            width: 300,
            height: 150,
          },
        },
      })}
      {popupPos ? (
        <PortalWrapper
          root={document.getElementById('mainRoot')}
          position="absolute"
          width={200}
          height={200}
          offsetX={popupPos.x}
          offsetY={popupPos.y}
        >
          <SmartChipPopup
            query={query}
            users={access}
            onSelect={handleAddSmartChip}
            onClose={handleRemoveChipField}
          />
        </PortalWrapper>
      ) : null}
    </>
  );
}

DescriptionEditor.propTypes = {
  refEdit: PropTypes.object,
  accId: PropTypes.string,
  description: PropTypes.string,
  access: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default DescriptionEditor;
