import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Clipboard, cr, CorezoidLightTheme as theme } from 'mw-style-react';
import {
  DEL_MODAL,
  MAKE_SOUND,
  NOTIFY_LEVEL,
  PM_APP_NAME,
  SET_MODAL,
  SHOW_NOTIFY,
  UPLOAD_FILE,
} from 'constants';
import {
  ADD_ACTOR,
  BULK_REMOVE_FROM_GRAPH,
  CREATE_ACTOR,
  CREATE_EDGE,
  GET_ACTORS,
  GET_ALL_ACTORS,
  SEARCH_ACTORS,
  REMOVE_ACTOR,
  REMOVE_EDGE,
  UPDATE_ACTOR,
  UPDATE_EDGE,
  GET_ACTORS_BALANCE,
  COPY_FROM_GRAPH,
  PASTE_TO_GRAPH,
  BULK_ADD_TO_GRAPH,
  DEFAULT_DASHBOARD,
  DELAY_BEFORE_NEXT_GRAPH_UPDATE,
  EXP_NODE,
} from '@control-front-end/common/constants/graphActors';
import {
  ACTOR_DECOMPOSE,
  ACTORS_AGGREGATION,
  EDITABLE_NODE,
  SAVE_LAYER_POSITION,
  STARRED_LAYER_ACTOR,
  SAVE_ACTOR_LAYER_SETTINGS,
  GRAPH_DISCOVERY,
  TYPE_LAYER,
} from '@control-front-end/common/constants/graphLayers';
import { CREATE_ACTORS_ACCOUNT } from '@control-front-end/common/constants/actorAccounts';
import { GET_FORM } from '@control-front-end/common/constants/forms';
import { isCellOccupiedByNodes } from '@control-front-end/utils/modules/utilsCellCoords';
import { SUBSCRIBE_BALANCES } from '@control-front-end/common/constants/graphRealtime';
import AppUtils from '@control-front-end/utils/utils';
import FormUtils from '@control-front-end/utils/formUtils';
import history from '@control-front-end/app/src/store/history';
import { useNotifications } from 'hooks';
import ActorsNoAccess from '../components/ActorsNoAccess';

/**
 * Actor's graph management actions
 * @param p
 */
const useActorsGraphActions = (p) => {
  const { graphMode, activeLayer, togglePanel, activeTemplateRef, graphRef } =
    p;
  const dispatch = useDispatch();
  const showNotification = useNotifications();
  const defaultActorTpl = useSelector((state) => state.defaultActorTpl);
  const systemForms = useSelector((state) => state.systemForms) || {};

  const activeLayerRef = useRef(activeLayer);
  activeLayerRef.current = activeLayer;

  /**
   * Create actor's account for tree layer
   */
  const handleCreateActorsAccount = (actorId) => {
    if (!activeLayer.treeEdgeTypeInfo) return;
    const { accountName = {}, accountCurrency = {} } =
      activeLayer.treeEdgeTypeInfo;
    dispatch({
      type: CREATE_ACTORS_ACCOUNT.REQUEST,
      payload: {
        actorId,
        currencyId: accountCurrency.id,
        nameId: accountName.id,
        accountType: 'fact',
        ignoreIfExist: true,
      },
    });
  };

  /**
   * Collapse/expand actor
   */
  const handleSaveActorLayerSettings = (payload, callback) => {
    dispatch({
      type: SAVE_ACTOR_LAYER_SETTINGS.REQUEST,
      payload,
      callback,
    });
  };

  const expandChartActor = ({ laId } = {}) => {
    const node = graphRef.current?.nodes(`[id = "node_${laId}"]`)[0];
    const position = node?.position();
    if (!laId || !position || !node) return;
    const offset = EXP_NODE.offset[EXP_NODE.sizeType.chart];
    handleSaveActorLayerSettings(
      {
        id: `node_${laId}`,
        settings: {
          expandType: EXP_NODE.contentType.chart,
          offset,
          expand: true,
        },
      },
      () =>
        dispatch({
          type: GRAPH_DISCOVERY.EXPAND_NODE.REQUEST,
          expand: true,
          position,
          offset,
        })
    );
  };

  const sendMessageUpdateActorTitle = ({ id, title }) => {
    const parentWindows = AppUtils.getAllParentWindows();
    parentWindows.forEach((parent) => {
      parent.postMessage(
        {
          appName: PM_APP_NAME,
          type: 'UPDATE_NODE_TITLE',
          data: { id, title },
        },
        window.parent.origin
      ); // NOSONAR
    });
  };

  /**
   * Update actor
   */
  const handleUpdateActor = (props) => {
    const {
      title,
      description,
      picture,
      pictureObject,
      color,
      formData,
      formId,
      id,
      ref,
      callback,
    } = props;
    if (title && window.frameElement) {
      sendMessageUpdateActorTitle({ id, title });
    }
    dispatch({
      type: UPDATE_ACTOR.REQUEST,
      payload: {
        title,
        description,
        picture,
        pictureObject,
        color,
        formData,
        formId,
        ref,
        id,
      },
      callback,
    });
  };

  /**
   * Add actor to graph
   */
  const handleAddActor = ({
    actorModel,
    position,
    polygon,
    callback,
    ...rest
  }) => {
    const isStateActor = actorModel.formId === systemForms.state.id;
    if (!isStateActor && isCellOccupiedByNodes(position, graphRef?.current))
      return;
    dispatch({
      type: ADD_ACTOR.REQUEST,
      payload: {
        actorModel,
        position,
        polygon: cr(
          [isStateActor && polygon, polygon],
          [isStateActor, AppUtils.makeDefaultPolygon(position)],
          [true, null]
        ),
        manageLayer: graphMode === 'layers',
        ...rest,
      },
      callback: (data) => {
        if (callback) callback(data);
        handleCreateActorsAccount(actorModel.id);
        if (data.formId === systemForms?.dashboards?.id) {
          expandChartActor(data);
        }
      },
    });
  };

  /**
   * Get linked nodes
   */
  const handleGetActors = ({ actorId, loadMore = false }) => {
    dispatch({
      type: GET_ACTORS.REQUEST,
      payload: {
        actorId,
        edgeType: 'hierarchy',
        loadMore,
      },
    });
  };

  /**
   * Get available actors list
   */
  const handleGetAllActors = ({ loadMore = false }) => {
    dispatch({
      type: GET_ALL_ACTORS.REQUEST,
      payload: { loadMore },
    });
  };

  /**
   * Search actors
   */
  const handleSearchActors = ({ query, offset, callback }) => {
    dispatch({
      type: SEARCH_ACTORS.REQUEST,
      payload: { query, offset },
      callback,
    });
  };

  /**
   * Get actors' balances
   */
  const handleGetBalances = (props) => {
    const {
      layerId,
      nameId,
      currencyId,
      accountType,
      from,
      to,
      currencyParams,
      callback,
    } = props;
    dispatch({
      type: GET_ACTORS_BALANCE.REQUEST,
      payload: {
        layerId,
        nameId,
        currencyId,
        accountType,
        from,
        to,
        currencyParams,
      },
      callback,
    });
  };

  /**
   * Subscription for actors' balances changes
   */
  const handleSubscribeBalances = ({ layerId, nameId, currencyId, status }) => {
    dispatch({
      type: SUBSCRIBE_BALANCES.REQUEST,
      payload: { layerId, nameId, currencyId, status },
    });
  };

  /**
   * Add new edge between two actors
   */
  const handleCreateEdge = ({ edge }) => {
    dispatch({
      type: CREATE_EDGE.REQUEST,
      payload: edge,
    });
  };

  /**
   * Update edge
   */
  const handleUpdateEdge = ({
    id,
    name,
    linkedActorId,
    curveStyle,
    callback,
  }) => {
    dispatch({
      type: UPDATE_EDGE.REQUEST,
      payload: { id, name, linkedActorId, curveStyle },
      callback,
    });
  };

  /**
   * Remove edge
   */
  const handleRemoveEdge = (id, manageLayer, callback) => {
    dispatch({
      type: REMOVE_EDGE.REQUEST,
      payload: { id, manageLayer, withReq: !manageLayer },
      callback,
    });
    if (togglePanel) togglePanel(false);
  };

  /**
   * After actor's creation
   */
  const createActorEdge = (actor, edge) => {
    if (!edge) return;

    const { target } = edge;

    handleCreateEdge({
      edge: {
        ...edge,
        target: target || actor.id,
        laIdTarget: target ? edge.laIdTarget : actor.laId,
        source: target ? actor.id : edge.source,
        laIdSource: target ? actor.laId : edge.laIdSource,
      },
    });
    handleCreateActorsAccount(actor.id);
  };

  /**
   * Create new actor
   */
  const handleCreateActor = (props) => {
    const {
      title,
      ref,
      description,
      color,
      picture,
      areaPicture,
      pictureObject,
      edge,
      position = { x: 0, y: 0 },
      form,
      formData = {},
      extraData,
      manageLayer,
      polygon = null,
      isTextNode = false,
      callback,
      startEditingNewActor = true,
    } = props;
    const formId = form ? form.id : null;
    if (
      formId !== systemForms.state.id &&
      isCellOccupiedByNodes(position, graphRef?.current)
    )
      return;
    dispatch({
      type: CREATE_ACTOR.REQUEST,
      payload: {
        title,
        ref,
        description,
        color,
        picture,
        areaPicture,
        pictureObject,
        formId,
        formData,
        position,
        polygon,
        isTextNode,
        extraData,
        manageLayer,
        contextLayerId: activeLayer?.id,
        startEditingNewActor,
      },
      callback: (actor) => {
        if (callback) callback(actor);
        createActorEdge(actor, edge);
        if (form?.id === systemForms?.dashboards?.id) {
          expandChartActor(actor);
        }
      },
    });
  };

  /**
   * Create new actor via modal window
   */
  const handleCreateActorModal = (props) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'CreateActor',
        data: {
          ...props,
          viewMode: 'graph',
          graphMode,
        },
        closeConfirm: true,
        callback: (actor) => {
          if (props.callback) props.callback(actor);
          createActorEdge(actor, props.edge);
        },
      },
    });
  };

  /**
   * Validates the form and determines in which mode to create the actor.
   */
  const validateFormAndCreateActor = (form, actorProps) => {
    const { areRequiredFieldsFilled } = FormUtils.checkValidation(form);

    if (!areRequiredFieldsFilled) {
      handleCreateActorModal(actorProps);
      return;
    }

    const formData = actorProps?.formData || FormUtils.getFormData(form);
    handleCreateActor({ ...actorProps, formData });
  };

  /**
   * Get an accessible default form for actors creating
   */
  const getDefaultForm = () => {
    const form = activeTemplateRef.current;
    return form && !form.accessDenied ? form : defaultActorTpl;
  };

  /**
   * Create actor by template
   */
  const handleCreateTplActor = (props) => {
    const form = props.form || getDefaultForm();
    const color = props.color || AppUtils.getRandomActorsColor();
    const actorProps = { ...props, color, form };
    const formMode = form?.mode || FormUtils.getCreatingMode(form.form);

    if (formMode === 'modal') {
      if (form?.form) {
        validateFormAndCreateActor(form.form, actorProps);
      } else {
        dispatch({
          type: GET_FORM.REQUEST,
          payload: { formId: form.id },
          callback: ({ form }) => validateFormAndCreateActor(form, actorProps),
        });
      }
    } else {
      handleCreateActor(actorProps);
    }
    graphRef.current.elements().unselect();
  };

  /**
   * Update actor's title
   */
  const handleUpdateTitleActor = ({ id, title }) => {
    const editableNode = activeLayerRef.current.nodes.find((i) => i.id === id);
    if (!editableNode || title === editableNode.data.title) return;
    sendMessageUpdateActorTitle({ id, title });
    dispatch({
      type: UPDATE_ACTOR.REQUEST,
      payload: {
        ...editableNode.data,
        title,
      },
    });
  };

  /**
   * Remove actor
   */
  const handleRemoveActor = (id, manageLayer, callback) => {
    dispatch({
      type: REMOVE_ACTOR.REQUEST,
      payload: { id, manageLayer },
      callback,
    });
    if (togglePanel) togglePanel(false);
  };

  /**
   * Mass remove graph elements
   */
  const bulkRemoveGraphElements = (
    list,
    manageLayer,
    callback,
    asyncCallback = () => {}
  ) => {
    asyncCallback();
    dispatch({
      type: BULK_REMOVE_FROM_GRAPH.REQUEST,
      payload: { list, manageLayer },
      callback: (...args) => {
        callback?.(...args);
        if (graphMode !== TYPE_LAYER.layers) return;
        setTimeout(() => {
          dispatch({
            type: GRAPH_DISCOVERY.MAKE_TRACE.REQUEST,
            payload: {
              elements: list,
              hidden: !manageLayer,
            },
          });
        }, DELAY_BEFORE_NEXT_GRAPH_UPDATE);
      },
    });
    if (togglePanel) togglePanel(false);
  };

  /**
   * Mass add actors to graph
   */
  const bulkAddActorsOnGraph = ({ layerId, actors, callback }) => {
    dispatch({
      type: BULK_ADD_TO_GRAPH.REQUEST,
      payload: { layerId, nodes: actors },
      callback,
    });
  };

  /**
   * Access denied notification
   */
  const notifyAccessDenied = () => {
    showNotification.error("You don't have permission to modify this layer.");
  };

  /**
   * Play sound of moving actor on layer
   */
  const handleActionSound = () => {
    dispatch({ type: MAKE_SOUND, payload: { type: 'moveActor' } });
  };

  /**
   * Play sound of making new edge on layer
   */
  const handleStartEdgeSound = () => {
    dispatch({ type: MAKE_SOUND, payload: { type: 'addLink' } });
  };

  /**
   * Play sound of deleting actor on layer
   */
  const handleDeleteSound = () => {
    dispatch({ type: MAKE_SOUND, payload: { type: 'delete' } });
  };

  /**
   * Delete confirm modal window
   */
  const showModalConfirmDelete = (toDel, delCallback, elementType) => {
    const count = Array.isArray(toDel) ? toDel.length : 1;
    const isTreeNodes = graphMode === 'trees' && elementType !== 'edges';
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'removeActorsLinks',
          text: {
            text: count === 1 ? 'removeActorConfirm' : 'removeActorsConfirm',
            values: { count },
          },
          buttons: [
            {
              type: 'error',
              label: 'delPermanently',
              onClick: () => {
                delCallback(toDel, false);
                dispatch({ type: DEL_MODAL });
                togglePanel(false);
              },
            },
            {
              label: 'delFromLayer',
              visibility:
                (graphMode === 'layers' && activeLayer.isCustom) || isTreeNodes
                  ? 'visible'
                  : 'hidden',
              onClick: () => {
                delCallback(toDel, true);
                dispatch({ type: DEL_MODAL });
                togglePanel(false);
              },
            },
          ],
        },
      },
    });
  };

  /**
   * Modal window with elements not accessible to delete
   */
  const showListNoAccess = (listNoAccess, listToDel) => {
    const count = listToDel.length;
    const canModifyLayer = !!activeLayer?.privs?.modify;
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'cantDeleteActors',
          content: <ActorsNoAccess list={listNoAccess} />,
          buttons: [
            {
              label: count ? 'delOtherItems' : 'ok',
              onClick: () => {
                dispatch({ type: DEL_MODAL });
                if (count) {
                  showModalConfirmDelete(listToDel, bulkRemoveGraphElements);
                }
              },
            },
            {
              label: 'delFromLayer',
              onClick: () => {
                const list = [...listNoAccess, ...listToDel];
                bulkRemoveGraphElements(list, true);
                dispatch({ type: DEL_MODAL });
              },
              visibility: canModifyLayer ? 'visible' : 'hidden',
            },
          ],
        },
      },
    });
  };

  /**
   * Bulk remove parent edges from tree layer
   */
  const bulkRemoveParentEdges = (list, layer) => {
    const listIds = list.map((i) => i.id);
    const treeParentEdges = layer.edges
      .filter((i) => listIds.includes(i.data.target))
      .map((i) => i.data);
    bulkRemoveGraphElements(treeParentEdges, false);
  };

  /**
   * Callback on remove elements from graph
   */
  const removeCallback = ({
    manageLayer,
    listNoAccess,
    listToDel,
    asyncCallback = () => {},
    callback = () => {},
  }) => {
    if (manageLayer) {
      bulkRemoveGraphElements(listToDel, manageLayer, callback, asyncCallback);
    } else if (listNoAccess.length) {
      showListNoAccess(listNoAccess, listToDel);
    } else {
      const hasEdges = listToDel.some((i) => i.type === 'edge');
      showModalConfirmDelete(
        listToDel,
        (list, justLayer) => {
          bulkRemoveGraphElements(
            list,
            justLayer,
            ({ activeLayer: newActiveLayer }) => {
              callback();
              if (justLayer && graphMode === 'trees') {
                bulkRemoveParentEdges(list, newActiveLayer);
              }
            },
            asyncCallback
          );
        },
        hasEdges ? 'edges' : 'nodes'
      );
    }
  };

  /**
   * Remove group of selected elements
   */
  const handleRemoveEls = ({
    elements,
    manageLayer = false,
    asyncCallback,
    callback,
  }) => {
    if (activeLayer && !activeLayer.privs?.modify) {
      notifyAccessDenied();
      return;
    }
    const listToDel = [];
    const listNoAccess = [];
    elements.forEach((el) => {
      if (el.isNonInteractive) return;
      const { id, actorId, edgeId, title, privs, isSystem } = el;
      const type = el.type || 'node';
      if ((privs?.remove && !isSystem) || manageLayer) {
        listToDel.push(el);
      } else if (type === 'node') {
        listNoAccess.push({ id, actorId, title, type });
      } else {
        const source = activeLayerRef.current.nodes.find(
          (i) => i.id === el.source
        );
        const target = activeLayerRef.current.nodes.find(
          (i) => i.id === el.target
        );
        listNoAccess.push({
          id,
          edgeId,
          title: `from ${source.data.title} to ${target.data.title}`,
          type,
        });
      }
    });

    removeCallback({
      manageLayer,
      listNoAccess,
      listToDel,
      callback,
      asyncCallback,
    });
  };

  /**
   * Flag actor on layer
   */
  const handleFlagActor = (payload) => {
    dispatch({
      type: STARRED_LAYER_ACTOR.REQUEST,
      payload: { layerId: activeLayer.id, ...payload },
    });
  };

  /**
   * Create new actor via modal window
   */
  const handleCreateChart = (props) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'CreateDashboard',
        data: props,
        closeConfirm: true,
        callback: (actor) => {
          if (props.callback) props.callback(actor);
          createActorEdge(actor, props.edge);
          expandChartActor(actor);
        },
      },
    });
  };

  /**
   * Save nodes positions on graph
   */
  const handleSaveGraphPosition = ({ extra, callback, errorCallback }) => {
    const { nodes } = extra;
    if (!activeLayer.id || !nodes.length) return;
    nodes.forEach((i) => {
      i.id = i.id.replace('node_', '');
    });
    dispatch({
      type: SAVE_LAYER_POSITION.REQUEST,
      payload: {
        layerId: activeLayer.id,
        positions: nodes,
        manageLayer: activeLayer.privs.modify,
      },
      callback,
      errorCallback,
    });
  };

  /**
   * Center canvas to node
   */
  const panCenterNodeViewBox = ({ e, graph }) => {
    if (!e.target) return;
    const run = () => {
      const { w, h } = graph.extent();
      const zoom = graph.zoom();
      const bb = e.target.boundingBox();
      const pan = {
        x: (zoom * w - zoom * (bb.x1 + bb.x2)) / 2,
        y: (zoom * h - zoom * (bb.y1 + bb.y2)) / 2,
      };
      graph.animate({ zoom, pan });
    };
    try {
      setTimeout(run, 150);
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  };

  /**
   * Copy group of selected elements
   */
  const handleCopyGraphElements = ({ nodes: list, hasLoops }, callback) => {
    if (!list || !list.length) return;
    // Clear clipboard to prevent its content usage in paste on graph
    Clipboard.copy('');
    const nodes = [];
    const edges = [];
    list.forEach((el) => {
      if (el.isNonInteractive) return;
      const arr = el.type === 'edge' ? edges : nodes;
      arr.push(el);
    });
    if (!nodes.length && !edges.length) return;
    dispatch({
      type: COPY_FROM_GRAPH.REQUEST,
      payload: { layerId: activeLayer.id, nodes, edges, hasLoops: hasLoops() },
      callback: () => {
        showNotification.success('Graph elements copied.');
        callback?.();
      },
    });
  };

  const getPositionByRenderedPosition = (renderedPosition) => {
    const { x: offsetX, y: offsetY } = graphRef.current
      .container()
      .getBoundingClientRect();
    const pan = graphRef.current.pan();
    const zoom = graphRef.current.zoom();
    return {
      x: AppUtils.renderedCoordinateToPosition(
        renderedPosition.x - offsetX,
        zoom,
        pan.x
      ),
      y: AppUtils.renderedCoordinateToPosition(
        renderedPosition.y - offsetY,
        zoom,
        pan.y
      ),
    };
  };

  /**
   * Paste copied elements to layer
   */
  const handlePasteGraphElements = ({ graph, position }) => {
    if (!activeLayer.privs.modify) {
      notifyAccessDenied();
      return;
    }
    dispatch({
      type: PASTE_TO_GRAPH.REQUEST,
      payload: {
        layerId: activeLayer.id,
        typeLayer: activeLayer.typeLayer,
        type: activeLayer.type,
        position,
      },
      callback: (elements) => {
        graph.current.elements().unselect();
        setTimeout(() => {
          graph.current
            .elements()
            .filter((i) => elements.find((node) => node.id === i.id()))
            .select();
        }, 100);
      },
    });
  };

  /**
   * Make graph node editable
   */
  const handleSetEditable = (id) => {
    dispatch({
      type: EDITABLE_NODE.REQUEST,
      payload: id,
    });
  };

  /**
   * Copy link to actor
   */
  const handleCopyActorLink = (id) => {
    const accId = AppUtils.getAccountId(history.location.pathname);
    const actorLink = `/actors_graph/${accId}/graph/0/actors/${id}`;
    const actorFullLink =
      document.location.origin + AppUtils.makeUrl(actorLink);
    Clipboard.copy(actorFullLink);
    const payload = {
      id: AppUtils.createRid(),
      type: NOTIFY_LEVEL.SUCCESS,
      label: 'Actor link has been copied to clipboard',
    };
    if (window.frameElement) {
      window.top.postMessage(
        {
          appName: PM_APP_NAME,
          type: 'SHOW_NOTIFY_TOP_WINDOW',
          data: { payload },
        },
        window.parent.origin
      ); // NOSONAR
    } else
      dispatch({
        type: SHOW_NOTIFY.REQUEST,
        payload,
      });
  };

  /**
   * Create napkin or image actor
   */
  const handleCreateNapkinOrImage = ({
    title,
    pictureObject,
    position,
    type,
    isChanged,
    callback,
  }) => {
    if (!pictureObject.img || !isChanged) return;
    pictureObject.type = type;
    const actor = {
      title,
      pictureObject,
      position,
      callback,
    };
    if (type === 'image') actor.picture = pictureObject.img;
    handleCreateTplActor(actor);
  };

  /**
   * Create image actor via drag&drop
   */
  const handleDragImageActor = (files, { pageX, pageY }) => {
    const pan = graphRef.current.pan();
    const zoom = graphRef.current.zoom();
    dispatch({
      type: UPLOAD_FILE.REQUEST,
      payload: {
        files,
        callback: (attachments) => {
          const img = attachments[0];
          AppUtils.getImgSize(img.fileName, ({ width, height }) => {
            const graphContainer = graphRef.current.container();
            const correctX = graphContainer.getBoundingClientRect().left || 0;
            const position = {
              x: (pageX - correctX - pan.x) / zoom,
              y: (pageY - pan.y) / zoom,
            };
            const actor = {
              title: img.title,
              pictureObject: {
                type: 'image',
                img: img.fileName,
                width,
                height,
              },
              position,
            };
            handleCreateTplActor(actor);
          });
        },
      },
    });
  };

  /**
   * Decompose actor
   */
  const handleDecomposeActor = (
    { layerName, layerId, actorModel },
    callback
  ) => {
    dispatch({
      type: ACTOR_DECOMPOSE.REQUEST,
      payload: {
        layerName,
        layerId,
        position: actorModel.position,
        actorModel: { ...actorModel, id: actorModel.actorId },
      },
      callback,
    });
  };

  /**
   * Aggregation of actors
   */
  const handleActorAggregation = (props, callback) => {
    const { edges, layerName, layerId, rootActor } = props;
    dispatch({
      type: ACTORS_AGGREGATION.REQUEST,
      payload: { edges, rootActor, layerName, layerId },
      callback,
    });
  };

  /**
   * Create chart for selected actors' accounts
   */
  const handleCreateQuickChart = ({
    elements,
    position,
    nameId,
    currencyId,
    callback,
  }) => {
    const chartParams = {
      accounts: elements
        .filter((el) => el.type === 'node' && !el.isTrace)
        .map(({ actorId }) => ({
          actorId,
          nameId,
          currencyId,
          incomeType: 'total',
        })),
      range: DEFAULT_DASHBOARD.range,
      counterType: DEFAULT_DASHBOARD.counterType,
      chartType: DEFAULT_DASHBOARD.chartType,
      chartViewMode: DEFAULT_DASHBOARD.chartViewMode,
      displayChartDataLabels: DEFAULT_DASHBOARD.displayChartDataLabels,
    };
    handleCreateActor({
      position,
      title: DEFAULT_DASHBOARD.title,
      form: { id: systemForms.dashboards.id },
      formData: { source: JSON.stringify(chartParams) },
      callback,
    });
  };

  /**
   * Create a text node actor
   */
  const handleCreateTextNode = ({
    position,
    description,
    size: { width, height },
    textNodeScale,
  }) => {
    handleCreateActor({
      position,
      description,
      startEditingNewActor: false,
      color: theme.palette.black,
      manageLayer: true,
      polygon: AppUtils.makeDefaultPolygon(position),
      isTextNode: true,
      callback: (actor) => {
        if (actor?.laId) {
          const nodeId = `node_${actor.laId}`;
          handleSaveActorLayerSettings({
            id: nodeId,
            settings: {
              textNodeScale,
              width,
              height,
              isTextNode: true,
            },
          });
        }
      },
    });
  };

  return {
    handleCreateEdge,
    handleUpdateEdge,
    handleAddActor,
    handleGetActors,
    handleGetAllActors,
    handleSearchActors,
    handleRemoveEdge,
    handleCreateActor,
    handleCreateActorModal,
    handleCreateTextNode,
    handleCreateTplActor,
    handleCreateChart,
    handleRemoveActor,
    handleUpdateActor,
    handleUpdateTitleActor,
    handleCreateQuickChart,
    handleFlagActor,
    bulkRemoveGraphElements,
    handleCopyGraphElements,
    handlePasteGraphElements,
    handleRemoveEls,
    handleSaveGraphPosition,
    handleActionSound,
    handleStartEdgeSound,
    handleDeleteSound,
    panCenterNodeViewBox,
    handleSetEditable,
    handleSaveActorLayerSettings,
    handleGetBalances,
    handleSubscribeBalances,
    handleCopyActorLink,
    handleCreateNapkinOrImage,
    handleDragImageActor,
    handleDecomposeActor,
    handleActorAggregation,
    showModalConfirmDelete,
    showListNoAccess,
    bulkAddActorsOnGraph,
    bulkRemoveParentEdges,
    getPositionByRenderedPosition,
    validateFormAndCreateActor,
  };
};

export default useActorsGraphActions;
