import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import cn from 'classnames';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  Label,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { SET_MODAL } from 'constants';
import mes from './intl';
import '../../Modal.scss';

class InfoModal extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  // Localization function
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  onClick() {
    const { data, callback, dispatch, onClose } = this.props;
    if (data.id === 'welcome') {
      dispatch({ type: SET_MODAL, payload: { name: 'Invite' } });
    } else {
      if (callback) callback();
      onClose();
    }
  }

  getTextLocal(textInfo) {
    if (typeof textInfo === 'object') {
      return this.i(mes[textInfo.text], textInfo.values);
    }
    return this.i(mes[textInfo]);
  }

  renderModalContentText(text) {
    if (Array.isArray(text) && text.length > 0) {
      return (
        <div className="label">
          {text.map((item) => (
            <Label key={item} value={this.getTextLocal(item)} />
          ))}
        </div>
      );
    }
    return <Label className="label" value={this.getTextLocal(text)} />;
  }

  renderButton(data) {
    const { onClose } = this.props;
    if (!data || !data.buttons) {
      return (
        <Button
          styleName="modal__btn"
          label={this.i(mes.continue)}
          size="large"
          onClick={this.onClick}
        />
      );
    }
    return data.buttons.map((btn, index) => (
      <Button
        key={index}
        styleName={cn('modal__btn', { red: btn.isRed })}
        label={this.i(mes[btn.label]) || this.i(btn.label)}
        type={btn.type || 'default'}
        size="large"
        onClick={btn.onClick || onClose}
        visibility={btn.visibility || 'visible'}
      />
    ));
  }

  render() {
    const { data, visibility, onClose } = this.props;
    return (
      <Modal
        styleName="modal"
        visibility={visibility}
        onClose={onClose}
        label={`${this.i(mes[data.title]) || ''} ${data.extraTitle || ''}`}
      >
        <ModalContent styleName="modal__content">
          {data.content || this.renderModalContentText(data.text)}
        </ModalContent>
        <ModalButtons styleName="modal__buttons">
          {this.renderButton(data)}
        </ModalButtons>
      </Modal>
    );
  }
}

InfoModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

InfoModal.contextTypes = {
  intl: intlShape,
};

export default injectIntl(InfoModal);
