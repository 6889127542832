import { SET_TEXT_NODE_EDIT } from '@control-front-end/common/constants/graphActors';
import { createReducer } from '@reduxjs/toolkit';

// Initial state: no text node being edited
const initialState = null;

export default createReducer(initialState, {
  [SET_TEXT_NODE_EDIT]: (state, action) => {
    if (!action.payload) return null;

    return {
      ...action.payload,
    };
  },
});
