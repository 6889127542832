import React from 'react';
import { Icon, Label, Utils } from 'mw-style-react';
import { TRANSACTION_TYPES } from '@control-front-end/common/constants/actorAccounts';
import cn from 'classnames';
import './TransactionStatus.scss';

/**
 * Transaction status with icon
 * @param type
 * @param isDone
 * @returns {JSX.Element}
 * @constructor
 */
function TransactionStatus({ type, isDone, withBg = false }) {
  const getStatusIcon = (status, isDone) => {
    switch (status) {
      case 'removed':
        return 'trash';
      case TRANSACTION_TYPES.AUTHORIZED:
        return isDone ? 'flag' : 'clock';
      case TRANSACTION_TYPES.COMPLETED:
        return 'check';
      case TRANSACTION_TYPES.DECLINED:
      case TRANSACTION_TYPES.REVERSED:
      case TRANSACTION_TYPES.CANCELED:
        return 'close';
      case TRANSACTION_TYPES.MIN_LIMIT_CHANGED:
      case TRANSACTION_TYPES.MAX_LIMIT_CHANGED:
        return 'edit';
      default:
        return null;
    }
  };

  return (
    <div styleName={cn('transactionStatus', `${withBg ? `withBg` : ''}`, type)}>
      <Icon
        styleName={cn('transactionStatus__icon', type)}
        size="small"
        type={getStatusIcon(type, isDone)}
      />
      <Label styleName="status" value={type ? Utils.toUpperLatter(type) : ''} />
    </div>
  );
}

export default TransactionStatus;
