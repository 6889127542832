import { useState, useRef, useEffect, useCallback } from 'react';

const DEFAULT_MIN_ZOOM = 1;
const DEFAULT_MAX_ZOOM = 3;
const ZOOM_STEP = 0.03;
const INITIAL_POSITION = { x: 0, y: 0 };
const DEFAULT_TRANSFORM_ORIGIN = { x: 50, y: 50 };

const getTranslateBounds = (
  scale,
  rect,
  transformOrigin = DEFAULT_TRANSFORM_ORIGIN
) => {
  const originalWidth = rect.width / scale;
  const originalHeight = rect.height / scale;

  const extraWidth = rect.width - originalWidth;
  const extraHeight = rect.height - originalHeight;

  const leftSpace = (transformOrigin.x / 100) * extraWidth;
  const rightSpace = extraWidth - leftSpace;

  const topSpace = (transformOrigin.y / 100) * extraHeight;
  const bottomSpace = extraHeight - topSpace;

  return {
    minX: -rightSpace,
    maxX: leftSpace,
    minY: -bottomSpace,
    maxY: topSpace,
  };
};

const clampTranslate = (translate, bounds) => ({
  x: Math.max(bounds.minX, Math.min(translate.x, bounds.maxX)),
  y: Math.max(bounds.minY, Math.min(translate.y, bounds.maxY)),
});

function useZoomAndPan({
  minZoom = DEFAULT_MIN_ZOOM,
  maxZoom = DEFAULT_MAX_ZOOM,
  zoomPanEnabled = true,
} = {}) {
  const [scale, setScale] = useState(minZoom);
  const scaleRef = useRef(minZoom);
  const [translate, setTranslate] = useState(INITIAL_POSITION);
  const translateRef = useRef(INITIAL_POSITION);
  const [transformOrigin, setTransformOrigin] = useState(
    DEFAULT_TRANSFORM_ORIGIN
  );
  const transformOriginRef = useRef(DEFAULT_TRANSFORM_ORIGIN);
  const containerRef = useRef(null);
  const lastMousePosition = useRef(INITIAL_POSITION);
  const isDragging = useRef(false);

  const applyTransform = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    container.style.transformOrigin = `${transformOriginRef.current.x}% ${transformOriginRef.current.y}%`;
    container.style.transform =
      `translate(${translateRef.current.x}px, ${translateRef.current.y}px) ` +
      `scale(${scaleRef.current})`;
  }, []);

  const setZoomAndPan = useCallback(
    (changes = {}) => {
      // reset to default in case of empty changes
      const {
        zoom: newZoom = minZoom,
        translate: newTranslate = INITIAL_POSITION,
      } = changes;
      scaleRef.current = newZoom;
      translateRef.current = newTranslate;
      transformOriginRef.current = DEFAULT_TRANSFORM_ORIGIN;

      setScale(newZoom);
      setTranslate(newTranslate);
      setTransformOrigin(DEFAULT_TRANSFORM_ORIGIN);

      applyTransform();
    },
    [minZoom, applyTransform]
  );

  const handleZoom = useCallback(
    (event) => {
      event.preventDefault();
      const container = containerRef.current;
      if (!container) return;

      const rect = container.getBoundingClientRect();
      const { clientX, clientY, deltaY } = event;

      const newScale = Math.min(
        maxZoom,
        Math.max(
          minZoom,
          scaleRef.current + (deltaY > 0 ? -ZOOM_STEP : ZOOM_STEP)
        )
      );

      if (newScale === minZoom) {
        setZoomAndPan();
        return;
      }

      if (newScale === scaleRef.current) return;

      const offsetX = ((clientX - rect.left) / rect.width) * 100;
      const offsetY = ((clientY - rect.top) / rect.height) * 100;

      scaleRef.current = newScale;
      setScale(newScale);

      transformOriginRef.current = { x: offsetX, y: offsetY };
      setTransformOrigin({ x: offsetX, y: offsetY });

      const bounds = getTranslateBounds(
        scaleRef.current,
        rect,
        transformOriginRef.current
      );

      const clamped = clampTranslate(translateRef.current, bounds);
      translateRef.current = clamped;
      setTranslate(clamped);

      applyTransform();
    },
    [minZoom, maxZoom, applyTransform]
  );

  const handlePan = useCallback(
    (deltaX, deltaY) => {
      const container = containerRef.current;
      if (!container) return;

      const rect = container.getBoundingClientRect();
      const bounds = getTranslateBounds(
        scaleRef.current,
        rect,
        transformOriginRef.current
      );

      const newTranslate = {
        x: translateRef.current.x - deltaX,
        y: translateRef.current.y - deltaY,
      };

      const clamped = clampTranslate(newTranslate, bounds);

      translateRef.current = clamped;
      setTranslate(clamped);

      applyTransform();
    },
    [applyTransform]
  );

  useEffect(() => {
    const handleWheel = (event) => {
      if (scaleRef.current !== minZoom) {
        event.preventDefault();
        event.stopPropagation();
      }

      const { deltaX, deltaY, ctrlKey, metaKey } = event;

      if (ctrlKey || metaKey) {
        handleZoom(event);
      } else if (scaleRef.current > minZoom) {
        handlePan(deltaX, deltaY);
      }
    };

    const handleMouseDown = (event) => {
      if (scaleRef.current === minZoom) return;
      isDragging.current = true;
      lastMousePosition.current = { x: event.clientX, y: event.clientY };
    };

    const handleMouseMove = (event) => {
      if (!isDragging.current) return;

      const dx = event.clientX - lastMousePosition.current.x;
      const dy = event.clientY - lastMousePosition.current.y;

      handlePan(-dx, -dy);

      lastMousePosition.current = { x: event.clientX, y: event.clientY };
    };

    const handleMouseUp = () => {
      isDragging.current = false;
    };

    if (zoomPanEnabled) {
      const container = containerRef.current;
      container?.addEventListener('wheel', handleWheel, { passive: false });
      container?.addEventListener('mousedown', handleMouseDown);
      container?.addEventListener('dblclick', setZoomAndPan, false);
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);

      containerRef?.current?.focus();
    }

    return () => {
      const container = containerRef.current;
      container?.removeEventListener('wheel', handleWheel);
      container?.removeEventListener('mousedown', handleMouseDown);
      container?.removeEventListener('dblclick', setZoomAndPan);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [containerRef.current, zoomPanEnabled]);

  return {
    containerRef,
    scale,
    translate,
    transformOrigin,
    setZoomAndPan,
  };
}

export default useZoomAndPan;
