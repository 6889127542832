import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Chip, Tooltip, Clipboard, Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { useIntl, useNotifications } from 'hooks';
import mes from './intl';
import './CopyIdChip.scss';

/**
 * Chip for copying entity ID
 * @returns {*}
 * @constructor
 */
function CopyIdChip({ id, type, fontSize, unspaced, className }) {
  const t = useIntl();
  const showNotification = useNotifications();
  const idString = id?.toString();

  return id ? (
    <Tooltip
      value={t(mes[`copy${Utils.toUpperLatter(type)}Id`]) || t(mes.copyId)}
    >
      <Chip
        styleName={cn('idChip', { unspaced })}
        className={cn('idChip', className)}
        label={AppUtils.isUuid(idString) ? idString.split('-')[0] : idString}
        size="small"
        fontSize={fontSize}
        fontWeight="normal"
        icon="copy"
        unspaced={unspaced}
        closeIcon={false}
        type="rectangular"
        onClick={(e) => {
          e.stopPropagation();
          Clipboard.copy(idString.replace(/\s/g, '').trim());
          showNotification.success(
            t(mes[`${type}IdCopied`]) || t(mes.defaultIdCopied)
          );
        }}
      />
    </Tooltip>
  ) : null;
}

CopyIdChip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  fontSize: PropTypes.oneOf(['small', 'medium']),
  unspaced: PropTypes.bool,
  type: PropTypes.oneOf([
    'default',
    'actor',
    'edge',
    'edgeType',
    'edgeTypeId',
    'template',
    'ref',
    'account',
    'transaction',
    'transfer',
  ]),
};

export default CopyIdChip;
