import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Utils } from 'mw-style-react';

import { GRAPH_NODE_SIZE } from '@control-front-end/common/constants/graphActors';
import AppUtils from '@control-front-end/utils/utils';
import { AVERAGE_CHAR_WIDTH_FACTOR } from 'constants';

/**
 * Poppers for the graph
 */
function GraphPopper(props) {
  const {
    graph,
    graphContainer,
    graphEdgeHandles,
    edgeReconnect,
    ehStarted,
    readOnly,
    isSingleLayerModel,
  } = props;

  const currentMarkupStateIdRef = useRef(null);

  const addPopper = (params) => {
    const {
      graphElement,
      popper,
      style,
      title,
      tooltipTitle,
      renderedPosition,
      placement,
      modifiers: customModifiers,
      offset,
    } = params;
    const { className, backgroundColor, totalSize, fontSize, scalable, width } =
      style;
    popper.div = document.createElement('div');
    popper.div.classList.add(className);
    popper.div.style.backgroundColor = backgroundColor;
    const sourceGrabbable = graphElement.source()
      ? graphElement.source().grabbable()
      : false;
    const layerSettings = graphElement.data('layerSettings');
    const isPinned = layerSettings && layerSettings.pin;
    popper.div.style.display =
      graphElement.grabbable() ||
      sourceGrabbable ||
      (className === 'popper-handle' && isPinned) ||
      className === 'popper-linked-actor'
        ? 'block'
        : 'none';
    const currentScale = graph.current.zoom();
    const popperSize = totalSize * currentScale;
    if (totalSize) {
      popper.div.style.width = `${popperSize}px`;
      popper.div.style.height = `${popperSize}px`;
      popper.div.style.backgroundSize = `${popperSize}px`;
    }
    if (fontSize) popper.div.style.fontSize = `${fontSize * currentScale}px`;
    popper.format = { totalSize, fontSize, scalable };
    if (title) {
      const label = document.createElement('span');
      label.innerHTML = title.length <= 30 ? title : `${title.slice(0, 30)}...`;
      label.style.position = 'absolute';
      label.style.left = '30px';
      popper.div.appendChild(label);
    }
    if (tooltipTitle) {
      const label = document.createElement('span');
      label.innerHTML = tooltipTitle;
      popper.div.style.display = 'flex';
      popper.div.style.width = `${width || 0}px`;
      popper.div.appendChild(label);
    }
    graphContainer.current.appendChild(popper.div);
    const popperConfig = {
      content: popper.div,
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: offset || [0, -(popperSize || 0) / 2],
            },
          },
        ],
      },
    };
    if (renderedPosition) popperConfig.renderedPosition = renderedPosition;
    if (placement) popperConfig.popper.placement = placement;
    if (customModifiers) popperConfig.popper.modifiers.push(...customModifiers);
    popper.obj = graphElement.popper(popperConfig);
  };

  const updatePopper = (popper) => {
    if (popper.obj) popper.obj.update();
    if (!popper.div || !popper.format || !popper.format.scalable) return;
    const { totalSize, fontSize } = popper.format;
    const currentScale = graph.current.zoom();
    const popperSize = totalSize * currentScale;
    popper.div.style.width = `${popperSize}px`;
    popper.div.style.height = `${popperSize}px`;
    popper.div.style.backgroundSize = `${popperSize}px`;
    if (fontSize) popper.div.style.fontSize = `${fontSize * currentScale}px`;
  };

  const removePopper = (popper) => {
    if (popper.obj) {
      popper.obj = null;
    }
    popper.clicked = false;
    if (popper.div) {
      // remove event listeners
      const clonedElement = popper.div.cloneNode(true);
      popper.div.replaceWith(clonedElement);
      graphContainer.current.removeChild(clonedElement);
      popper.div = null;
    }
  };

  /**
   * Display linked actor for connection
   */
  const edgeLinkedActorPopper = (edge) => {
    if (!edge) return;
    const popper = {};

    const addHandle = () => {
      if (popper.div) return;
      if (edge.removed()) return;
      const popperActor = edge.data('linkedActor');
      if (!popperActor) return;
      addPopper({
        graphElement: edge,
        popper,
        style: {
          className: 'popper-linked-actor',
          backgroundColor: popperActor.color || 'white',
          totalSize: 16,
          fontSize: 12,
          scalable: true,
        },
        title: popperActor.title || '',
        placement: 'bottom',
      });
    };

    const updateHandle = () => {
      updatePopper(popper);
    };

    const removeHandle = () => {
      currentMarkupStateIdRef.current = null;
      removePopper(popper);
    };

    const changeStyleHandle = () => {
      if (edge.classes().includes('hide')) {
        removeHandle();
        return;
      }
      addHandle();
      if (!popper.div) return;
      if (edge.classes().includes('faded')) {
        popper.div.classList.add('faded');
      } else {
        popper.div.classList.remove('faded');
      }
    };

    const coordIsNaN = ({ x, y }) => {
      return Number.isNaN(x) || Number.isNaN(y);
    };

    const moveNodesHandle = () => {
      if (!edge) return;
      if (coordIsNaN(edge.midpoint())) {
        removeHandle();
        return;
      }
      addHandle();
    };

    if (edge.data('linkedActorId')) {
      addHandle();
    }
    graph.current.on('pan zoom resize render', updateHandle);
    edge.on('data', () => {
      if (!edge.data('linkedActorId')) {
        removeHandle();
        return;
      }
      addHandle();
    });
    edge.on('style', changeStyleHandle);
    edge.on('remove', removeHandle);
    const sourceNode = edge.source();
    const targetNode = edge.target();
    sourceNode.on('position', moveNodesHandle);
    targetNode.on('position', moveNodesHandle);
  };

  /**
   * Hook for new connection
   */
  const edgeHandlesPopper = () => {
    const popper = {};

    const removeHandle = () => {
      return removePopper(popper);
    };

    const setHandleOn = (node) => {
      if (ehStarted.current) return;
      const {
        isLayer,
        isAutoLayerNode,
        accessDenied,
        readOnly: readOnlyNode,
        isNonInteractive,
        isStateMarkup,
      } = node.data();
      if (
        accessDenied ||
        readOnlyNode ||
        readOnly ||
        isLayer ||
        isAutoLayerNode ||
        isNonInteractive ||
        isStateMarkup
      )
        return;
      addPopper({
        graphElement: node,
        popper,
        style: {
          className: 'popper-handle',
          totalSize: 16,
        },
        placement: 'bottom',
      });
      popper.div.addEventListener('mousedown', (e) => {
        e.stopPropagation();
        graphEdgeHandles.current.start(node);
      });
      node.on('mouseout', removeHandle);
    };

    const handleMouseOver = (event) => {
      const selectedEdges = graph.current.elements('edge:selected');
      if (selectedEdges.length) return;
      setHandleOn(event.target);
    };

    graph.current.on('mouseover', 'node', handleMouseOver);
    graph.current.on('zoom pan', removeHandle);
    graph.current.on('grab', 'node', removeHandle);
    graph.current.on('tap', (e) => {
      if (e.target === graph.current) removeHandle();
    });
    graph.current.on('ehstart', () => {
      ehStarted.current = true;
    });
    graph.current.on('ehstop', () => {
      ehStarted.current = false;
    });
  };

  /**
   * Hook at the end of connection for changing source/target
   */
  const edgeReconnectPopper = (edge, side) => {
    const popper = {};
    const edgeSideNode = edge[side]();
    const anotherSide = side === 'source' ? 'target' : 'source';
    const getPosFunc = `rendered${Utils.capitalize(anotherSide)}Endpoint`;
    const targetSideNode = edge[anotherSide]();
    if (
      edgeSideNode.data('isLayer') ||
      edgeSideNode.data('isAutoLayerRoot') ||
      targetSideNode.data('isAutoLayerRoot')
    )
      return;

    const updateHandle = () => {
      return updatePopper(popper);
    };

    const removeHandle = () => {
      return removePopper(popper);
    };

    const handleMouseDown = (e) => {
      e.stopPropagation();
      edge.addClass('hide');
      removeHandle();
      graphEdgeHandles.current.start(edgeSideNode);
      edgeReconnect.current = { id: edge.id(), side: anotherSide };
    };

    const addHandle = () => {
      addPopper({
        graphElement: edge,
        popper,
        style: {
          className: 'popper',
        },
        renderedPosition: () => edge[getPosFunc](),
        offset: [-2, -6],
      });
      popper.div.addEventListener('mousedown', handleMouseDown);
    };

    const handleEhPreview = () => {
      const eh = graph.current.$('.eh-ghost-edge, .eh-preview');
      // set arrow for connection preview depending on its direction
      const ehPreviewClass =
        edgeReconnect.current && edgeReconnect.current.side === 'source'
          ? 'eh-reverse-arrow'
          : 'eh-arrow';
      eh.forEach((i) => i.addClass(ehPreviewClass));
    };

    addHandle();
    graph.current.on('pan zoom resize', updateHandle);
    graph.current.on('ehpreviewon ehhoverover', handleEhPreview);
    edge.on('unselect remove', removeHandle);
    edgeSideNode.on('position', () => edge.unselect());
  };

  const edgeAddTransferPopper = (handleClick) => {
    const popper = {};

    const removeHandle = () => {
      handleClick(null);
      graph.current.elements('edge:selected').unselect();
      return removePopper(popper);
    };

    const setHandleOn = (edge) => {
      if (
        isSingleLayerModel ||
        edge.data('isNonInteractive') ||
        edge.data('isTree')
      ) {
        return;
      }
      addPopper({
        graphElement: edge,
        popper,
        style: {
          className: 'popper-handle',
          totalSize: 16,
        },
        placement: 'bottom',
      });
      if (popper.div && handleClick) {
        popper.div.addEventListener('mousedown', () => {
          popper.clicked = true;
          handleClick({ edge, popperDiv: popper.div, removeHandle });
        });
      }
      edge.on('mouseout', () => {
        if (!popper.clicked) removeHandle();
      });
    };

    const handleMouseOver = (event) => {
      const selectedEdges = graph.current.elements('edge:selected');
      if (selectedEdges.length) return;
      setHandleOn(event.target);
    };

    graph.current.on('mouseover', 'edge', handleMouseOver);
    graph.current.on('zoom pan', removeHandle);
    graph.current.on('grab', 'node', removeHandle);
    graph.current.on('tap', (e) => {
      if (e.target === graph.current) {
        removeHandle();
        if (handleClick) handleClick(null);
      }
    });
  };

  const stateNodePopper = (onClick) => {
    const statePopper = {};

    const removeHandle = () => {
      removePopper(statePopper);
    };

    const setHandleOn = (node) => {
      const { x1, y1, x2, y2 } = graph.current.extent();
      const polygon = node.data('polygon');
      if (!polygon) return;
      const { x2: x, y2: y } = AppUtils.makeBoundingBoxFromPolygon(polygon);
      // if state right-bottom corner is outside - don't show the popper
      // otherwise cytoscape-popper render it in unexpected position with ui bugs
      const popperPlaceInViewport = x > x1 && x < x2 && y > y1 && y < y2;
      if (!popperPlaceInViewport) return;
      const zoom = graph.current.zoom();
      const radius = GRAPH_NODE_SIZE / 2;
      const padding = 8;
      const halfWidth = node.renderedBoundingbox().w / 2;
      addPopper({
        graphElement: node,
        popper: statePopper,
        style: {
          className: 'popper-handle',
          totalSize: radius,
        },
        placement: 'bottom',
        offset: [
          halfWidth - (radius - padding) * zoom,
          -(radius + padding) * zoom,
        ],
      });

      if (statePopper.div && onClick) {
        statePopper.div.addEventListener('mousedown', (e) => {
          e.stopPropagation();
          if (statePopper.clicked) {
            statePopper.clicked = false;
            onClick(null);
          } else {
            statePopper.clicked = true;
            onClick({
              stateActor: node.data(),
              popperDiv: statePopper.div,
              position: node.position(),
              renderedPosition: { x: e.clientX, y: e.clientY },
            });
          }
        });
      }

      node.on('mouseout grab', ({ type, position }) => {
        if (!position) return;
        const { x, y } = position;
        const { x1, x2, y1, y2 } = node.boundingBox();
        const mouseInState = x > x1 && x < x2 && y > y1 && y < y2;
        if (type === 'mouseout' && mouseInState) return;
        removeHandle();
      });
      graph.current.on('pan zoom resize render', removeHandle);
    };

    graph.current.on('mousemove', 'node[?isStateMarkup][!isTrace]', (e) => {
      if (
        !statePopper.obj ||
        currentMarkupStateIdRef.current !== e.target.id()
      ) {
        removeHandle();
        currentMarkupStateIdRef.current = e.target.id();
        setHandleOn(e.target);
      }
    });
  };

  const nodeTitleTooltipPopper = () => {
    const popper = {};

    const removeHandle = () => {
      return removePopper(popper);
    };

    const showTooltip = (node) => {
      const { title, isTrace, tooltip } = node.data();
      const fontSize = 14;
      const tooltipTitle = tooltip || title || '';
      const approximateWidth =
        tooltipTitle.length * fontSize * AVERAGE_CHAR_WIDTH_FACTOR;
      addPopper({
        graphElement: node,
        popper,
        style: {
          className: 'popper-tooltip',
          width: Math.min(approximateWidth, 360),
        },
        placement: 'top',
        tooltipTitle,
        offset: [0, 6],
        modifiers: [
          {
            name: 'flip',
            enabled: true,
            options: {
              fallbackPlacements: [
                'top',
                'top-start',
                'top-end',
                'bottom',
                'bottom-start',
                'bottom-end',
                'left',
                'left-start',
                'left-end',
                'right',
                'right-start',
                'right-end',
              ], // Flip to any of these positions if needed
              padding: {
                top: 90,
                bottom: 40,
                left: 45,
              },
            },
          },
        ],
      });
      if (!isTrace) {
        popper.div.addEventListener('mousedown', (e) => {
          e.stopPropagation();
          graphEdgeHandles.current.start(node);
        });
      }
      node.on('mouseout', removeHandle);
    };

    graph.current.on('mouseover', 'node', (event) => {
      showTooltip(event.target);
    });
    graph.current.on('zoom pan', removeHandle);
    graph.current.on('grab', 'node', removeHandle);
  };

  return {
    edgeLinkedActorPopper,
    edgeReconnectPopper,
    edgeHandlesPopper,
    edgeAddTransferPopper,
    stateNodePopper,
    nodeTitleTooltipPopper,
  };
}

GraphPopper.propTypes = {
  graph: PropTypes.object,
  graphContainer: PropTypes.object,
  edgeReconnect: PropTypes.object,
  ehStarted: PropTypes.object,
  readOnly: PropTypes.bool,
  isSingleLayerModel: PropTypes.bool,
};

export default GraphPopper;
