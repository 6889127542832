import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Checkbox as CheckMW } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import Edit from '../Edit/Edit';
import mes from '../../intl';
import './Check.scss';

/**
 * Check Constructor Component
 */
class Check extends PureComponent {
  // Localization function
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  render() {
    const { id } = this.props;
    return (
      <div styleName="check">
        <CheckMW id={id} styleName="check__box" />
        <Edit placeholder={this.i(mes.checkPlaceholder)} {...this.props} />
      </div>
    );
  }
}

Check.propTypes = {
  id: PropTypes.string.isRequired,
};

Check.contextTypes = {
  intl: intlShape,
};

export default injectIntl(Check);
