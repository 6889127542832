import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { facingModeFromLocalTrack } from 'livekit-client';
import { ParticipantPlaceholder } from '@livekit/components-react';
import { useVisualEffects } from '../../hooks';
import EffectsButton from '../EffectsButton';
import scss from './VideoPreview.scss';

/**
 * VideoPreview component that displays a local video track with visual effects support.
 * This component is used in both PreJoin and VisualEffectsPopup components.
 *
 * @param {Object} props - Component props
 * @param {Track} props.videoTrack - The local video track to display
 * @param {boolean} props.isCameraEnabled - Whether the camera is enabled
 * @param {Object} props.style - Additional styles to apply to the container
 * @param {string} props.className - Additional CSS class names
 */
function VideoPreview({
  videoTrack,
  withEffects,
  isCameraEnabled,
  style,
  className,
}) {
  const videoEl = useRef(null);

  useVisualEffects({ videoTrack, isCameraEnabled });

  const facingMode = useMemo(() => {
    if (videoTrack) {
      const { facingMode } = facingModeFromLocalTrack(videoTrack);
      return facingMode;
    }
    return 'undefined';
  }, [videoTrack]);

  useEffect(() => {
    if (videoEl.current && videoTrack) {
      videoTrack.unmute();
      videoTrack.attach(videoEl.current);
    }
    return () => {
      videoTrack?.detach();
    };
  }, [videoTrack]);

  return (
    <div className={cn(scss.videoPreview, className)} style={style}>
      {videoTrack && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          ref={videoEl}
          width="1280"
          height="720"
          data-lk-facing-mode={facingMode}
        />
      )}
      {(!videoTrack || !isCameraEnabled) && (
        <div className={scss.videoPlaceholder}>
          <ParticipantPlaceholder />
        </div>
      )}
      {withEffects && (
        <EffectsButton className={scss.videoEffectsButton} displayEnabled />
      )}
    </div>
  );
}

VideoPreview.propTypes = {
  videoTrack: PropTypes.object,
  isCameraEnabled: PropTypes.bool,
  withEffects: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default VideoPreview;
