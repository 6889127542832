import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useModal,
  useIntl,
  useOutsideClick,
  useFullScreenHandler,
} from 'hooks';
import cn from 'classnames';
import { Space, Button, Icon, cr } from 'mw-style-react';
import {
  UPDATE_USER_SETTINGS,
  MY_SIMULATOR_GRAPH_REF,
  MY_TWIN_LAYER_REF,
  FULLSCREEN_DESCRIPTORS,
} from '@control-front-end/common/constants';
import AppUtils from '@control-front-end/utils/utils';
import history from '@control-front-end/app/src/store/history';
import { getActiveAccount } from 'selectors';
import useActorsBagActions from '@control-front-end/app/src/routes/ActorsGraph/hooks/useActorsBagActions';
import MenuItem from './components/MenuItem';
import UserProfile from './components/UserProfile';
import Workspace from './components/Workspace';
import Notifications from '../Notifications';
import Logo from './img/logo.svg';
import getMenu from './getMenu';
import mes from './components/MenuItem/intl';
import './Menu.scss';

function Menu({ toggleMenu, isSlideout = false }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const collapseBtn = useRef();
  const menuRef = useRef();
  const isMobile = AppUtils.isMobile();
  const { collapsedSidebar } = useSelector((state) => state.settings);
  const config = useSelector((state) => state.config);
  const { id: userId } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(
    collapsedSidebar && !isMobile && !isSlideout
  );
  const [activePopupId, setActivePopupId] = useState(null);
  const activeAccount = useSelector(getActiveAccount) || {};
  const { id: accId, name: activeAccName } = activeAccount;
  const { handleSearchActors } = useActorsBagActions({});
  const [isGraphsFullScreen] = useFullScreenHandler(
    FULLSCREEN_DESCRIPTORS.GRAPHS
  );

  const navigateMyVerseLayer = () => {
    history.push(`/actors_graph/${accId}/profile`);
  };

  const navigateMyTwinLayer = (mySimulatorGraphId, myTwinLayerId) => {
    if (!mySimulatorGraphId || !myTwinLayerId) {
      history.push(`/actors_graph/${accId}/profile`);
      return;
    }
    history.push(
      `/actors_graph/${accId}/graph/${mySimulatorGraphId}/layers/${myTwinLayerId}`
    );
    document.title = `${t(mes.mySimulator)} - ${activeAccName}`;
  };

  const searchLayerCallback = (searchResults, mySimulatorGraphId) => {
    const myTwinLayerId = searchResults?.[0]?.id;
    if (!myTwinLayerId) {
      navigateMyVerseLayer();
      return;
    }
    navigateMyTwinLayer(mySimulatorGraphId, myTwinLayerId);
  };

  const searchGraphCallback = (searchResults) => {
    const mySimulatorGraphId = searchResults?.[0]?.id;
    if (!mySimulatorGraphId) {
      navigateMyVerseLayer();
      return;
    }
    const myTwinLayerRef = MY_TWIN_LAYER_REF.replace('{userId}', userId);
    handleSearchActors({
      query: myTwinLayerRef,
      offset: 0,
      localState: true,
      callback: (data) => searchLayerCallback(data, mySimulatorGraphId),
    });
  };

  const handleMyVerseClick = () => {
    const mySimulatorGraphRef = MY_SIMULATOR_GRAPH_REF.replace(
      '{userId}',
      userId
    );
    handleSearchActors({
      query: mySimulatorGraphRef,
      offset: 0,
      localState: true,
      callback: searchGraphCallback,
    });
  };

  const menu = getMenu({
    config,
    handleMyVerseClick,
  });

  const closeMenuPopup = () => setActivePopupId(null);

  const { open: openInviteMembersModal } = useModal('InviteMembersModal');

  useOutsideClick({ ref: menuRef, callback: closeMenuPopup });
  useEffect(() => {
    if (menuRef.current)
      menuRef.current.addEventListener('mouseout', closeMenuPopup);
    return () => {
      if (menuRef.current)
        menuRef.current.removeEventListener('mouseout', closeMenuPopup);
    };
  }, []);

  /**
   * Opening/closing the menu
   */
  const handleCollapseMenu = () => {
    if (isMobile) return;
    setCollapsed(!collapsed);
    dispatch({
      type: UPDATE_USER_SETTINGS.REQUEST,
      payload: { accId: null, collapsedSidebar: !collapsedSidebar },
    });
  };

  const getActiveMenu = () => {
    const menuList = [];
    menu.forEach((item) => {
      menuList.unshift(item);
      if (item.children.length) menuList.unshift(...item.children);
    });
    let active;
    menuList.forEach((item) => {
      if (!item.link) return;
      const link = item.link.replace('{{accId}}', '(\\S+)');
      const re = new RegExp(link);
      if (!re.test(String(document.location.pathname))) return;
      active = active && active.link >= item.link ? active : item;
    });
    return active;
  };

  useEffect(() => {
    const activeMenu = getActiveMenu();
    if (activeMenu)
      document.title = `${t(mes[activeMenu.title])} - ${activeAccount.name}`;
  }, [document.location.href, activeAccount]);

  const renderMenuItems = () => {
    const { id: accId, role } = activeAccount;
    return menu.map((item, index) => (
      <MenuItem
        key={item.id}
        itemIndex={index}
        activePopupId={activePopupId}
        setActivePopupId={(id) => setActivePopupId(id)}
        accId={accId}
        role={role}
        menuItem={item}
        open={isSlideout}
        collapsed={collapsed}
      />
    ));
  };

  return cr([
    !isGraphsFullScreen,
    <div
      ref={menuRef}
      styleName={cn('menu', {
        collapsed,
        slideout: isSlideout,
      })}
    >
      <div styleName="menu__header">
        <NavLink
          className="logo"
          to={AppUtils.makeUrl(`/events/${activeAccount.id}/list/all`)}
        >
          <img alt="track" src={Logo} />
        </NavLink>
        <Notifications collapsed={collapsed} />
      </div>
      <div styleName="menu__content">{renderMenuItems()}</div>
      <div styleName="menu__footer">
        <div
          ref={collapseBtn}
          styleName="menu__footer__arrow"
          onClick={handleCollapseMenu}
        >
          <Icon size="small" type="arrows" />
        </div>
        <Space left right size="xsmall">
          <Button
            fullWidth
            type="text"
            icon="person_add"
            label={collapsed ? null : t(mes.inviteUsers)}
            onClick={() => {
              openInviteMembersModal();
              if (toggleMenu) {
                toggleMenu(false);
              }
            }}
          />
        </Space>
        <div styleName="menu__footer__profile">
          <Workspace collapsed={collapsed} />
          <UserProfile collapsed={collapsed} />
        </div>
      </div>
    </div>,
  ]);
}

Menu.propTypes = {
  isSlideout: PropTypes.bool,
  toggleMenu: PropTypes.func,
};

export default Menu;
