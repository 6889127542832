import { useState, useEffect } from 'react';
import { useRoomContext } from '@livekit/components-react';

const SEC = 1000;
const MIN = 60000;
const H = 3600000;

/**
 * Custom hook that calculates and formats the duration of a call based on its start time.
 *
 * This hook uses the creation time of a call retrieved from the room context and updates the
 * call duration in real time. The duration is formatted as `HH:mm:ss` and is updated every second.
 *
 * @function useCallDuration
 * @returns {string} The formatted call duration in the format `HH:mm:ss`.
 */
const useCallDuration = () => {
  const { roomInfo } = useRoomContext();
  const [callDuration, setCallDuration] = useState('00:00:00');
  const TIMER_INTERVAL = 1000;

  const formatDuration = (t) => {
    const hours = String(Math.floor(t / H)).padStart(2, '0');
    const minutes = String(Math.floor((t % H) / MIN)).padStart(2, '0');
    const seconds = String(Math.floor((t % MIN) / SEC)).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (!roomInfo?.creationTime) return;
    const callStartTimestamp = +roomInfo.creationTime.toString() * 1000;

    const timer = setInterval(() => {
      const elapsedTime = Date.now() - callStartTimestamp;
      setCallDuration(formatDuration(elapsedTime));
    }, TIMER_INTERVAL);

    return () => clearInterval(timer); // Clear timer on unmount
  }, [roomInfo?.creationTime]);

  return callDuration;
};

export default useCallDuration;
