import { useEffect, useState } from 'react';
import AppUtils from '@control-front-end/utils/utils';

const useRelativeTime = ({ timestamp }) => {
  const [relativeTime, setRelativeTime] = useState(() =>
    AppUtils.getTimeAgo(timestamp)
  );

  useEffect(() => {
    const updateRelativeTime = setInterval(() => {
      setRelativeTime(AppUtils.getTimeAgo(timestamp));
    }, 60 * 1000);
    return () => clearInterval(updateRelativeTime);
  }, []);

  return relativeTime;
};

export default useRelativeTime;
