import React from 'react';
import { Button, Icon, Tooltip } from 'mw-style-react';
import mes from '@control-front-end/app/src/globalIntl';
import { useIntl, useModal } from '@control-front-end/app/src/hooks';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AppUtils from '@control-front-end/utils/utils';

/**
 * Renders a button component with visual effects settings functionality.
 * The button supports customizable styles, label visibility, full width, and display state.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.className - The CSS class name for the button.
 * @param {boolean} [props.showLabel=false] - Determines if the label should be displayed on the button.
 * @param {boolean} [props.fullWidth=false] - Determines if the button should take full width.
 * @param {boolean} [props.displayEnabled=false] - Flag to change styles, according to the state of virtual effects.
 *
 * @return {JSX.Element} A button component wrapped in a tooltip providing access to visual effects settings.
 */
function EffectsButton({
  className,
  showLabel = false,
  fullWidth = false,
  displayEnabled = false,
}) {
  const t = useIntl();
  const { activeVirtualEffect } = useSelector(
    (state) => state.settings.meeting
  );
  const { openModal: openVisualEffectsModal } = useModal(
    'VisualEffectsSettings',
    {}
  );
  const isEnabled = displayEnabled && Boolean(activeVirtualEffect);

  // Only show the effects button on Chrome or Chromium-based browsers
  if (!AppUtils.isChromiumBased()) {
    return null;
  }

  return (
    <Tooltip value={t(mes.backgroundEffects)}>
      <Button
        className={className}
        fontWeight="normal"
        label={showLabel ? t(mes.backgroundEffects) : null}
        fullWidth={fullWidth}
        type={isEnabled ? 'default' : 'quaternary'}
        icon={
          <Icon
            type="avatar"
            colorType={isEnabled ? Icon.COLOR.white : Icon.COLOR.primary}
          />
        }
        onClick={() => openVisualEffectsModal()}
      />
    </Tooltip>
  );
}

EffectsButton.propTypes = {
  showLabel: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

export default EffectsButton;
