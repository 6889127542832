import { defineMessages } from 'react-intl';

const m = defineMessages({
  uploadCustomBackground: {
    id: 'uploadCustomBackground',
    defaultMessage: 'Upload custom background',
  },
  blurEffect: {
    id: 'blurEffect',
    defaultMessage: 'Blur',
  },
  noEffects: {
    id: 'noEffects',
    defaultMessage: 'No background effect',
  },
  cantDeleteImage: {
    id: 'cantDeleteImage',
    defaultMessage: "You can't delete the active virtual background image",
  },
});

export default m;
