import React, {
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  ArcElement,
  PieController,
  DoughnutController,
  PolarAreaController,
  RadarController,
  LineController,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  RadialLinearScale,
  Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FunnelController, TrapezoidElement } from 'chartjs-chart-funnel';
import { groupBy, orderBy, uniqBy } from 'lodash';
import {
  DASHBOARD_CHART_TYPES,
  DASHBOARD_TYPES_WITH_LEGEND,
  LINED_CHART_TYPES,
} from '@control-front-end/common/constants/graphActors';
import AppUtils from '@control-front-end/utils/utils';
import {
  highlightLineChartItem,
  resetLineChartStyle,
  highlightLegendItem,
  resetLegendStyle,
  findDatasetIndex,
} from './utils';

ChartJS.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  FunnelController,
  PieController,
  DoughnutController,
  PolarAreaController,
  RadarController,
  LineController,
  ArcElement,
  TrapezoidElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  RadialLinearScale,
  Filler
);

const LABELS_FONT = {
  color: '#393F48',
  font: {
    family: 'Open Sans,sans-serif',
    size: 14,
  },
};

const TICKS_FONT = {
  color: '#7E8A9A',
  font: {
    family: 'Open Sans,sans-serif',
    size: 12,
  },
};

const POLAR_TICKS_OPTIONS = {
  showLabelBackdrop: true,
  backdropColor: '#F9FAFB',
  z: 2, // Put ticks on top
  backdropPadding: {
    x: 4,
    y: 2,
  },
};

const DATALABELS_FONT = {
  ...TICKS_FONT,
  backgroundColor: '#393F48',
  borderRadius: 4,
  color: '#ffffff',
  align: 'center',
  opacity: 0.7,
  clip: false,
  clamp: true,
  display: true,
};

const CHART_TYPES = Object.keys(DASHBOARD_CHART_TYPES).map(
  (key) => DASHBOARD_CHART_TYPES[key]
);

const GET_TYPE = (type) => {
  if (type === DASHBOARD_CHART_TYPES.STACKED_BAR) {
    return DASHBOARD_CHART_TYPES.BAR;
  }
  return type;
};

const tooltipFooter = ({ type, tooltipItems }) => {
  if (type !== DASHBOARD_CHART_TYPES.STACKED_BAR) return undefined;
  const total = Object.entries(tooltipItems[0]?.parsed?._stacks?.y).reduce(
    (acc, [key, value]) => {
      if (key.charAt(0) === '_') return acc;
      return (acc += value);
    },
    0
  );
  return `Total: ${total}`;
};

/**
 * React wrapper component for Chart.js
 * https://www.chartjs.org/
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Chart(props) {
  const {
    type = DASHBOARD_CHART_TYPES.LINE,
    getTitle,
    showLegend = true,
    redraw = false,
    dataSet = [],
    yAxis,
    onElementClick,
    displayChartDataLabels = true,
  } = props;
  const [visibleDataSet, setVisibleDataSet] = useState(
    dataSet.map((item) => ({ ...item, visible: !item.hidden }))
  );
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const isLineChartType = useMemo(
    () => LINED_CHART_TYPES.includes(type),
    [type]
  );

  useEffect(() => {
    setVisibleDataSet(
      dataSet.map((item) => ({ ...item, visible: !item.hidden }))
    );
  }, [dataSet]);

  const totalVisibleValue = useMemo(() => {
    return getTitle(
      visibleDataSet.reduce(
        (acc, { visible, value }) => (visible ? acc + value : acc),
        0
      )
    );
  }, [visibleDataSet, getTitle]);

  const handleToggleDataVisibility = useCallback((index) => {
    setVisibleDataSet((prevDataSet) =>
      prevDataSet.map((item, i) =>
        i === index ? { ...item, visible: !item.visible && !item.hidden } : item
      )
    );
  }, []);

  const axesDisplay = useMemo(() => {
    const axes = [
      DASHBOARD_CHART_TYPES.BAR,
      DASHBOARD_CHART_TYPES.STACKED_BAR,
      DASHBOARD_CHART_TYPES.LINE,
    ];
    return axes.includes(type);
  }, [type]);
  const isStacked = useMemo(
    () => type === DASHBOARD_CHART_TYPES.STACKED_BAR,
    [type]
  );
  const hasLegend = useMemo(
    () => DASHBOARD_TYPES_WITH_LEGEND.includes(type),
    [type]
  );
  const sum = useMemo(
    () => dataSet.reduce((a, b) => a + b.value, 0),
    [dataSet]
  );

  /**
   * Callback function for tooltip label
   */
  const getTooltipLabel = useCallback(
    (context) => {
      const { raw, formattedValue } = context;
      if (
        type === DASHBOARD_CHART_TYPES.PIE ||
        type === DASHBOARD_CHART_TYPES.FUNNEL ||
        type === DASHBOARD_CHART_TYPES.DOUGHNUT
      ) {
        const percentage = sum > 0 ? (raw / sum) * 100 : 0;
        return `${formattedValue} (${percentage.toFixed(2)}%)`;
      }
      if (isLineChartType) {
        return `${formattedValue}${raw?.label ? ` (${raw.label})` : ''}`;
      }
      return formattedValue;
    },
    [type, dataSet]
  );

  /**
   * Callback function for tooltip title
   */
  const getTooltipTitle = useCallback(
    (context) => [...new Set(context.map(({ label }) => label))].join('\n'),
    [type, dataSet]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          onClick: (event, legendItem, legend) => {
            if (isLineChartType) {
              const datasetIndex =
                legendItem.datasetIndex ?? findDatasetIndex(legend, legendItem);
              if (datasetIndex !== undefined) {
                const meta = legend.chart.getDatasetMeta(datasetIndex);
                legend.chart.setDatasetVisibility(datasetIndex, meta.hidden);
                handleToggleDataVisibility(datasetIndex);
              }
            } else {
              const index = legendItem.index;
              legend.chart.toggleDataVisibility(index);
              handleToggleDataVisibility(index);
            }
            legend.chart.update();
          },
          onHover: (event, legendItem, legend) => {
            // Highlights the line chart item when hovering over a legend
            if (!isLineChartType) return;
            const datasetIndex =
              legendItem.datasetIndex ?? findDatasetIndex(legend, legendItem);
            highlightLineChartItem(legend.chart, datasetIndex);
          },
          onLeave: (event, legendItem, legend) => {
            // Reset the highlighting of the line chart after the mouse leaves
            if (!isLineChartType) return;
            resetLineChartStyle(legend.chart);
          },
          display: showLegend && hasLegend,
          position: 'bottom',
          labels: {
            pointStyle: 'circle',
            usePointStyle: true,
            ...LABELS_FONT,
          },
        },
        title: {
          display: !!totalVisibleValue,
          text: totalVisibleValue,
        },
        tooltip: {
          callbacks: {
            title: getTooltipTitle,
            label: getTooltipLabel,
            footer: (tooltipItems) => tooltipFooter({ type, tooltipItems }),
          },
        },
        datalabels: {
          ...DATALABELS_FONT,
          display: displayChartDataLabels,
          formatter(value) {
            if (value === 0) return null;
            return value;
          },
          listeners: {
            click: (context) => {
              const { dataIndex, datasetIndex } = context;
              const currentIndex = isLineChartType ? datasetIndex : dataIndex;
              if (currentIndex !== undefined) {
                onElementClick?.(currentIndex);
              }
            },
          },
        },
        zoom: false,
      },
      scales: {
        x: {
          display: axesDisplay,
          ticks: TICKS_FONT,
          stacked: isStacked,
        },
        y: {
          display: axesDisplay,
          ticks: TICKS_FONT,
          stacked: isStacked,
          title: {
            display: !!yAxis,
            text: yAxis.name,
            ...LABELS_FONT,
          },
        },
        ...(type === DASHBOARD_CHART_TYPES.POLAR_AREA
          ? {
              r: {
                min: 0,
                beginAtZero: true,
                ticks: {
                  ...TICKS_FONT,
                  ...POLAR_TICKS_OPTIONS,
                },
              },
            }
          : {}),
      },
      indexAxis: type === DASHBOARD_CHART_TYPES.FUNNEL ? 'y' : 'x',
      onHover: (event, elements) => {
        // Highlight a legend item when hovering over a dataset in the line chart
        if (!isLineChartType) return;
        const chart = chartRef.current;
        if (elements.length) {
          highlightLegendItem(chart, elements);
        } else {
          resetLegendStyle(chart);
        }
      },
      onClick: (event, elements) => {
        if (elements.length === 0) return;
        const { index, datasetIndex } = elements[0];
        const currentIndex = isLineChartType ? datasetIndex : index;

        if (currentIndex !== undefined) {
          onElementClick?.(currentIndex);
        }
      },
    }),
    [type, showLegend, dataSet, totalVisibleValue]
  );

  const makeChartLabels = () => {
    return type === DASHBOARD_CHART_TYPES.RADAR
      ? uniqBy(orderBy(dataSet, 'accountPair'), 'accountPair')?.map(
          ({ accountPairTitle }) => accountPairTitle
        )
      : dataSet.map((item) => item.label);
  };

  const labels = useMemo(() => makeChartLabels(), [type, dataSet]);

  const makeChartDatasets = () => {
    if (isLineChartType)
      return dataSet.map((item) => ({
        data: item.data.map((i) => ({ ...i, label: item.label })),
        label: item.label,
        backgroundColor: item.color,
        borderColor: item.color,
        tension: 0.3,
        fill: type === DASHBOARD_CHART_TYPES.STACKED_BAR,
      }));
    if (type === DASHBOARD_CHART_TYPES.RADAR) {
      const chartActors = groupBy(dataSet, 'actorId');
      return Object.keys(chartActors).map((actorId) => {
        const items = orderBy(chartActors[actorId], 'accountPair');
        return {
          data: items.map((item) => item.value),
          label: items[0].actorTitle,
          backgroundColor: AppUtils.hexToRgba(items[0].color, 0.2),
          borderColor: AppUtils.hexToRgba(items[0].color),
          fill: true,
        };
      });
    }
    return [
      dataSet.reduce(
        (acc, item) => {
          acc.data.push(item.value);
          acc.backgroundColor.push(item.color);
          return acc;
        },
        { data: [], backgroundColor: [] }
      ),
    ];
  };

  /**
   * Chart data
   */
  const data = useMemo(() => {
    return {
      labels: isLineChartType ? [] : labels,
      datasets: makeChartDatasets(),
    };
  }, [type, dataSet]);

  // Set visibility based on dataSet[i].hidden
  const updateDataSetVisibility = () => {
    dataSet.forEach((item, index) => {
      if (!item.hidden) return;
      if (isLineChartType) {
        chartRef.current.setDatasetVisibility(index, false);
      } else if (chartRef.current.getDataVisibility(index)) {
        chartRef.current.toggleDataVisibility(index);
      }
    });
  };

  const renderChart = () => {
    if (!canvasRef.current) return;
    chartRef.current = new ChartJS(canvasRef.current, {
      type: GET_TYPE(type),
      data,
      plugins: !isLineChartType ? [ChartDataLabels] : {},
      options,
    });

    updateDataSetVisibility();
    chartRef.current.update();
  };

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const updateChart = (dataChart) => {
    const { datasets, labels } = dataChart;
    const curData = chartRef.current.data;
    chartRef.current.options = options;

    if (curData.datasets.length !== datasets.length) {
      curData.datasets = datasets;
    } else {
      datasets.forEach((dataset, i) => {
        const { data, label, backgroundColor, borderColor } = dataset;
        const setDataLen = curData.datasets[i].data.length;
        const newData = data || [];

        if (isLineChartType) {
          curData.datasets[i].borderColor = borderColor;
        }

        curData.datasets[i].backgroundColor = backgroundColor;
        curData.datasets[i].label = label;

        if (setDataLen !== newData.length) {
          curData.datasets[i].data = newData;
        } else {
          curData.datasets[i].data.forEach((_, j) => {
            curData.datasets[i].data[j] = newData[j] || 0;
          });
        }
      });
    }
    updateDataSetVisibility();
    curData.labels = labels;
    chartRef.current.update();
  };

  useEffect(() => {
    if (!chartRef.current) return;
    if (redraw) {
      destroyChart();
      setTimeout(renderChart);
    } else {
      updateChart(data);
    }
  }, [redraw, options, data]);

  useEffect(() => {
    renderChart();
    return () => destroyChart();
  }, [type]);
  return (
    <canvas style={{ maxWidth: '100%', maxHeight: '100%' }} ref={canvasRef} />
  );
}

Chart.propTypes = {
  type: PropTypes.oneOf(CHART_TYPES).isRequired,
  getTitle: PropTypes.func,
  showLegend: PropTypes.bool,
  redraw: PropTypes.bool,
  dataSet: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    })
  ),
  yAxis: PropTypes.object,
  onElementClick: PropTypes.func,
  displayChartDataLabels: PropTypes.bool,
};

export default Chart;
