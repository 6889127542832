import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import { Select as SelectMW, MenuItem } from 'mw-style-react';
import '../Panel.scss';

/**
 * Select Component
 */
class Select extends Component {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // Get field value.
  static getValue(options, value) {
    const valueItem = Array.isArray(value) ? value[0] : value;
    if (!valueItem) return value;
    const findOpt = options.find(
      (i) => i.value === value.toString() || i.value === valueItem.value
    );
    return findOpt ? findOpt.value.toString() : '';
  }

  shouldComponentUpdate(nextProps) {
    const { value, options, visibility } = this.props;
    return (
      nextProps.value !== value ||
      nextProps.visibility !== visibility ||
      JSON.stringify(nextProps.options) !== JSON.stringify(options)
    );
  }

  // Select static option
  handleOnChange({ id, value }) {
    const { onChange } = this.props;
    onChange({ id, value });
  }

  // Render selection options
  renderItems(options) {
    options = options || [];
    return options.map((i) => (
      <MenuItem key={i.value} value={i.value} label={i.title} />
    ));
  }

  render() {
    const { id, title, value, options, visibility } = this.props;
    return (
      <div styleName={cn('panel__el')}>
        <SelectMW
          id={id}
          bordered={true}
          label={title}
          value={Select.getValue(options, value)}
          onChange={this.handleOnChange}
          visibility={visibility}
        >
          {this.renderItems(options)}
        </SelectMW>
      </div>
    );
  }
}

Select.defaultProps = {
  errors: {},
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  visibility: PropTypes.string,
};

export default Select;
