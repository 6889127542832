import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Stack, Space, ProgressBar } from 'mw-style-react';
import { useIntl } from '@control-front-end/app/src/hooks';
import VideoPreview from '@control-front-end/common/components/MeetingRoom/components/VideoPreview';
import { usePreviewVideoTrack } from '@control-front-end/common/components/MeetingRoom/hooks';
import mes from 'globalIntl';
import EffectsMenu from './EffectsMenu';
import scss from './VisualEffectsSettings.scss';

/**
 * Modal component for selecting and applying camera visual effects
 */
function VisualEffectsSettings({ visibility, onClose }) {
  const t = useIntl();
  const [saving, setSaving] = useState(false);
  const { videoTrack } = usePreviewVideoTrack();

  useEffect(() => {
    return () => {
      if (videoTrack) {
        videoTrack.stop();
      }
    };
  }, [videoTrack]);

  return (
    <Modal
      size="small"
      label={t(mes.backgroundEffects)}
      onClose={onClose}
      visible={visibility}
    >
      <Space size="small" style={{ position: 'relative' }}>
        <Stack.V alignItems="center">
          {saving && (
            <div className={scss.savingLoader}>
              <ProgressBar size="large" />
            </div>
          )}
          <VideoPreview videoTrack={videoTrack} isCameraEnabled />
          <EffectsMenu onSaving={setSaving} />
        </Stack.V>
      </Space>
    </Modal>
  );
}

VisualEffectsSettings.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

VisualEffectsSettings.defaultProps = {
  visibility: false,
};

export default VisualEffectsSettings;
