/**
 * Transformation of extra fields
 * @param data
 * @param splitData
 * @returns {[*, *]|[*]}
 */
const useExtraFields = (data, splitData = true) => {
  const maxCountInColumn = 4;

  const getFieldType = (i) => {
    if (i.values) return 'select';
    if (i.type === 'date') return 'date';
    if (i.type === 'file') return 'file';
    return 'edit';
  };

  const fields = data.map((i) => ({
    id: i.key,
    obj: 'extraData',
    dataType: i.type === 'number' ? 'int' : 'text',
    label: i.title,
    required: i.required,
    regexp: i.regexp,
    type: getFieldType(i),
    options: i.values ? i.values.map((item) => ({ v: item, t: item })) : [],
  }));

  if (!splitData) return fields;
  // Split data into 2 arrays
  if (fields.length > maxCountInColumn) {
    const half = Math.ceil(fields.length / 2);
    const max = Math.max(maxCountInColumn, half);
    return [fields.slice(0, max), fields.slice(max)];
  }
  return [fields];
};

export default useExtraFields;
