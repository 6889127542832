import merge from 'lodash/merge';
import { useLayoutContext } from '@livekit/components-react';

/**
 * The `useSettingsToggle` function provides state and functions for toggling the settings menu.
 * @remarks
 * Depends on the `LayoutContext` to work properly.
 */
function useSettingsToggle({ props }) {
  const { dispatch, state } = useLayoutContext().widget;
  const className = 'lk-button lk-settings-toggle';

  const mergedProps = merge({}, props, {
    className,
    onClick: () => {
      if (dispatch) dispatch({ msg: 'toggle_settings' });
    },
    'aria-pressed': state?.showSettings ? 'true' : 'false',
  });

  return { mergedProps };
}

export default useSettingsToggle;
