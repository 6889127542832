import { useRef, useEffect, useCallback, useMemo } from 'react';

/**
 * Constants for audio configuration
 */
const AUDIO_CONFIG = {
  // Audio oscillator settings
  BASE_FREQUENCY: 100, // Hz
  ALTERNATE_FREQUENCY: 101, // Hz
  GAIN_VALUE: 0.001, // Near silent volume
  OSCILLATOR_TYPE: 'sine', // Sine wave uses less CPU

  // Keep-alive behavior settings
  REFRESH_INTERVAL: 5000, // 5 seconds between frequency changes
  ACTIVATE_IMMEDIATELY: true, // Start keep-alive as soon as tab is hidden
};

/**
 * Feature detection for the Web Audio API
 * @returns {boolean} Whether the current browser supports Web Audio API
 */
const isAudioContextSupported = () => {
  return (
    typeof window !== 'undefined' &&
    typeof (window.AudioContext || window.webkitAudioContext) !== 'undefined'
  );
};

/**
 * Hook that prevents browser tab suspension during calls by using the Web Audio API
 *
 * When a browser tab goes to the background, browsers may throttle or suspend
 * processing after a period of inactivity. This hook creates a silent audio context
 * that keeps the tab active, ensuring WebRTC connections remain live.
 *
 * The technique is similar to those used by major conferencing platforms like Google Meet.
 *
 * @returns {Object} Status information about the keep-alive mechanism
 * @property {boolean} isActive - Whether the keep-alive is currently running
 * @property {boolean} isSupported - Whether the browser supports this feature
 */
const useKeepAlive = () => {
  // Feature detection result (memoized to prevent recalculation)
  const isSupported = useMemo(() => isAudioContextSupported(), []);

  // References for maintaining state between renders
  const audioContextRef = useRef(null);
  const oscillatorRef = useRef(null);
  const gainNodeRef = useRef(null);
  const keepAliveIntervalRef = useRef(null);
  const isActiveRef = useRef(false);

  /**
   * Initialize the audio context if it doesn't exist yet
   * @returns {AudioContext|null} The created audio context or null if failed
   */
  const initAudioContext = useCallback(() => {
    // Skip if browser doesn't support Audio API or context already exists
    if (!isSupported || audioContextRef.current) {
      return audioContextRef.current;
    }

    try {
      // Create the appropriate audio context for this browser
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      audioContextRef.current = new AudioContext();
      return audioContextRef.current;
    } catch (err) {
      // Silent fail - this is an enhancement feature
      return null;
    }
  }, [isSupported]);

  /**
   * Create and start a silent audio oscillator
   * @returns {boolean} Success status of the operation
   */
  const createSilentAudio = useCallback(() => {
    // Get or create the audio context
    const ctx = audioContextRef.current || initAudioContext();

    // Skip if we couldn't create a context or oscillator already exists
    if (!ctx || oscillatorRef.current) {
      return false;
    }

    try {
      // Create audio nodes
      const oscillator = ctx.createOscillator();
      const gainNode = ctx.createGain();

      // Configure audio properties
      oscillator.type = AUDIO_CONFIG.OSCILLATOR_TYPE;
      oscillator.frequency.value = AUDIO_CONFIG.BASE_FREQUENCY;
      gainNode.gain.value = AUDIO_CONFIG.GAIN_VALUE; // Almost silent

      // Connect the audio graph
      oscillator.connect(gainNode);
      gainNode.connect(ctx.destination);

      // Start the oscillator and store references
      oscillator.start();
      oscillatorRef.current = oscillator;
      gainNodeRef.current = gainNode;
      isActiveRef.current = true;

      return true;
    } catch (err) {
      // Silent fail - non-critical enhancement
      return false;
    }
  }, [initAudioContext]);

  /**
   * Stop and clean up the audio oscillator
   */
  const stopAudio = useCallback(() => {
    if (!oscillatorRef.current) return;

    try {
      // Stop the oscillator
      oscillatorRef.current.stop();

      // Disconnect nodes to free resources
      if (gainNodeRef.current) {
        gainNodeRef.current.disconnect();
        gainNodeRef.current = null;
      }

      oscillatorRef.current.disconnect();
      oscillatorRef.current = null;
      isActiveRef.current = false;
    } catch (err) {
      // Silent catch - this shouldn't prevent other cleanup
    }
  }, []);

  /**
   * Toggle the oscillator frequency to maintain activity
   */
  const toggleFrequency = useCallback(() => {
    if (!oscillatorRef.current) return;

    try {
      // Alternate between two frequencies to signal activity
      const currentFreq = oscillatorRef.current.frequency.value;
      const newFreq =
        currentFreq === AUDIO_CONFIG.BASE_FREQUENCY
          ? AUDIO_CONFIG.ALTERNATE_FREQUENCY
          : AUDIO_CONFIG.BASE_FREQUENCY;

      oscillatorRef.current.frequency.value = newFreq;
    } catch (err) {
      // Silent fail - non-critical operation
    }
  }, []);

  /**
   * Handle page visibility changes
   */
  const handleVisibilityChange = useCallback(() => {
    const isHidden = document.visibilityState === 'hidden';

    if (isHidden) {
      // Tab is hidden - activate keep-alive
      if (AUDIO_CONFIG.ACTIVATE_IMMEDIATELY) {
        createSilentAudio();
      }

      // Start interval to periodically signal activity
      if (!keepAliveIntervalRef.current && oscillatorRef.current) {
        keepAliveIntervalRef.current = setInterval(
          toggleFrequency,
          AUDIO_CONFIG.REFRESH_INTERVAL
        );
      }
    } else {
      // Tab is visible - stop keep-alive to save resources
      if (keepAliveIntervalRef.current) {
        clearInterval(keepAliveIntervalRef.current);
        keepAliveIntervalRef.current = null;
      }

      stopAudio();
    }
  }, [createSilentAudio, toggleFrequency, stopAudio]);

  // Setup effect for visibility change handling
  useEffect(() => {
    // Skip setup if browser doesn't support required APIs
    if (!isSupported) return;

    // Add visibility change listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Check initial state - if page starts in background, activate immediately
    if (
      document.visibilityState === 'hidden' &&
      AUDIO_CONFIG.ACTIVATE_IMMEDIATELY
    ) {
      createSilentAudio();

      // Setup the interval for toggling frequency
      if (oscillatorRef.current && !keepAliveIntervalRef.current) {
        keepAliveIntervalRef.current = setInterval(
          toggleFrequency,
          AUDIO_CONFIG.REFRESH_INTERVAL
        );
      }
    }

    // Clean up all resources when component unmounts
    return () => {
      // Remove the event listener
      document.removeEventListener('visibilitychange', handleVisibilityChange);

      // Clear any running intervals
      if (keepAliveIntervalRef.current) {
        clearInterval(keepAliveIntervalRef.current);
        keepAliveIntervalRef.current = null;
      }

      // Stop audio
      stopAudio();

      // Close audio context to free resources
      if (
        audioContextRef.current &&
        audioContextRef.current.state !== 'closed'
      ) {
        try {
          audioContextRef.current.close();
        } catch (err) {
          // Silent catch - closing is a best effort
        } finally {
          audioContextRef.current = null;
        }
      }
    };
  }, [
    isSupported,
    handleVisibilityChange,
    createSilentAudio,
    toggleFrequency,
    stopAudio,
  ]);

  return {
    isActive: isActiveRef.current,
    isSupported,
  };
};

export default useKeepAlive;
