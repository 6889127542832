import React, {
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDrag } from 'hooks';
import { WIDGETS_NAMES, REMOVE_WIDGET } from 'constants';
import ResizableWrap from '@control-front-end/common/components/ResizableWrap';
import Dashboard from '@control-front-end/common/components/Dashboard';
import LineDashboardWidget from './components/LineDashboardWidget/LineDashboardWidget';
import ScriptWidget from './components/ScriptWidget/ScriptWidget';

/**
 * The Widget component is a versatile UI element that serves as a container for various types of content.
 * It is resizable and draggable, offering interactive flexibility within the application's layout.
 * The component uses a PortalWrapper for flexible DOM placement, allowing it to be rendered
 * independently of its parent component's location in the DOM tree.
 *
 * Properties:
 * - id: A unique identifier for the widget.
 * - parentPosition: The initial position of the widget relative to its parent.
 * - name: Specifies the type of widget to be rendered, mapped from the __WIDGETS__ object.
 * - ...restProps: Additional props are spread into the rendered widget, allowing for customization.
 *
 * The widget's size and position can be dynamically adjusted by the user. It integrates with Redux
 * for state management, particularly for actions like removing a widget from the global state.
 * It is designed to be flexible and adaptable for various content types, making it an essential
 * component for dynamic and interactive user interfaces.
 */

const SIZE = {
  [WIDGETS_NAMES.scriptWidget]: {
    def: { width: 360, height: 600 },
    min: { width: 230, height: 300 },
  },
  [WIDGETS_NAMES.lineDashboardWidget]: {
    def: { width: Dashboard.DEF_WIDTH, height: Dashboard.DEF_HEIGHT },
    min: { width: Dashboard.DEF_WIDTH, height: Dashboard.DEF_HEIGHT },
  },
};

const WIDGET_MARGIN_OFFSET = 20;

const defaultResizeHandles = ['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne'];

const __WIDGETS__ = {
  LineDashboardWidget,
  ScriptWidget,
};

function Widget({
  id,
  parentPosition,
  name,
  isVisible: isVisibleProps,
  actorId,
  ...restProps
}) {
  const dispatch = useDispatch();
  const [size, setSize] = useState(
    SIZE[name]?.def || { width: 200, height: 200 }
  );
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [resizeHandles, setResizeHandles] = useState([...defaultResizeHandles]);
  const { actorId: actorViewId } = useSelector((state) => state.actorView);
  const [isVisible, setIsVisible] = useState(
    actorId === actorViewId && isVisibleProps
  );

  useEffect(() => {
    setIsVisible(actorId === actorViewId && isVisibleProps);
  }, [actorId, actorViewId, isVisibleProps]);

  const getBoundedPosition = (position, size) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const newLeft = Math.max(
      0,
      Math.min(position.left, screenWidth - size.width)
    );
    const newTop = Math.max(
      0,
      Math.min(position.top, screenHeight - size.height)
    );

    return { left: newLeft, top: newTop };
  };

  useLayoutEffect(() => {
    if (parentPosition) {
      let { left, top } = parentPosition;
      if (name === WIDGETS_NAMES.scriptWidget) {
        left = (window.innerWidth - size.width) / 2;
        top = (window.innerHeight - size.height) / 2;
      } else {
        left -= size.width;
      }
      setPosition(
        getBoundedPosition(
          {
            left,
            top,
          },
          size
        )
      );
    }
  }, [parentPosition]);

  const rootRef = useRef();
  const dragRef = useRef();

  const handleOnClose = () => {
    dispatch({ type: REMOVE_WIDGET, payload: { id } });
  };

  useEffect(() => {
    const atRightEdge = position.left + size.width >= window.innerWidth;
    const atBottomEdge = position.top + size.height >= window.innerHeight;
    const atLeftEdge = position.left <= 0;
    const atTopEdge = position.top <= 0;

    const newHandles = defaultResizeHandles.filter(
      (handle) =>
        (!atRightEdge || !handle.includes('e')) &&
        (!atBottomEdge || !handle.includes('s')) &&
        (!atLeftEdge || !handle.includes('w')) &&
        (!atTopEdge || !handle.includes('n'))
    );

    setResizeHandles(newHandles);
  }, [position, size]);

  const handleDragWidget = useCallback(
    (e) => {
      setPosition((prevPosition) => {
        const newPosition = {
          left: prevPosition.left + e.movementX,
          top: prevPosition.top + e.movementY,
        };
        return getBoundedPosition(newPosition, size);
      });
    },
    [size]
  );

  const handleResize = (e, { size: newSize, handle }) => {
    const deltaX = newSize.width - size.width;
    const deltaY = newSize.height - size.height;

    const mLeft = handle.includes('w') ? deltaX : 0;
    const mTop = handle.includes('n') ? deltaY : 0;
    const boundedPosition = getBoundedPosition(
      { left: position.left - mLeft, top: position.top - mTop },
      newSize
    );
    setSize(newSize);
    setPosition(boundedPosition);
  };

  useDrag(dragRef, handleDragWidget);

  const WidgetContent = __WIDGETS__[name]; // NOSONAR

  return (
    <ResizableWrap
      axis="both"
      {...size}
      minConstraints={Object.values(SIZE[name]?.min || {})}
      maxConstraints={[
        window.innerWidth - WIDGET_MARGIN_OFFSET,
        window.innerHeight - WIDGET_MARGIN_OFFSET,
      ]}
      resizeHandles={resizeHandles}
      handleResize={handleResize}
      style={{
        position: 'absolute',
        left: `${position.left}px`,
        top: `${position.top}px`,
        overflow:
          name === WIDGETS_NAMES.lineDashboardWidget ? 'hidden' : 'none',
        zIndex: '9999999',
        display:
          isVisible || name === WIDGETS_NAMES.lineDashboardWidget
            ? 'block'
            : 'none',
      }}
      className="ResizableWrap"
      // Add a data attribute using the script ref for precise CSS accessibility
      data-short-name={restProps.scriptRef ?? undefined}
    >
      <WidgetContent
        onClose={handleOnClose}
        dragRef={dragRef}
        rootRef={rootRef}
        id={id}
        actorId={actorId}
        {...restProps}
      />
    </ResizableWrap>
  );
}

Widget.propTypes = {
  id: PropTypes.string.isRequired,
  parentPosition: PropTypes.shape({
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
  }).isRequired,
  name: PropTypes.oneOf(Object.keys(__WIDGETS__)),
};

export default Widget;
