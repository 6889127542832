import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DEL_MODAL } from 'constants';
import PublishForm from './main/PublishForm';
import FormEditorIframe from './main/FormEditorIframe';
import EventCard from './main/EventCard';
import CreateWebhook from './main/CreateWebhook';
import InfoModal from './main/InfoModal';
import ImagePreview from './main/ImagePreview';
import FilePreview from './main/FilePreview';
import JsonPreview from './main/JsonPreview';
import CreateCurrency from './main/CreateCurrency';
import CreateTransfer from './main/CreateTransfer';
import CreateTransaction from './main/CreateTransaction';
import CreateCorrectiveTransaction from './main/CreateCorrectiveTransaction';
import CreateFormFieldTransaction from './main/CreateFormFieldTransaction';
import CreateTransactionsFilter from './main/CreateTransactionsFilter';
import TransactionsHistory from './main/TransactionsHistory';
import AccountDetails from './main/AccountDetails';
import ImportSettingsModal from './main/ImportSettingsModal';
import InviteMembersModal from './main/InviteMembersModal';
import ImportWarningModal from './main/ImportWarningModal';
import ImportStrategySelectorModal from './main/ImportStrategySelectorModal';
import AddConnector from './main/AddConnector';
import AddFormula from './main/AddFormula';
import ManageAccessRules from './main/ManageAccessRules';
import BulkManageAccessRules from './main/BulkManageAccessRules';
import BulkManageTags from './main/BulkManageTags';
import CreateActor from './main/CreateActor';
import CreateCompanyCardActor from './main/CreateActor/CreateCompanyCardActor';
import CompanyCardCreationWarning from './main/CompanyCardCreationWarning';
import ActorModalView from './main/ActorModalView';
import DiscardChanges from './main/DiscardChanges';
import CreateActorAccount from './main/CreateActorAccount';
import CreateScriptApp from './main/CreateScriptApp';
import CreateSmartForm from './main/CreateSmartForm';
import ConfirmWorkspaceExportModal from './main/ConfirmWorkspaceExportModal';
import SaveWithName from './main/SaveWithName';
import LayerDeleted from './main/LayerDeleted';
import LayerAccount from './main/LayerAccount';
import ManageGraphFolder from './main/ManageGraphFolder';
import TransferActorAccounts from './main/TransferActorAccounts';
import CreateActorsFilter from './main/CreateActorsFilter';
import CreateDashboard from './main/CreateDashboard';
import EditExportGraph from './main/EditExportGraph';
import UsersGroupDetails from './main/UsersGroupDetails';
import PersonalScriptModal from './main/PersonalScriptModal';
import PersonalScriptUsers from './main/PersonalScriptUsers';
import ChangesHistory from './main/ChangesHistory';
import TriggerHistory from './main/TriggerHistory';
import ActorTriggers from './main/ActorTriggers';
import MakeActorCopies from './main/MakeActorCopies';
import StaticGraph from './main/StaticGraph';
import VisualEffectsSettings from './main/VisualEffectsSettings';
import './Modal.scss';

const __MODALS__ = {
  PublishForm,
  FormEditorIframe,
  CreateScriptApp,
  CreateSmartForm,
  ConfirmWorkspaceExportModal,
  InfoModal,
  ImagePreview,
  FilePreview,
  JsonPreview,
  CreateWebhook,
  EventCard,
  CreateCurrency,
  CreateTransfer,
  CreateTransaction,
  CreateCorrectiveTransaction,
  CreateFormFieldTransaction,
  CreateTransactionsFilter,
  TransactionsHistory,
  AccountDetails,
  ImportSettingsModal,
  InviteMembersModal,
  ImportWarningModal,
  ImportStrategySelectorModal,
  AddConnector,
  AddFormula,
  CreateActor,
  CreateCompanyCardActor,
  CompanyCardCreationWarning,
  ActorModalView,
  ManageAccessRules,
  BulkManageAccessRules,
  BulkManageTags,
  CreateActorAccount,
  SaveWithName,
  LayerDeleted,
  LayerAccount,
  ManageGraphFolder,
  TransferActorAccounts,
  CreateActorsFilter,
  CreateDashboard,
  EditExportGraph,
  UsersGroupDetails,
  PersonalScriptModal,
  PersonalScriptUsers,
  ChangesHistory,
  MakeActorCopies,
  TriggerHistory,
  ActorTriggers,
  StaticGraph,
  VisualEffectsSettings,
};

function Modal({ name, data, visible, callback, closeCallback, closeConfirm }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const accounts = useSelector((state) => state.accounts);
  const formView = useSelector((state) => state.formView);
  const formList = useSelector((state) => state.formList);
  const webhooks = useSelector((state) => state.webhooks);
  const credentials = useSelector((state) => state.credentials);
  const [visibility, setVisibility] = useState(visible || false);
  const [showConfirmModal, toggleConfirmModal] = useState(false);
  const [hasChanges, setChanges] = useState(false);

  const Component = __MODALS__[name]; // NOSONAR

  const closeModal = () => {
    if (closeCallback) closeCallback();
    setVisibility(false);
    setTimeout(() => dispatch({ type: DEL_MODAL, payload: name }), 300);
  };

  const onClose = (event, isLocked = false) => {
    if (isLocked) return;
    if (closeConfirm && hasChanges) {
      toggleConfirmModal(true);
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    setTimeout(() => setVisibility(true), 10);
  }, []);

  useEffect(() => {
    if (visibility && !visible) onClose();
  }, [document.location.href]);

  const renderCloseConfirmationModal = () => {
    if (!closeConfirm) return null;
    return (
      <DiscardChanges
        visibility={showConfirmModal}
        onClose={() => toggleConfirmModal(false)}
        onSubmit={closeModal}
      />
    );
  };

  return (
    <>
      {renderCloseConfirmationModal()}
      <Component
        key={name}
        name={name}
        data={data}
        accounts={accounts}
        visibility={visibility}
        callback={callback}
        dispatch={dispatch}
        auth={auth}
        formList={formList}
        formView={formView}
        credentials={credentials}
        webhooks={webhooks}
        onClose={onClose}
        onContentChange={() => setChanges(true)}
      />
    </>
  );
}

Modal.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.object,
  visible: PropTypes.bool,
  callback: PropTypes.func,
  closeCallback: PropTypes.func,
  closeConfirm: PropTypes.bool,
};

export default Modal;
