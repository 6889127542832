import { takeEvery, select, call, put, delay } from 'redux-saga/effects';
import {
  ASYNC_TASK_STATUS,
  RequestStatus,
} from '@control-front-end/common/constants';
import {
  CHECK_SMART_FORM_INSTALL_INTERVAL,
  CREATE_SMART_FORM,
  GET_SMART_FORMS_TEMPLATES,
  SMART_FORM_INSTALL_TIMEOUT,
  SMART_FORM_INSTALL_TIMEOUT_ERROR,
} from '@control-front-end/common/constants/smartForms';
import api from '@control-front-end/common/sagas/api';
import { CREATE_ACTOR_LIST } from '../constants/graphActors';

export function* getSmartFormsTemplates({ callback }) {
  const accId = yield select((state) => state.accounts.active);
  const { data, result } = yield call(api, {
    method: 'get',
    url: `/smart_forms/list/${accId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  callback?.(data.data);
}

/**
 * Check the status of a smart form installation task
 * @param {Object} params - The function parameters
 * @param {string} params.taskId - The ID of the smart form installation task to check
 * @returns {Object} Object containing task and actor data if successful, empty object otherwise
 */
function* checkSmartFormInstall({ taskId }) {
  const { data, result } = yield call(api, {
    method: 'get',
    url: `/smart_forms/check_install/${taskId}`,
  });
  if (result !== RequestStatus.SUCCESS) return {};
  return { ...data.data };
}

/**
 * Poll the smart form installation status until completion or timeout
 * @param {Object} params - The function parameters
 * @param {string} params.taskId - The ID of the installation task to monitor
 * @param {Function} [params.callback] - Optional callback to execute on successful completion
 * @param {Function} [params.errorCallback] - Optional callback to execute on error
 * @returns {Object} The final task data
 */
function* waitForSmartFormInstallation({ taskId, callback, errorCallback }) {
  const startTime = Date.now();
  const taskFinalStatuses = [
    ASYNC_TASK_STATUS.completed,
    ASYNC_TASK_STATUS.failed,
    ASYNC_TASK_STATUS.canceled,
  ];

  // Poll until we get a final status or timeout
  let result = null;

  while (Date.now() - startTime < SMART_FORM_INSTALL_TIMEOUT) {
    const response = yield call(checkSmartFormInstall, { taskId });
    const { task } = response;

    if (taskFinalStatuses.includes(task?.status)) {
      result = response;
      break;
    }

    yield delay(CHECK_SMART_FORM_INSTALL_INTERVAL);
  }

  // Handle the result
  if (!result) {
    errorCallback?.(SMART_FORM_INSTALL_TIMEOUT_ERROR);
  } else if (result.task?.status === ASYNC_TASK_STATUS.completed) {
    callback?.(result);
  } else {
    errorCallback?.(result.task?.details);
  }

  return result;
}

function* createSmartForm({ payload, callback, errorCallback }) {
  const { fileUrl, smartFormRef, title, ref, description } = payload;
  const accId = yield select((state) => state.accounts.active);
  const { data, result } = yield call(api, {
    method: 'post',
    url: `/smart_forms/${accId}`,
    body: {
      fileUrl,
      smartFormRef,
      title,
      ref,
      description,
    },
  });
  if (result !== RequestStatus.SUCCESS) {
    errorCallback?.();
    return;
  }
  const { actor: newSmartForm } = yield call(waitForSmartFormInstallation, {
    taskId: data?.data?.task?.id,
    callback,
    errorCallback,
  });
  if (!newSmartForm) return;
  const { list } = yield select((state) => state.actorsList);
  const newList = list.slice();
  newList.unshift(newSmartForm);
  yield put({
    type: CREATE_ACTOR_LIST.SUCCESS,
    payload: { list: newList },
  });
}

function* smartForms() {
  yield takeEvery(GET_SMART_FORMS_TEMPLATES.REQUEST, getSmartFormsTemplates);
  yield takeEvery(CREATE_SMART_FORM.REQUEST, createSmartForm);
}

export default smartForms;
