import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import AppUtils from '@control-front-end/utils/utils';
import '@control-front-end/common/styles/core.scss';
import { CLEAR_NOTIFY } from 'constants';
import history from '@control-front-end/app/src/store/history';
import routes from '../../routes';
import { useFocusTracker } from '../../hooks/useFocusTracker';

function RouteWithLayout({
  layout: Layout,
  component: Component,
  goToUndefPage,
  whiteLabel,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout goToUndefPage={goToUndefPage}>
          <React.Suspense fallback={null}>
            <Component
              {...matchProps}
              whiteLabel={whiteLabel}
              history={history}
            />
          </React.Suspense>
        </Layout>
      )}
    />
  );
}

RouteWithLayout.propTypes = {
  layout: PropTypes.any.isRequired,
  component: PropTypes.any.isRequired,
  goToUndefPage: PropTypes.func.isRequired,
  whiteLabel: PropTypes.bool,
};

function RootLayout({ location }) {
  const accounts = useSelector((state) => state.accounts);
  useFocusTracker();

  // eslint-disable-line react/prop-types
  let accId;
  if (location.pathname.indexOf('error') === -1 && !AppUtils.isPublicScript()) {
    if (!accounts.active) return null;
    accId = accounts.active;
  }

  const goToUndefPage = (notify, dispatch) => {
    const items = notify.items || [];
    if (!items.length) return;
    const findAccessDenied = items.find((i) => i.statusCode === 403);
    const findNotFound = items.find((i) => i.statusCode === 404);
    if (findAccessDenied || findNotFound) {
      dispatch({ type: CLEAR_NOTIFY });
      const url = findAccessDenied ? '/access_denied' : '/not_found';
      history.push(url);
    }
  };

  return (
    <Switch>
      <Redirect exact from="/" to={`/events/${accId}/list`} />
      {routes.map((params) => (
        <RouteWithLayout
          key="route"
          {...params}
          history={history}
          goToUndefPage={goToUndefPage}
        />
      ))}
      <Redirect from="/*" to="/not_found" />
    </Switch>
  );
}

RootLayout.propTypes = {};

export default withRouter(RootLayout);
