import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ResizableBox } from 'react-resizable';
import './ResizableWrap.scss';

const ResizeHandle = React.forwardRef((props, ref) => {
  const { handleAxis, showHandles, ...restProps } = props;
  return (
    <div
      ref={ref}
      styleName={cn(`resizable-handle ${handleAxis}`, { showHandles })}
      style={{ pointerEvents: 'all' }}
      {...restProps}
    />
  );
});

/**
 * Компонент-обертка для ресайза элемента
 */
function ResizableWrap(props) {
  const {
    children,
    style,
    width,
    height,
    minConstraints,
    maxConstraints,
    showHandles = false,
    lockAspectRatio = false,
    axis = 'both',
    resizeHandles = ['s', 'w', 'e', 'n'],
    handleResizeStart,
    handleResize,
    handleResizeStop,
    draggableOpts,
  } = props;

  return (
    <ResizableBox
      axis={axis}
      width={width}
      height={height}
      minConstraints={minConstraints}
      maxConstraints={maxConstraints}
      resizeHandles={resizeHandles}
      onResizeStart={handleResizeStart}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      style={style}
      handle={<ResizeHandle showHandles={showHandles} />}
      lockAspectRatio={lockAspectRatio}
      draggableOpts={draggableOpts}
      {...props}
    >
      {children}
    </ResizableBox>
  );
}

ResizableWrap.propTypes = {
  children: PropTypes.element.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  axis: PropTypes.oneOf(['both', 'x', 'y']),
  resizeHandles: PropTypes.array,
  showHandles: PropTypes.bool,
  minConstraints: PropTypes.array,
  maxConstraints: PropTypes.array,
  style: PropTypes.object.isRequired,
  handleResizeStart: PropTypes.func,
  handleResize: PropTypes.func.isRequired,
  handleResizeStop: PropTypes.func,
  draggableOpts: PropTypes.object,
};

export default ResizableWrap;
