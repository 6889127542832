import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import AppUtils from '@control-front-end/utils/utils';
import Link from '../Link/Link';
import mes from '../../intl';
import './Image.scss';

/**
 * Image Constructor Component
 */
class Image extends PureComponent {
  // Localization function
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  render() {
    const { title, value } = this.props;
    return (
      <div styleName="image">
        <Link
          titleLabel={this.i(mes.imageTitle)}
          urlLabel={this.i(mes.imageUrl)}
          {...this.props}
        />
        {value ? (
          <div styleName="image__view">
            <img alt={title} src={value} />
          </div>
        ) : null}
      </div>
    );
  }
}

Image.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

Image.contextTypes = {
  intl: intlShape,
};

export default injectIntl(Image);
