import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useIntl from 'useIntl';
import useOutsideClick from 'useOutsideClick';
import AppUtils from '@control-front-end/utils/utils';
import { SEARCH_ACTORS } from '@control-front-end/common/constants/graphActors';
import { RECENT_ACTORS_GROUPED_BY_TPL, SEARCH } from 'constants';
import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import { Label, Icon, ProgressBar, Tooltip } from 'mw-style-react';
import cn from 'classnames';
import mes from './intl';
import './SmartChipPopup.scss';

const DEFAULT_SEARCH_FILTER = 'actors';
const VISIBLE_LABEL_LENGTH = 23;

/**
 * Popup for adding smart chip with preview
 * @returns {*}
 * @constructor
 */
function SmartChipPopup({ query, users: members = [], onSelect, onClose }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const systemForms = useSelector((state) => state.systemForms);
  const eventsFormId = systemForms.events.id;
  const graphsFormId = systemForms.graphs.id;
  const [filter, setFilter] = useState('');
  const [suggestions, setSuggestions] = useState({});
  const [search, setSearch] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const isDefaultFilter = !filter.length;
  const noSuggestions = Object.keys(suggestions).every(
    (key) => !suggestions[key].length
  );
  const users = members.map((u) => ({ ...u, id: u.userId, objType: 'user' }));

  const smartPopupRef = useRef();
  useOutsideClick({
    ref: smartPopupRef,
    callback: (e) => {
      const chipSearchFieldEl = document.querySelector('div.searchChip');
      if (chipSearchFieldEl?.contains(e.target)) return;
      onClose();
    },
  });

  const getActorType = ({ formId }) => {
    let type = 'actor';
    if (formId === eventsFormId) {
      type = 'event';
    } else if (formId === graphsFormId) {
      type = 'graph';
    }
    return type;
  };

  const getRecentSysTplActors = () => {
    dispatch({
      type: RECENT_ACTORS_GROUPED_BY_TPL.REQUEST,
      payload: {},
      callback: (data) => {
        const newSuggestions = {};
        Object.keys(data).forEach((key) => {
          newSuggestions[key] = data[key].map((i) => ({
            ...i,
            objType: getActorType(i),
          }));
        });
        setSuggestions(newSuggestions);
      },
    });
  };

  const getFilteredUsers = (searchString) => {
    const filteredUsers = users.filter((i) => {
      const nick = i.name.toLowerCase();
      const nickT = AppUtils.transliterate(nick);
      return (
        nick.indexOf(searchString) !== -1 || nickT.indexOf(searchString) !== -1
      );
    });
    return filteredUsers.map((i) => ({ ...i, objType: 'user' }));
  };

  const handleSearch = () => {
    if (isDefaultFilter) {
      dispatch({
        type: SEARCH.REQUEST,
        payload: { filters: DEFAULT_SEARCH_FILTER, query },
        callback: (data) => {
          const filteredUsers = getFilteredUsers(query);
          const result = data.map((i) => ({ ...i, objType: getActorType(i) }));
          const fullResult = [...filteredUsers, ...result];
          setSearch(fullResult);
          setLoading(false);
        },
      });
    } else {
      const filterParams = systemForms[filter]
        ? { formId: systemForms[filter].id }
        : {};
      dispatch({
        type: SEARCH_ACTORS.REQUEST,
        payload: { query, params: filterParams, localState: true },
        callback: (data) => {
          const result = data.map((i) => ({ ...i, objType: getActorType(i) }));
          setSearch(result);
          setLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    getRecentSysTplActors();
  }, []);

  useEffect(() => {
    if (query.length < 3) return;
    handleSearch();
    setLoading(true);
  }, [query, filter]);

  const handleSelectObject = (obj) => {
    const id = obj.id;
    const title = obj.title || obj.name || obj.nick || obj.value;
    const objType = obj.objType === 'graph' ? 'graphFolder' : obj.objType;
    onSelect({ id, objType, title });
  };

  const renderList = (type, list) => {
    return list.map((item) => {
      const itemLabel = item.title || item.name || item.nick || item.value;
      return (
        <Tooltip
          key={`tooltip_${item.id}_${item.objType}`}
          styleName={cn('smartChip__results__item__tooltip', {
            hidden: itemLabel.length < VISIBLE_LABEL_LENGTH,
          })}
          topLevel
          value={itemLabel}
        >
          <div
            key={`${item.id}_${item.objType}`}
            styleName="smartChip__results__item"
            onClick={() => handleSelectObject(item)}
          >
            <div styleName="smartChip__results__item__actor">
              <ActorAvatar
                size="small"
                type="compact"
                formType={item.formType}
                formTitle={item.formTitle}
                pictureUrl={item.pictureUrl || item.avatar}
                colorFilled={true}
                colors={item.allColors}
                status={item.status}
              />
            </div>
            <Label fontSize="small" value={itemLabel} />
          </div>
        </Tooltip>
      );
    });
  };

  const getIsVisible = (objType) => isDefaultFilter || filter === objType;

  const renderGroup = (type) => {
    const groupIsVisible = getIsVisible(type);
    const list = type === 'users' ? users : suggestions[type];
    if (!groupIsVisible || !list || !list.length) return null;
    const countToShow = isDefaultFilter ? 2 : list.length;
    return (
      <div styleName="smartChip__results__group">
        <Icon
          styleName={cn('smartChip__results__group__icon', {
            back: !isDefaultFilter,
          })}
          type="arrow"
          size="small"
          onClick={() => setFilter(isDefaultFilter ? type : '')}
        />
        <Label
          fontSize="small"
          fontWeight="semibold"
          value={t(mes[`${type}ChipResult`])}
        />
        {renderList(type, list.slice(0, countToShow))}
      </div>
    );
  };

  const renderSuggestions = () => {
    if (noSuggestions) {
      return (
        <div styleName="smartChip__results__empty">
          <Label value={t(mes.noSuggestions)} />
        </div>
      );
    }
    return (
      <>
        {renderGroup('users')}
        {renderGroup('events')}
        {renderGroup('actors')}
        {renderGroup('graphs')}
      </>
    );
  };

  const renderSearchResults = () => {
    if (isLoading) {
      return [
        <div key="loader" styleName="smartChip__results__loader">
          <ProgressBar type="circle" size="small" />
        </div>,
      ];
    }
    if (!search.length) {
      return [
        <div key="empty" styleName="smartChip__results__empty">
          <Label value={t(mes.noResultsFound)} />
        </div>,
      ];
    }
    const groupedResults = isDefaultFilter
      ? AppUtils.groupBy(search, 'objType')
      : { [filter]: search };
    return Object.keys(groupedResults).map((key) => {
      const groupedList = groupedResults[key];
      return (
        <div key={key} styleName="smartChip__results__group">
          <Icon
            styleName="smartChip__results__group__icon"
            type="arrow"
            onClick={() => setFilter(key === 'graph' ? 'graphs' : `${key}s`)}
            visibility={isDefaultFilter ? 'visible' : 'hidden'}
          />
          <Label
            fontSize="small"
            fontWeight="semibold"
            value={t(mes[`${key}sChipResult`])}
          />
          {renderList(key, groupedList)}
        </div>
      );
    });
  };

  return (
    <div ref={smartPopupRef} styleName="smartChip">
      <div styleName="smartChip__results">
        {query.length > 2 ? renderSearchResults() : renderSuggestions()}
      </div>
    </div>
  );
}

SmartChipPopup.propTypes = {
  query: PropTypes.string,
  users: PropTypes.array,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

export default SmartChipPopup;
