import PropTypes from 'prop-types';
import React from 'react';
import {
  Utils,
  Icon,
  Button as ButtonMW,
  Label as LabelMW,
  Checkbox,
  Tooltip,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import cn from 'classnames';
import { useIntl } from 'hooks';
import { getItemAvailableFields } from '@control-front-end/app/src/components/FormConstructor/SetDefaultValue';
import Edit from '../Edit/Edit';
import Select from '../Select/Select';
import MultiSelect from '../MultiSelect/MultiSelect';
import Label from '../Label/Label';
import Calendar from '../Calendar/Calendar';
import Check from '../Check/Check';
import Link from '../Link/Link';
import Image from '../Image/Image';
import Upload from '../Upload/Upload';
import Button from '../Button/Button';
import mes from '../../intl';
import './BaseItem.scss';
import ItemIdEditable from './ItemIdEditable';
import FieldTypeSelector from './FieldTypeSelector';

const _ITEMS_ = {
  Edit,
  Select,
  MultiSelect,
  Label,
  Calendar,
  Check,
  Link,
  Image,
  Upload,
  Button,
  Radio: Select,
};

/**
 * BaseItem Component
 */
function BaseItem(props) {
  const {
    activeSection,
    activeItem,
    activeRow,
    sectionIndex,
    itemIndex,
    class: className,
    row,
    rowItems,
    required,
    rowCount,
    sections,
    sectionsLen,
    itemsLen,
    focus: itemFocus,
    onChange,
    onActive,
    onCreateItem,
    onRemoveItem,
    onSortItem,
    onShowPanel,
    showPanel,
    errors = {},
  } = props;
  const t = useIntl();
  const isSystem = sectionIndex === -1;

  const isNotUniqueId = (id) => {
    return sections.some((section) =>
      section.content.some(
        (item) =>
          item.id === id &&
          (item.sectionIndex !== sectionIndex || item.itemIndex !== itemIndex)
      )
    );
  };

  // Make correct item value after class change
  const makeCorrectItemValue = ({ value, class: itemClass }) => {
    switch (itemClass) {
      case 'check':
        if (typeof value !== 'boolean') return false;
        break;
      case 'upload':
      case 'calendar':
        if (!value || typeof value !== 'object') return {};
        break;
      case 'select':
      case 'multiSelect':
        if (!Array.isArray(value)) return [];
        break;
      case 'link':
      case 'image':
        if (!AppUtils.isValidUrl(value)) return '';
        break;
      default:
        return '';
    }
    return value;
  };

  // Value actualization after form element class change
  const actualizeValue = (newItemClass) => {
    const correctValue = makeCorrectItemValue({
      value: props.value,
      class: newItemClass,
    });
    onChange({ id: 'value', value: correctValue });
  };

  // Create an object with errors
  const makeError = ({ error, id: fieldId, errorId }) => {
    if (error) {
      errors[fieldId] = t(mes[errorId]);
    } else {
      delete errors[fieldId];
    }
    return Object.keys(errors).length ? errors : undefined;
  };

  const handleChange = ({ fieldId, value, errors: newErrors }) => {
    onChange({
      id: fieldId,
      value,
      errors: newErrors,
      sectionIndex,
      itemIndex,
    });
  };

  const handleChangeId = ({ value, callback, errorOnly }) => {
    const error = isNotUniqueId(value);
    if (!error && errorOnly) return;
    if (callback) callback(error);
    const newErrors = makeError({ error, id: 'id', errorId: 'notUniqueId' });
    handleChange({ fieldId: 'id', value, errors: newErrors });
  };

  const handleMakeActive = () => {
    onActive({ sectionIndex, itemIndex });
  };

  const checkIsActive = ({ sectionIndex: sectionIdx, itemIndex: itemIdx }) => {
    return activeSection === sectionIdx && activeItem === itemIdx;
  };

  const getRowItemIndex = () => {
    if (!row) return { minIndex: itemIndex, maxIndex: itemIndex };
    const sortRowItems = Utils.sort(rowItems.slice(), 'itemIndex');
    const minIndex = sortRowItems[0].itemIndex;
    const maxIndex = sortRowItems[sortRowItems.length - 1].itemIndex;
    return { minIndex, maxIndex };
  };

  // Render of element itself
  const renderItem = (item, focus = false, isActive = false) => {
    const ItemName = Utils.toUpperLatter(className);
    const Item = _ITEMS_[ItemName];
    if (!Item) return null;
    return (
      <Item
        key={`${sectionIndex}_${itemIndex}`}
        className={className}
        onChange={handleChange}
        title={item.title}
        placeholder={item.placeholder}
        options={item.options}
        visibility={item.visibility}
        value={item.value}
        errors={item.errors}
        id={item.id}
        isActive={isActive}
        autoFocus={focus}
        extra={item.extra}
      />
    );
  };

  // Rendering inactive element
  const renderUnactiveItem = () => {
    const ItemName = Utils.toUpperLatter(className);
    const Item = _ITEMS_[ItemName];
    if (!Item) return null;
    // Don't render inactive element in a row
    if (row && row === activeRow) return null;
    return (
      <div styleName="item__unactive" onClick={handleMakeActive}>
        {renderItem(props)}
      </div>
    );
  };

  // Render tabs for row
  const renderRowTabs = () => {
    if (!row || row !== activeRow) return null;
    const tabs = rowItems.map((item, index) => {
      const { title } = item;
      const isActive = checkIsActive(item);
      return (
        <div
          key={`tab_${sectionIndex}_${index}`}
          styleName={cn('item__active__tabs__item', { active: isActive })}
          onClick={() => onActive(item)}
        >
          {title || `${t(mes.item)} ${index + 1}`}
        </div>
      );
    });
    return <div styleName="item__active__tabs">{tabs}</div>;
  };

  // Render element sorting buttons
  const renderItemSortBtns = () => {
    const actionsDenied = sectionsLen === 1 && itemsLen === 1;
    const { minIndex, maxIndex } = getRowItemIndex();
    const moveUpDenied = actionsDenied || minIndex === 0;
    const moveDownDenied = actionsDenied || maxIndex === itemsLen - 1;
    return (
      <div styleName="item__active__header__sort">
        <Tooltip topLevel value={t(mes.moveItemUp)}>
          <div
            onClick={() => {
              if (moveUpDenied) return;
              onSortItem({ sectionIndex, itemIndex, position: 'up' });
            }}
          >
            <Icon
              type="arrow"
              visibility={moveUpDenied ? 'disabled' : 'visible'}
            />
          </div>
        </Tooltip>
        <Tooltip topLevel value={t(mes.moveItemDown)}>
          <div
            onClick={() => {
              if (moveDownDenied) return;
              onSortItem({ sectionIndex, itemIndex, position: 'down' });
            }}
          >
            <Icon
              type="arrow"
              visibility={moveDownDenied ? 'disabled' : 'visible'}
            />
          </div>
        </Tooltip>
      </div>
    );
  };

  // Rendering active element
  const renderActiveItem = () => {
    const isActiveRow = row && row === activeRow;
    const requiredIsVar =
      required && !['true', 'false'].includes(required.toString());
    const harRequiredField =
      getItemAvailableFields(props).includes('required') &&
      props.visibility !== 'disabled';
    return (
      <div
        styleName={cn('item__active', { row: isActiveRow })}
        className="active"
      >
        {renderRowTabs()}
        <div styleName="item__active__header">
          <div styleName="item__active__header__left">
            {renderItemSortBtns()}
            <div styleName="item__active__header__type">
              <FieldTypeSelector
                type={className}
                onChange={({ value }) => {
                  handleChange({ fieldId: 'class', value });
                  actualizeValue(value);
                }}
                styleName="item__active__header__classSelect"
              />
            </div>
            {harRequiredField ? (
              <div
                styleName={cn('item__active__header__required', {
                  disabled: requiredIsVar,
                })}
              >
                <Checkbox
                  value={AppUtils.toBool(required)}
                  visibility={requiredIsVar ? 'disabled' : 'visible'}
                  onChange={({ value }) =>
                    handleChange({
                      fieldId: 'required',
                      value: value.toString(),
                    })
                  }
                >
                  <LabelMW value={t(mes.required)} />
                </Checkbox>
              </div>
            ) : null}
          </div>
          <div styleName="item__active__header__right">
            {showPanel ? null : (
              <ItemIdEditable
                id={props.id}
                error={!!errors.id}
                onChange={handleChangeId}
              />
            )}
            <div onClick={onShowPanel}>
              <Icon type="settings" />
            </div>
            <div onClick={() => onRemoveItem({ sectionIndex, itemIndex })}>
              <Icon type="trash" visibility="visible" />
            </div>
          </div>
        </div>
        <div styleName="item__active__main">
          {renderItem(props, itemFocus, true)}
        </div>
        <div styleName="item__active__footer">
          <Tooltip topLevel value={t(mes.addItemDown)}>
            <ButtonMW
              icon="add_item_below"
              type="quaternary"
              size="smallplus"
              rounded="true"
              onClick={() =>
                onCreateItem({ sectionIndex, itemIndex, isRow: false })
              }
            />
          </Tooltip>
          <Tooltip topLevel value={t(mes.addItemRow)}>
            <ButtonMW
              icon="add_item_row"
              type="quaternary"
              size="smallplus"
              visibility={rowCount < 3 ? 'visible' : 'hidden'}
              rounded="true"
              onClick={() =>
                onCreateItem({ sectionIndex, itemIndex, isRow: true })
              }
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  const isBaseItemActive = checkIsActive({ sectionIndex, itemIndex });

  return (
    <div styleName={cn('item', { system: isSystem })}>
      {isBaseItemActive ? renderActiveItem() : renderUnactiveItem()}
    </div>
  );
}

BaseItem.propTypes = {
  activeSection: PropTypes.number.isRequired,
  activeItem: PropTypes.number.isRequired,
  activeRow: PropTypes.string,
  sectionIndex: PropTypes.number.isRequired,
  itemIndex: PropTypes.number.isRequired,
  class: PropTypes.string.isRequired,
  row: PropTypes.string,
  rowItems: PropTypes.array,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rowCount: PropTypes.number,
  sections: PropTypes.array,
  sectionsLen: PropTypes.number,
  itemsLen: PropTypes.number,
  focus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onActive: PropTypes.func.isRequired,
  onCreateItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onSortItem: PropTypes.func.isRequired,
  onShowPanel: PropTypes.func.isRequired,
  showPanel: PropTypes.bool,
};

export default BaseItem;
