import { useState, useEffect, useRef } from 'react';

/**
 * Check if the browser supports Wake Lock API
 * @returns {boolean} True if supported, false otherwise
 */
const isWakeLockSupported = () => {
  return 'wakeLock' in navigator;
};

/**
 * Custom hook that manages the Wake Lock API to prevent screen dimming during calls
 * @returns {Object} Object containing the current wake lock status and support information
 * @property {WakeLockSentinel|null} wakeLockRef - The active wake lock object or null if inactive
 * @property {boolean} isSupported - Whether the browser supports Wake Lock API
 * @property {string|null} error - Error message if wake lock request failed
 */
const useWakeLock = () => {
  const wakeLockRef = useRef(null);
  const [isSupported, setIsSupported] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Request a wake lock to prevent screen dimming
   */
  const requestWakeLock = async () => {
    try {
      const lock = await navigator.wakeLock.request('screen');
      wakeLockRef.current = lock;
      setError(null);

      // Clean up when wake lock is released
      lock.addEventListener('release', () => {
        wakeLockRef.current = null;
      });
    } catch (err) {
      setError(`Wake Lock error: ${err.message}`);
    }
  };

  /**
   * Handle visibility change events
   */
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && !wakeLockRef.current) {
      requestWakeLock();
    }
  };

  // Check for Wake Lock API support
  useEffect(() => {
    const supported = isWakeLockSupported();
    setIsSupported(supported);

    if (!supported) {
      setError('Wake Lock API is not supported in this browser');
    }
  }, []);

  // Set up wake lock and visibility change listener
  useEffect(() => {
    if (!isSupported) return;

    // Initial wake lock request
    requestWakeLock();

    // Listen for visibility changes to re-request wake lock if needed
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);

      // Release wake lock when component unmounts
      if (wakeLockRef.current) {
        wakeLockRef.current.release().catch(() => {
          // Silent catch - not critical if release fails on unmount
        });
      }
    };
  }, [isSupported]);

  return {
    isSupported,
    error,
  };
};

export default useWakeLock;
