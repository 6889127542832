import { useState, useCallback, useRef, useEffect } from 'react';
import { EXP_NODE } from '@control-front-end/common/constants/graphActors';

const DEFAULT_RESIZER_MARGIN = { left: 0, top: 0 };

/**
 * Hook for node resize (Chart, Picture or ExpandedActor)
 */
const useGraphNodeResize = ({ size: initialSize }) => {
  const [size, setSize] = useState(initialSize || EXP_NODE.size.default);
  const sizeRef = useRef();
  const [margin, setMargin] = useState(DEFAULT_RESIZER_MARGIN);

  useEffect(() => {
    sizeRef.current = { ...size };
  }, [size?.width, size?.height]);

  const resetMargin = useCallback(() => setMargin(DEFAULT_RESIZER_MARGIN), []);

  const setNodeSize = useCallback((newSize) => setSize(newSize), []);

  const onResize = useCallback(
    ({ size: newSize, handle }) => {
      const deltaX = newSize.width - size.width;
      const deltaY = newSize.height - size.height;
      const mLeft = handle.includes('w') ? deltaX : 0;
      const mRight = handle.includes('e') ? deltaX : 0;
      const mTop = handle.includes('n') ? deltaY : 0;
      const mBottom = handle.includes('s') ? deltaY : 0;

      setMargin({
        left: margin.left + mLeft - mRight,
        top: margin.top + mTop - mBottom,
      });
      setSize(newSize);
    },
    [size, margin]
  );

  return {
    size,
    margin,
    setNodeSize,
    resetMargin,
    onResize,
    setMargin,
    sizeRef,
  };
};

export default useGraphNodeResize;
