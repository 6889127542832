import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  TextField,
  Calendar,
  Divider,
  Button,
  DateUtils,
} from 'mw-style-react';
import { DATE_FORMAT_4 } from 'constants';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import { CopyIdChip } from 'components';
import mes from './intl';
import './CorrectiveTransaction.scss';

function CorrectiveTransaction(props) {
  const {
    accountId,
    parentId,
    total,
    currencyName,
    currencyParams,
    incomeType,
    onClose,
    onSubmit,
  } = props;
  const t = useIntl();
  const [initialDate, setInitialDate] = useState(null);
  const [date, setDate] = useState(null);
  const [correctionAmount, setCorrectionAmount] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    const currentTime = DateUtils.unixtime();
    setInitialDate(currentTime);
    setDate(currentTime);
  }, []);

  /**
   * Create corrective transaction
   */
  const handleCreateTransaction = () => {
    const transaction = {
      parentId,
      amount: parseFloat(correctionAmount),
      createdAt: initialDate * 1000,
      originalDate: date === initialDate ? undefined : date * 1000,
      comment,
    };
    onSubmit({ id: accountId, incomeType, transaction });
  };

  /**
   * Get corrected total
   */
  const getCorrectedTotal = () => {
    if (!correctionAmount) return total;
    const amount = parseFloat(correctionAmount);
    return incomeType === 'debit' ? total - amount : total + amount;
  };

  return (
    <div styleName="corrective">
      <div styleName="corrective__header">
        <Label fontWeight="semibold" value="Create corrective transaction" />
        <Button
          size="smallplus"
          fontWeight="normal"
          type="text"
          label={t(mes.cancel)}
          onClick={onClose}
        />
      </div>
      <div styleName="corrective__acc">
        <Label value={t(mes.accountIdInfo)} />
        <CopyIdChip type="default" id={accountId} />
      </div>
      <Divider />
      <div styleName="corrective__data">
        <TextField
          size="large"
          bordered={true}
          label={t(mes.correctiveDate)}
          value={date ? DateUtils.toDate(date, DATE_FORMAT_4) : ''}
          calendar={() => (
            <Calendar
              size="small"
              time={true}
              value={{ startDate: date }}
              onChange={({ value }) => setDate(value.startDate)}
            />
          )}
        />
        <TextField
          size="large"
          label={t(mes.current)}
          bordered={true}
          value={`${AppUtils.formattedAmount(
            total,
            currencyParams
          )} ${currencyName}`}
          visibility="disabled"
        />
        {incomeType === 'credit' ? '+' : '-'}
        <TextField
          size="large"
          type="float"
          label={t(mes.correctiveAmount)}
          bordered={true}
          autoFocus={true}
          value={correctionAmount || ''}
          onChange={({ value }) => setCorrectionAmount(value)}
        />
        =
        <TextField
          size="large"
          label={t(mes.totalAfterCorrective)}
          bordered={true}
          value={AppUtils.formattedAmount(getCorrectedTotal(), currencyParams)}
          visibility="disabled"
        />
      </div>
      <TextField
        styleName="corrective__comment"
        size="large"
        label={t(mes.comment)}
        bordered={true}
        value={comment || ''}
        onChange={({ value }) => setComment(value)}
      />
      <Button
        styleName="corrective__btn"
        size="smallplus"
        fontWeight="normal"
        label={t(mes.create)}
        onClick={handleCreateTransaction}
        visibility={correctionAmount ? 'visible' : 'disabled'}
      />
    </div>
  );
}

CorrectiveTransaction.propTypes = {
  accountId: PropTypes.string,
  parentId: PropTypes.number,
  total: PropTypes.number,
  currencyName: PropTypes.string,
  currencyParams: PropTypes.object,
  incomeType: PropTypes.oneOf(['debit', 'credit']),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CorrectiveTransaction;
