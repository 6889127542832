import { useCallback, useRef } from 'react';

/**
 * Hook for long press on mobile devices
 * @param callback
 * @param isPreventDefault
 * @param delay
 */
const useLongPress = ({
  callback = () => {},
  isPreventDefault = true,
  delay = 300,
}) => {
  const timeout = useRef();
  const target = useRef();

  const isTouchEvent = (ev) => 'touches' in ev;

  const preventDefault = (ev) => {
    if (!isTouchEvent(ev)) return;

    if (ev.touches.length < 2 && ev.preventDefault) {
      ev.preventDefault();
    }
  };

  const start = useCallback(
    (event) => {
      // ignore long press for links
      if (event.target.tagName === 'A') {
        event.stopPropagation();
        return;
      }
      if (isPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => callback(event), delay);
    },
    [callback, delay, isPreventDefault]
  );

  const clear = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (isPreventDefault && target.current) {
      target.current.removeEventListener('touchend', preventDefault);
    }
  }, [isPreventDefault]);

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: clear,
    onMouseLeave: clear,
    onTouchEnd: clear,
  };
};

export default useLongPress;
