import { createReducer } from '@reduxjs/toolkit';
import {
  APP_INIT,
  GET_USER_SETTINGS,
  UPDATE_USER_SETTINGS,
  EVENTS_VIEW_MODES,
  CALENDAR_VIEWS,
  APP_SETTINGS,
} from 'constants';
import { EDGE_CURVE_STYLE } from '../constants/graphActors';

// ------------------------------------
// Reducer
// TODO: Describe all possible settings
// ------------------------------------
const initState = {
  /**
   * Holds user settings for push notifications and sounds
   * Value type: Object
   * @example
   * {
   *     "NEW_ACTOR": {
   *         "DEFAULT": {
   *             "SOUND": false,
   *             "PUSH": true
   *         },
   *         "IMPORTANT": {
   *             "SOUND": false,
   *             "PUSH": true
   *         }
   *     },
   *     "NEW_REACTION": {
   *         "DEFAULT": {
   *             "SOUND": false,
   *             "PUSH": true
   *         },
   *         "IMPORTANT": {
   *             "SOUND": true,
   *             "PUSH": true
   *         }
   *     }
   * }
   */
  [APP_SETTINGS.notificationsSettings]: null,

  /**
   * Flag to enable/disable mode with active event view in panel
   * Value type: Boolean
   */
  [APP_SETTINGS.plainMode]: false,

  [APP_SETTINGS.eventsViewMode]: EVENTS_VIEW_MODES.list,
  [APP_SETTINGS.calendarView]: CALENDAR_VIEWS.timeGridDay,

  /**
   * Holds order values for streams or filters tabs
   * Value type: Object
   * @example
   * {
   *     "4127245": {
   *         "all": 0,
   *         "sign": 1,
   *         "execute": 2,
   *         "starred": 3,
   *         "c1c197ca-18eb-4b24-b4f9-3045a0420e1a": 4,
   *         "9bacee6d-f06e-4d95-b5e4-1d7426cd440d": 5,
   *     },
   *     "4127257": {
   *         "all": 0,
   *         "3eaff357-d142-454f-995e-461092c3b93e": 1,
   *         "dd8c9c00-56e4-4f30-8b1a-3c6ef7f96558": 2,
   *     }
   * }
   */
  [APP_SETTINGS.tabsOrder]: {},

  // The order of custom bar icons
  [APP_SETTINGS.scriptsOrder]: {},

  // The order of custom layers on the panel
  [APP_SETTINGS.customLayersOrder]: {},

  // Display nodes numbers (just order from 1 to Infinite) on graph or not
  [APP_SETTINGS.actorNumbers]: false,

  // Graph editing sound on/off
  [APP_SETTINGS.sound]: false,

  // Display nodes cell coordinates on graph or not (for examle (A, -1))
  [APP_SETTINGS.showNodesCoordinates]: true,

  // toggle active cell blinking
  [APP_SETTINGS.blinkActiveCell]: true,

  // Display the coordinate grid or not
  [APP_SETTINGS.coordinateGrid]: true,

  // Display cross-cursor or not
  [APP_SETTINGS.crossCursor]: false,

  // trace max count
  [APP_SETTINGS.traceMaxCount]: 1,

  // Graph edges style
  [APP_SETTINGS.edgeCurveStyle]: EDGE_CURVE_STYLE.curved,

  // Meeting settings
  [APP_SETTINGS.meeting]: {
    virtualBgImages: [],
    activeVirtualEffect: null,
    activeBgImageId: null,
  },
};

export default createReducer(initState, {
  [GET_USER_SETTINGS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_USER_SETTINGS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [APP_INIT.SUCCESS](state) {
    return { ...state, loaded: true };
  },
});
