import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useIntl } from 'hooks';
import {
  MenuItem,
  Select,
  Icon,
  Badge,
  Label,
  Divider,
  Space,
} from 'mw-style-react';
import { SPACE_SIZE } from 'mw-style-react/build/constants';
import Toggle from '@control-front-end/common/components/Toggle';
import '../../CreateActorsFilter.scss';
import mes from './intl';

function renderFieldsSelect(props) {
  const {
    isSingleSelect,
    label,
    placeholder,
    disabled,
    fieldsSelectList,
    handleSelect,
    translate: t,
    selectedValue,
    getSelectedValueTitle,
    popoverOnTop,
    unspaced,
  } = props;

  return (
    <div styleName="filter__section dynamic">
      <Select
        id="fields"
        styleName={cn('filter__select', { isSingleSelect })}
        type="autocomplete"
        size={isSingleSelect ? 'large' : 'medium'}
        bordered
        unspaced={unspaced}
        popoverOptions={unspaced ? { padding: 4 } : {}}
        placeholder={
          placeholder ||
          (isSingleSelect ? t(mes.selectAccount) : t(mes.selectDisplayedFields))
        }
        onChange={handleSelect}
        label={
          isSingleSelect && label ? (
            <Space size={SPACE_SIZE.xxsmall}>
              <Label fontWeight="semibold" value={label} />
            </Space>
          ) : (
            ''
          )
        }
        value={isSingleSelect && selectedValue ? getSelectedValueTitle() : null}
        visibility={disabled ? 'disabled' : 'visible'}
        popoverOnTop={popoverOnTop}
      >
        {fieldsSelectList.map((i, index) => (
          <MenuItem
            key={i.key}
            styleName="filter__select__item"
            value={i.key}
            label={i.title}
          >
            <div styleName="filter__select__item__form">
              <Badge
                size="small"
                bgColor={i.formColor || '#ffffff'}
                borderColor={i.formColor || '#ACB3BE'}
              />
              <Label fontSize="small" value={i.formTitle} />
              {index > 0 ? (
                <Divider styleName="filter__select__item__divider" />
              ) : null}
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

/**
 * Selection of displayed fields in Actors Bag
 */
function VisibleColumns(props) {
  const {
    visibleColumns = [],
    formFields = [],
    onChange,
    selectedValue,
    isSingleSelect = false,
    multipleForms = false,
  } = props;
  const t = useIntl();
  const fieldsSelectList = isSingleSelect
    ? formFields
    : formFields.filter((i) => !visibleColumns.includes(i.key));

  const handleSelect = (data) => {
    const { value } = data;
    if (isSingleSelect) {
      onChange(value);
      return;
    }
    const copyList = visibleColumns.slice();
    copyList.push(value);
    onChange(copyList);
  };

  const handleRemoveField = (key) => {
    const list = visibleColumns.filter((i) => i !== key);
    onChange(list);
  };

  const getSelectedValueTitle = () => {
    if (!selectedValue) return null;
    const selectedField = formFields.find((i) => i.key === selectedValue);
    if (!selectedField) return null;
    const { title, formTitle } = selectedField;
    return `${title}${multipleForms ? ' - '.concat(formTitle) : ''}`;
  };

  const renderSelectedFields = () =>
    visibleColumns
      .map((key) => {
        const field = formFields.find((i) => i.key === key);
        if (!field) return null;
        return (
          <div styleName="table__row" key={key}>
            <div styleName="table__cell title">
              <span>{field.title}</span>
            </div>
            <div styleName="table__cell">
              <div styleName="table__cell__form">
                <Badge
                  size="small"
                  bgColor={field.formColor || '#1973E8'}
                  borderColor={field.formColor || '#1973E8'}
                />
                <span>{field.formTitle}</span>
              </div>
            </div>
            <div styleName="table__cell">
              <div
                styleName="table__cell__close"
                onClick={() => handleRemoveField(field.key)}
              >
                <Icon type="close" size="small" />
              </div>
            </div>
          </div>
        );
      })
      .filter((i) => !!i);

  if (!isSingleSelect && !formFields.length) return null;
  return isSingleSelect ? (
    renderFieldsSelect({
      ...props,
      fieldsSelectList,
      translate: t,
      selectedValue,
      getSelectedValueTitle,
      handleSelect,
    })
  ) : (
    <Toggle isOpen title={t(mes.displayedColumns)}>
      {renderFieldsSelect({
        ...props,
        fieldsSelectList,
        translate: t,
        selectedValue,
        getSelectedValueTitle,
        handleSelect,
      })}
      {visibleColumns.length ? (
        <div styleName="table">{renderSelectedFields()}</div>
      ) : null}
    </Toggle>
  );
}

VisibleColumns.propTypes = {
  visibleColumns: PropTypes.array,
  formFields: PropTypes.array,
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
  isSingleSelect: PropTypes.bool,
  multipleForms: PropTypes.bool,
};

export default VisibleColumns;
