import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Menu, Card, Icon, Popover, Tooltip } from 'mw-style-react';
import Dashboard from '@control-front-end/common/components/Dashboard';
import AppUtils from '@control-front-end/utils/utils';
import { useIntl, useNotifications } from 'hooks';
import { DASHBOARD_CHART_TYPES } from '@control-front-end/common/constants/graphActors';
import {
  INCOME_TYPE,
  COUNTER_TYPE,
} from '@control-front-end/common/constants/actorAccounts';
import ContextMenuItem from '@control-front-end/app/src/routes/ActorsGraph/components/ContextMenuItem';
import mes from 'globalIntl';

import './LineDashboardWidget.scss';

function LineDashboardWidget({ actorId, account, rootRef, dragRef, onClose }) {
  const t = useIntl();
  const dashboardContainerRef = useRef();
  const [menu, toggleMenu] = useState(false);
  const { showNotification } = useNotifications();

  const closeMenu = async () => toggleMenu(false);

  const staticSource = useMemo(
    () => ({
      accounts: [
        {
          actorId,
          nameId: account?.nameId,
          currencyId: account?.currencyId,
          incomeType: INCOME_TYPE.total,
          color: AppUtils.getRandomColorByPalette(),
        },
      ],
      counterType: COUNTER_TYPE.amount,
      chartType: DASHBOARD_CHART_TYPES.LINE,
      range: 'today',
      chartTitle: `${account?.accountName?.toUpperCase() || ''}, ${
        account?.currencyName || ''
      }`,
    }),
    [actorId, account]
  );

  const renderMenu = () => {
    return (
      <Menu
        size="small"
        onClick={() => closeMenu()}
        onClose={() => setTimeout(closeMenu, 10)}
      >
        <ContextMenuItem
          icon="download"
          label={t(mes.exportToPng)}
          handleClick={async () => {
            await closeMenu();
            AppUtils.exportToPng({
              rootElement: dashboardContainerRef.current,
              title: account?.accountName,
              onSuccess: () =>
                showNotification('success', t(mes.exportSuccessText)),
            });
          }}
        />
        <ContextMenuItem
          icon="copy"
          label={t(mes.copyImage)}
          handleClick={async () => {
            await closeMenu();
            AppUtils.copyToClipboardPng({
              rootElement: dashboardContainerRef.current,
              onSuccess: () => showNotification('success', t(mes.copiedText)),
            });
          }}
        />
      </Menu>
    );
  };

  return (
    <Card forwardRef={rootRef} styleName="chart">
      <Dashboard
        actorId={actorId}
        staticSource={staticSource}
        headerRef={dragRef}
        draggable
        dashboardContainerRef={dashboardContainerRef}
        actions={
          <>
            <Popover
              anchors={{
                binding: Popover.ANCHOR.right_top,
                content: Popover.ANCHOR.right_top,
              }}
              content={menu ? renderMenu() : null}
            >
              <Tooltip value={t(mes.more)}>
                <div data-draggable="false" onClick={() => toggleMenu(!menu)}>
                  <Icon type="more" />
                </div>
              </Tooltip>
            </Popover>
            <Tooltip value={t(mes.close)}>
              <div onClick={onClose}>
                <Icon size="small" type="close" />
              </div>
            </Tooltip>
          </>
        }
      />
    </Card>
  );
}

LineDashboardWidget.propTypes = {
  actorId: PropTypes.string,
  account: PropTypes.shape({
    nameId: PropTypes.string,
    currencyId: PropTypes.string,
    accountName: PropTypes.string,
    currencyName: PropTypes.string,
  }),
  rootRef: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.instanceOf(Element),
      PropTypes.oneOf([null]),
    ]),
  }),
  dragRef: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.instanceOf(Element),
      PropTypes.oneOf([null]),
    ]),
  }),
  onClose: PropTypes.func.isRequired,
};

export default LineDashboardWidget;
