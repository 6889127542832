import { UPDATE_ACTOR } from '@control-front-end/common/constants/graphActors';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'hooks';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

/**
 * Custom hook for managing notification settings for users
 *
 * @param {Object} options
 * @param {boolean} options.canManageNotification - Whether the current user can manage access
 * @returns {Object} Notification management methods and utilities
 */
const useNotificationManager = (canManageNotification) => {
  const dispatch = useDispatch();
  const t = useIntl();
  const actor = useSelector((state) => state.actorView) || {};

  /**
   * Toggle notification settings for a user
   * @param {Object} user - Object containing userId
   */
  const onNotificationClick = (user) => {
    if (!canManageNotification) return;

    const disableCallbackSoundUsers =
      actor.data?.disableCallbackSoundUsers || [];

    const userId = user.userId ?? user.id;

    const updatedDisableCallbackSoundUsers = disableCallbackSoundUsers.includes(
      userId
    )
      ? disableCallbackSoundUsers.filter((id) => id !== userId)
      : [...disableCallbackSoundUsers, userId];

    dispatch({
      type: UPDATE_ACTOR.REQUEST,
      payload: {
        ...actor,
        formData: {
          disableCallbackSoundUsers: updatedDisableCallbackSoundUsers,
        },
      },
    });
  };

  /**
   * Check if notifications are disabled for a user
   * @param {Object} user - Object containing userId
   * @returns {boolean} Whether notifications are disabled
   */
  const checkNotificationDisabled = (user) => {
    const userId = user.userId ?? user.id;
    const disableCallbackSoundUsers =
      actor.data?.disableCallbackSoundUsers || [];
    return disableCallbackSoundUsers.includes(userId);
  };

  /**
   * Get tooltip text based on notification status
   * @param {Object} user - Object containing userId
   * @returns {Array} Array containing [isDisabled, tooltipText]
   */
  const getNotificationStatus = (user) => {
    const isNotificationDisabled = checkNotificationDisabled(user);
    const ttText = canManageNotification
      ? t(
          isNotificationDisabled
            ? globalIntl.editSoundOn
            : globalIntl.editSoundOff
        )
      : t(
          isNotificationDisabled
            ? globalIntl.soundDisabled
            : globalIntl.soundEnabled
        );
    return [isNotificationDisabled, ttText];
  };

  return {
    onNotificationClick,
    getNotificationStatus,
  };
};

export default useNotificationManager;
