import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppUtils from '@control-front-end/utils/utils';

/**
 * Areas on the layer
 */
function Areas(props) {
  const { graph, layer, layersAreas } = props;
  const canvas = layer.getCanvas();
  const context = canvas.getContext('2d');

  /**
   * Render areas on the layer
   */
  const renderAreas = (ctx) => {
    layer.resetTransform(ctx);
    layer.clear(ctx);
    layer.setTransform(ctx);
    ctx.save();
    for (const area of layersAreas.list) {
      ctx.beginPath();
      for (const position of area.polygon) {
        ctx.lineTo(position[0], position[1]);
      }
      ctx.lineWidth = 1;
      ctx.strokeStyle = AppUtils.hexToRgba(area.color, 0.2);
      ctx.stroke();
      ctx.closePath();
      ctx.fillStyle = AppUtils.hexToRgba(area.color, 0.2);
      ctx.fill();
      ctx.save();
    }
  };

  useEffect(() => {
    graph.on('render cyCanvas.resize', () => {
      renderAreas(context);
    });
    renderAreas(context);
  }, []);

  return <div />;
}

Areas.propTypes = {
  graph: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  layersAreas: PropTypes.object,
};

export default Areas;
