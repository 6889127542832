import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import {
  Label,
  Icon,
  Space,
  Stack,
  Select,
  MenuItem,
  TextField,
  useObjectState,
  Tooltip,
  cr,
} from 'mw-style-react';
import { useIntl } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import { GET_FORM } from '@control-front-end/common/constants/forms';
// eslint-disable-next-line max-len
import FieldTypeSelector from '@control-front-end/app/src/components/FormConstructor/components/FormEdit/Items/BaseItem/FieldTypeSelector';
import { setDefaultValue } from '@control-front-end/app/src/components/FormConstructor/SetDefaultValue';
import m from './intl';

import l from './CreateActorAccount.scss';

function SpacedTitle({ ...props }) {
  return (
    <Space bottom size={Space.SIZE.xsmall}>
      <Label color={Label.COLOR.black} fontWeight="semibold" {...props} />
    </Space>
  );
}

const DEFAULT_VALUE_BY_CLASS = {
  multiSelect: [],
  calendar: {},
  check: false,
};

function CreateFormFieldAccount({ forms: actorsForms = [], onChange }) {
  const t = useIntl();

  const [form, setForm] = useState(null);

  const dispatch = useDispatch();

  const [state, setState] = useObjectState(
    {
      formId: null,
      sectionIndex: null,
      title: null,
      type: null,
    },
    {
      transformValue: ({ value }) => value,
      onChange: ({ formId, sectionIndex, title, type }) => {
        if ([formId, sectionIndex, type].includes(null)) return;

        const section = form?.sections[sectionIndex];

        if (!form || !section) return;

        const newField = {
          id: `item_${AppUtils.udid()}`,
          formId,
          key: AppUtils.udid(),
          title: title || `Title ${section.content.length + 1}`,
          class: type,
          visibility: 'visible',
          idNotChanged: true,
          value: DEFAULT_VALUE_BY_CLASS[type],
        };

        const updatedFormData = set(
          cloneDeep(form),
          `sections[${sectionIndex}]`,
          { ...section, content: [...section.content, newField] }
        );

        updatedFormData.id = formId;

        setDefaultValue({
          form: updatedFormData,
          activeSection: sectionIndex,
          activeItem: section.content.length,
        });

        onChange(updatedFormData);
      },
    }
  );

  useEffect(() => {
    if (!state.formId) return;
    dispatch({
      type: GET_FORM.REQUEST,
      payload: { formId: state.formId },
      afterReq: ({ form }) => {
        setForm(form);
      },
    });
  }, [state.formId]);

  const section = form?.sections[state.sectionIndex];

  return (
    <Space bottom>
      <Stack.V>
        <Stack.H size="xsmall" alignItems="center">
          <Icon type="info" />
          <Label color={Label.COLOR.gray} value={t(m.newFormFieldWarning)} />
        </Stack.H>
        <Stack.H fullWidth alignItems="center">
          <Select
            label={<SpacedTitle value={t(m.formSelectTitle)} />}
            bordered
            unspaced
            value={form?.title}
            color={Select.COLOR.white}
            onChange={({ value }) => {
              setState.formId({ value });
              setState.sectionIndex({ value: null });
            }}
            helperText={t(m.formSelectHelpText)}
            className={l.flexItem}
          >
            {actorsForms.map(({ id, title, type, isDefault, privs = {} }) => {
              const canEditForm =
                type !== 'system' && !isDefault && privs.modify;
              const item = (
                <MenuItem
                  key={id}
                  label={title}
                  value={id}
                  visibility={canEditForm ? 'visible' : 'disabled'}
                />
              );
              return cr(
                [canEditForm, item],
                [
                  true,
                  <Tooltip
                    key={id}
                    value={t(m.editAccessDenied)}
                    topLevel
                    breakWord
                  >
                    <div>{item}</div>
                  </Tooltip>,
                ]
              );
            })}
          </Select>
          <Select
            label={<SpacedTitle value={t(m.sectionSelectTitle)} />}
            bordered
            unspaced
            visibility={form ? 'visible' : 'disabled'}
            value={
              section
                ? section?.title || `Section ${state.sectionIndex + 1}`
                : ''
            }
            color={Select.COLOR.white}
            onChange={setState.sectionIndex}
            helperText={t(m.sectionSelectHelpText)}
            className={l.flexItem}
          >
            {(form?.sections || []).map(({ title }, index) => (
              <MenuItem
                key={index}
                label={title || `Section ${index + 1}`}
                value={index}
              />
            ))}
          </Select>
        </Stack.H>
        <FieldTypeSelector
          unspaced
          bordered
          color={TextField.COLOR.white}
          visibility={form && section ? 'visible' : 'disabled'}
          type={state.type}
          onChange={setState.type}
          label={<SpacedTitle value="Type" />}
          popoverOnTop
        />
        <TextField
          color={TextField.COLOR.white}
          visibility={form && section ? 'visible' : 'disabled'}
          unspaced
          bordered
          value={state.title}
          onChange={setState.title}
          label={<SpacedTitle value={t(m.fieldTitle)} />}
        />
      </Stack.V>
    </Space>
  );
}

CreateFormFieldAccount.propType = {
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CreateFormFieldAccount;
