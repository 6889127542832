import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Button,
  Stack,
  Upload,
  Icon,
  Tooltip,
  ProgressBar,
} from 'mw-style-react';
import cn from 'classnames';
import { pick } from 'lodash';
import { useIntl } from '@control-front-end/app/src/hooks';
import { UPDATE_USER_SETTINGS, UPLOAD_FILE } from 'constants';
import { VIDEO_BACKGROUND_EFFECT } from '@control-front-end/common/constants/meeting';
import AppUtils from '@control-front-end/utils/utils';
import BlurIcon from './img/blur.svg';
import NoEffectIcon from './img/noEffect.svg';
import AddEffectIcon from './img/addEffect.svg';
import scss from './EffectsMenu.scss';
import mes from './intl';

const IMAGE_VALUABLE_KEYS = ['id', 'fileName', 'filePath', 'title', 'type'];

/**
 * Returns a tooltip-wrapped card button.
 */
function EffectCard({ tooltip, isActive, onClick, children, className }) {
  return (
    <Tooltip value={tooltip} topLevel>
      <Card
        className={cn(scss.effectBox, { [scss.active]: isActive }, className)}
        onClick={onClick}
      >
        {children}
      </Card>
    </Tooltip>
  );
}

/**
 * Component that adds visual effects controls for a camera background
 */
function EffectsMenu({ onSaving }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const { maxFileSize } = useSelector((state) => state.config);

  const { meeting: meetingSettings } = useSelector((state) => state.settings);
  const {
    virtualBgImages = [],
    activeBgImageId,
    activeVirtualEffect,
  } = meetingSettings;

  const images = useMemo(() => {
    return virtualBgImages.map((file) => ({
      ...file,
      filePath:
        file.filePath || AppUtils.makeAppUrl(`/download/${file.fileName}`),
    }));
  }, [virtualBgImages]);

  const saveMeetingSettings = useCallback(
    (changes) => {
      onSaving(true);
      dispatch({
        type: UPDATE_USER_SETTINGS.REQUEST,
        payload: { accId: null, meeting: { ...meetingSettings, ...changes } },
        callback: () => onSaving(false),
      });
    },
    [meetingSettings, onSaving]
  );

  const handleUploadImage = useCallback(
    ({ value }) => {
      setUploading(true);
      dispatch({
        type: UPLOAD_FILE.REQUEST,
        payload: {
          files: value,
          callback: ([file]) => {
            saveMeetingSettings({
              virtualBgImages: [...images, pick(file, IMAGE_VALUABLE_KEYS)],
              activeVirtualEffect: VIDEO_BACKGROUND_EFFECT.IMAGE,
              activeBgImageId: file.id,
            });
            setUploading(false);
          },
        },
      });
    },
    [images, saveMeetingSettings]
  );

  const handleRemoveImage = useCallback(
    (fileId) => {
      const updatedImages = images.filter((i) => i.id !== fileId);
      saveMeetingSettings({ virtualBgImages: updatedImages });
    },
    [images, saveMeetingSettings]
  );

  const renderCustomImageEffect = useCallback(
    (file) => {
      const isActive = activeBgImageId === file.id;
      return (
        <div style={{ position: 'relative' }} key={file.id}>
          <EffectCard
            key={file.id}
            tooltip={file.title}
            isActive={isActive}
            onClick={() =>
              saveMeetingSettings({
                activeVirtualEffect: VIDEO_BACKGROUND_EFFECT.IMAGE,
                activeBgImageId: file.id,
              })
            }
          >
            <img src={file.filePath} alt={file.title} className={scss.image} />
          </EffectCard>
          <Tooltip value={isActive ? t(mes.cantDeleteImage) : null} topLevel>
            <Button
              className={scss.removeBtn}
              size="xxsmall"
              type="quinary"
              icon={
                <Icon size="small" type="declined" colorType={Icon.COLOR.red} />
              }
              rounded
              onClick={(e) => {
                e.stopPropagation();
                if (!isActive) handleRemoveImage(file.id);
              }}
              visibility={isActive ? 'disabled' : 'visible'}
            />
          </Tooltip>
        </div>
      );
    },
    [images, activeBgImageId, saveMeetingSettings]
  );

  return (
    <Stack.H
      className={scss.effectsMenu}
      size="xsmall"
      alignItems="center"
      fullWidth
    >
      <EffectCard
        tooltip={t(mes.noEffects)}
        isActive={activeVirtualEffect === null}
        onClick={() =>
          saveMeetingSettings({
            activeVirtualEffect: null,
            activeBgImageId: null,
          })
        }
      >
        <img alt="none" src={NoEffectIcon} />
      </EffectCard>
      <EffectCard
        tooltip={t(mes.blurEffect)}
        isActive={activeVirtualEffect === VIDEO_BACKGROUND_EFFECT.BLUR}
        onClick={() =>
          saveMeetingSettings({
            activeVirtualEffect: VIDEO_BACKGROUND_EFFECT.BLUR,
            activeBgImageId: null,
          })
        }
      >
        <img alt="blur" src={BlurIcon} />
      </EffectCard>
      <EffectCard
        className={scss.upload}
        tooltip={t(mes.uploadCustomBackground)}
      >
        <Upload
          accept="image/*"
          onChange={handleUploadImage}
          maxFileSize={maxFileSize}
        >
          <img alt="upload" src={AddEffectIcon} />
        </Upload>
      </EffectCard>
      {images.map(renderCustomImageEffect)}
      {uploading && (
        <EffectCard>
          <ProgressBar size="small" type="circle" />
        </EffectCard>
      )}
    </Stack.H>
  );
}
export default EffectsMenu;
