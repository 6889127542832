import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Badge,
  Button,
  Card,
  CorezoidLightTheme as theme,
  DateUtils,
  Icon,
  Label,
  Stack,
} from 'mw-style-react';
import {
  NOTIFY_LEVEL,
  SHOW_NOTIFY,
  HIDE_NOTIFY,
  DATE_FORMAT_5,
  STOP_SOUND,
  SOUND_CATEGORY,
  SOUND_TYPE,
  MAKE_SOUND,
} from 'constants';
import { MEETING_AUTO_JOIN } from '@control-front-end/common/constants/graphActors';
import {
  WS_MEETING_REMINDER_ACTOR,
  WS_MEETING_STARTED_ACTOR,
  MEETING_CHANNEL,
  MEETING_SOUND_CHANNEL,
  STOP_MEETING_NOTIFY,
  PLAY_MEETING_SOUND,
  WS_SIP_REMOVE_PARTICIPANT,
} from '@control-front-end/common/constants/meeting';
import soundPlayer from '@control-front-end/utils/soundPlayer';
import { useBroadcastChannel, useIntl, useReduxAction } from 'hooks';
import mes from 'globalIntl';
import scss from '@control-front-end/common/styles/notifyContent.scss';
import AppUtils from '@control-front-end/utils/utils';
import history from '../store/history';

const SECONDS_PER_MINUTE = 60;
const NOTIFY_BORDER_RADIUS = Card.BORDER_RADIUS.xxlarge;
const NOTIFICATION_DEBOUNCE_TIME = 2000; // 2 seconds
const NOTIFICATION_CLEANUP_TIME = 60000; // 1 minute

const MEETING_SOUNDS_MAP = {
  [WS_MEETING_REMINDER_ACTOR]: SOUND_TYPE.meetingStartTime,
  [WS_MEETING_STARTED_ACTOR]: SOUND_TYPE.calling,
};

const getNotificationHideDelay = (eventType, modelData) => {
  if (!modelData) return null;
  return eventType === WS_MEETING_REMINDER_ACTOR
    ? AppUtils.getMsToUnixTime(modelData.startDate)
    : AppUtils.getMsToUnixTime(modelData.endDate);
};

function EventAvatar() {
  return (
    <Avatar className={scss.avatar} size="large" bgColor={theme.palette.white}>
      <Icon type="event" colorType={Icon.COLOR.primary} />
    </Avatar>
  );
}

function NotifyLabel({
  text,
  color = Label.COLOR.black,
  fontSize = Label.SIZES.medium,
  fontWeight = Label.WEIGHTS.semibold,
  overflow,
}) {
  return (
    <Label
      className={scss.label}
      overflow={overflow}
      value={text}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
    />
  );
}

function useScheduleMeetingNotifier() {
  const t = useIntl();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const activeAccId = useSelector((state) => state.accounts.active);
  const isIframe = window.self !== window.top;

  const playSoundInCurrentTab = (eventType, loop = false) => {
    dispatch({
      type: MAKE_SOUND,
      payload: {
        type: MEETING_SOUNDS_MAP[eventType],
        category: SOUND_CATEGORY.meeting,
        settings: { loop },
      },
    });
  };

  // Channel for syncing sound playback across tabs
  const handleSoundBroadcastMessage = ({ type, payload }) => {
    if (type === PLAY_MEETING_SOUND) {
      const { eventType, loop = false } = payload;
      playSoundInCurrentTab(eventType, loop);
    }
  };

  const sendSoundBroadcastMessage = useBroadcastChannel(
    MEETING_SOUND_CHANNEL,
    handleSoundBroadcastMessage
  );

  const stopSound = () => {
    dispatch({ type: STOP_SOUND });
  };

  const startSound = (eventType, loop = false) => {
    // Instead of playing directly in this tab, broadcast the sound event to all tabs
    sendSoundBroadcastMessage({
      type: PLAY_MEETING_SOUND,
      payload: { eventType, loop },
    });

    // Also play in the current tab
    playSoundInCurrentTab(eventType, loop);
  };

  const checkSoundRelevant = (notifyItems) => {
    const audio = soundPlayer.get();
    if (!audio || !audio.src) return false;

    return notifyItems.some((item) => {
      const eventType = Object.keys(MEETING_SOUNDS_MAP).find((type) =>
        item.id.includes(type)
      );
      const notifySound = `${SOUND_CATEGORY.meeting}/${MEETING_SOUNDS_MAP[eventType]}`;
      return eventType && audio.src.includes(notifySound);
    });
  };

  const hideNotify = (id, needStopSound = false) => {
    dispatch({
      type: HIDE_NOTIFY.REQUEST,
      payload: { id },
      callback: (restItems) => {
        if (needStopSound && !checkSoundRelevant(restItems)) stopSound();
      },
    });
  };

  const showNotify = ({ id, extra }) => {
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id,
        type: NOTIFY_LEVEL.PRIMARY,
        borderRadius: NOTIFY_BORDER_RADIUS,
        ...extra,
      },
    });
  };

  // Callback to handle incoming messages from the BroadcastChannel
  const handleBroadcastMessage = ({ type, payload }) => {
    if (type === STOP_MEETING_NOTIFY) {
      const { id, userId, needStopSound } = payload;
      if (userId !== auth.id) return;
      hideNotify(id, needStopSound);
    }
  };

  // Use the custom BroadcastChannel to handle meeting notifications actions
  const sendBroadcastMessage = useBroadcastChannel(
    MEETING_CHANNEL,
    handleBroadcastMessage
  );

  const stopNotificationsForAllTabs = (id, needStopSound = false) => {
    hideNotify(id, needStopSound);
    sendBroadcastMessage({
      type: STOP_MEETING_NOTIFY,
      payload: { id, userId: auth.id, needStopSound },
    });
  };

  const handleMeetingNavigation = (id, { accId, id: modelId }) => {
    const meetingUrl = `/actors_graph/${accId}/view/${modelId}?tab=meeting&${MEETING_AUTO_JOIN}=true`;

    if (accId === activeAccId) {
      // Navigate within the same workspace without reloading the page
      history.push(meetingUrl);
    } else {
      // Construct absolute URL and reload to switch workspaces
      window.location.assign(new URL(meetingUrl, window.location.origin));
    }

    // Hide the notification after navigation
    hideNotify(id);
  };

  const renderNotificationContent = ({ renderFunc, ...props }) => (
    <Stack.H
      size="small"
      fullWidth
      alignItems="center"
      justifyContent="spaceBetween"
    >
      {renderFunc(props)}
    </Stack.H>
  );

  const getWorkspaceNameByAccId = (accId) => {
    return auth.workspaces[accId]?.name || 'New Workspace';
  };

  const renderMeetingReminder = ({ id, model, minutesLeft, label }) => {
    const workspaceName = getWorkspaceNameByAccId(model.accId);

    return (
      <>
        <EventAvatar />
        <Stack.V size="none" fullWidth>
          <NotifyLabel text={t(mes.meetingReminder, { minutesLeft })} />
          <NotifyLabel text={label} overflow="cut" />
          <NotifyLabel
            text={workspaceName}
            color={Label.COLOR.gray}
            fontSize={Label.SIZES.small}
            fontWeight={Label.WEIGHTS.normal}
            overflow="cut"
          />
        </Stack.V>
        <Button
          size="small"
          fontWeight="normal"
          label={t(mes.connect)}
          onClick={() => {
            stopNotificationsForAllTabs(id);
            handleMeetingNavigation(id, model);
          }}
        />
      </>
    );
  };

  const renderMeetingStarted = ({ id, model, label }) => {
    const workspaceName = getWorkspaceNameByAccId(model.accId);

    return (
      <>
        <Badge
          className={scss.badgeButton}
          size="xxlarge"
          bgColor={theme.fullPalette.red}
          borderColor={theme.fullPalette.red}
          value={<Icon type="call_off" color={theme.palette.white} />}
          onClick={() => stopNotificationsForAllTabs(id, true)}
        />
        <EventAvatar />
        <Stack.V size="none" fullWidth>
          <NotifyLabel text={label} overflow="cut" />
          <NotifyLabel
            text={workspaceName}
            color={Label.COLOR.gray}
            fontSize={Label.SIZES.small}
            fontWeight={Label.WEIGHTS.normal}
            overflow="cut"
          />
        </Stack.V>
        <Badge
          className={scss.badgeButton}
          size="xxlarge"
          bgColor={theme.fullPalette.green}
          borderColor={theme.fullPalette.green}
          value={<Icon type="call" color={theme.palette.white} />}
          onClick={() => {
            stopNotificationsForAllTabs(id, true);
            handleMeetingNavigation(id, model);
          }}
        />
      </>
    );
  };

  const prepareNotificationContent = (id, model, renderFunc) => {
    const { title, data } = model;
    if (!data || !renderFunc) return null;

    const formattedTime = DateUtils.toDate(data.startDate, DATE_FORMAT_5);
    const label = `${formattedTime} ${title}`;
    const now = Date.now() / 1000;
    const minutesLeft = Math.ceil((data.startDate - now) / SECONDS_PER_MINUTE);

    return renderNotificationContent({
      renderFunc,
      id,
      model,
      minutesLeft,
      label,
    });
  };

  // Add a sync timestamp to track which tab received the event first
  const receivedEvents = new Map();

  const createNotification = (eventType, renderFunc, extra = {}) => {
    useReduxAction(
      ({ payload }) => {
        if (isIframe) return; // Skip creating notifications inside iframe
        const { model } = payload;
        const id = `${eventType}_${model.id}`;

        // Check if this is a duplicate event
        const eventKey = `${eventType}_${model.id}`;
        const now = Date.now();
        const lastReceived = receivedEvents.get(eventKey);

        // Only play sound in the first tab that receives the event
        // or if it's been more than the debounce time since last notification
        const shouldPlaySound =
          !lastReceived || now - lastReceived > NOTIFICATION_DEBOUNCE_TIME;

        // Update the timestamp for this event
        receivedEvents.set(eventKey, now);

        // Clean up old events (older than cleanup time)
        for (const [key, timestamp] of receivedEvents.entries()) {
          if (now - timestamp > NOTIFICATION_CLEANUP_TIME) {
            receivedEvents.delete(key);
          }
        }

        const content = renderFunc
          ? prepareNotificationContent(id, payload.model, renderFunc)
          : null;
        const hideDelay = getNotificationHideDelay(eventType, model?.data);
        // reinsurance against irrelevant callbacks
        if (hideDelay && hideDelay < 0) return;

        showNotify({
          id,
          extra: {
            content,
            hideDelay,
            onClose: () => stopNotificationsForAllTabs(id, extra.loopSound),
            ...extra,
          },
        });

        const { data: { disableCallbackSoundUsers = [] } = {} } = model;
        if (!disableCallbackSoundUsers.includes(auth.id) && shouldPlaySound) {
          startSound(eventType, extra.loopSound);
        }
      },
      eventType,
      []
    );
  };

  createNotification(WS_MEETING_REMINDER_ACTOR, renderMeetingReminder);

  createNotification(WS_MEETING_STARTED_ACTOR, renderMeetingStarted, {
    closeIcon: false,
    loopSound: true,
  });

  useReduxAction(({ payload }) => {
    const removedAuthUser = payload.extra.participants.find(
      (u) => u.userId === auth.id
    );
    if (removedAuthUser?.value) {
      showNotify({
        id: AppUtils.createRid(),
        extra: {
          content: (
            <Stack.H fullWidth>
              <NotifyLabel
                color={Label.COLOR.primary}
                text={t(mes.meetingUserRemoved)}
              />
            </Stack.H>
          ),
        },
      });
    }
  }, WS_SIP_REMOVE_PARTICIPANT);
}

export default useScheduleMeetingNotifier;
