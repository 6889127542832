import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Clipboard,
  Label,
  Icon,
  Menu,
  MenuItem,
  FileUtils,
  ProgressBar,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import { URL_REGEXP } from '@control-front-end/common/constants/regExp';
import cn from 'classnames';
import useIntl from 'useIntl';
import FileViewer from 'react-file-viewer';
import ZipImg from './img/zip.svg';
import mes from './intl';
import './FilePreview.scss';

function FilePreview({ data, onClose }) {
  const { files, id = '', fileName, actions = [] } = data;
  const index = files.findIndex(
    (file) =>
      (file.id && file.id.toString() === id.toString()) ||
      file.fileName === fileName
  );
  const [activeFileIndex, setActiveFileIndex] = useState(index);
  const [menu, toggleMenu] = useState(false);
  const [activeFile, setActiveFile] = useState(files[activeFileIndex]);
  const [isLoaded, setLoaded] = useState(false);
  const activeFilePath =
    activeFile.filePath ||
    AppUtils.makeAppUrl(`/download/${activeFile.fileName}`);
  const dispatch = useDispatch();
  const t = useIntl();
  const isMobile = AppUtils.isMobile();

  const toPrevFile = () => {
    let prevIndex = activeFileIndex;
    if (prevIndex > 0) {
      prevIndex -= 1;
      setActiveFileIndex(prevIndex);
      setLoaded(false);
    }
  };

  const toNextFile = () => {
    let nextIndex = activeFileIndex;
    if (nextIndex < files.length - 1) {
      nextIndex += 1;
      setActiveFileIndex(nextIndex);
      setLoaded(false);
    }
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    switch (e.code) {
      case 'Escape':
        onClose();
        break;
      case 'ArrowLeft':
        toPrevFile();
        break;
      case 'ArrowRight':
        toNextFile();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const getLoadingStatus = () => {
    const req = new XMLHttpRequest();
    req.open('GET', activeFilePath, true);
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        setLoaded(true);
      }
    };
    req.send();
  };

  useEffect(() => {
    if (files[activeFileIndex].type === 'image') {
      return;
    }
    const parts = files[activeFileIndex].title.split('.');
    const extension = parts[parts.length - 1].toLowerCase();
    setActiveFile({
      ...files[activeFileIndex],
      extension,
      styleName: `preview__${extension}`,
    });
  }, [activeFileIndex]);

  // Download file
  const downloadFile = () => {
    FileUtils.download(activeFilePath, activeFile.title);
  };

  // Copy link to file
  const handleCopyLink = () => {
    const urlReg = new RegExp(URL_REGEXP);
    const link = urlReg.test(activeFilePath)
      ? activeFilePath
      : `${document.location.origin}${activeFilePath}`;
    Clipboard.copy(`${link}?preview=true`);
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.SUCCESS,
        label: t(mes.linkCopied),
      },
    });
  };

  const handleMenuClick = ({ value }) => {
    if (value === 'link') {
      handleCopyLink();
    } else if (value === 'download') {
      downloadFile();
    } else {
      const action = actions.find((i) => i.value === value);
      if (action.callback) action.callback(activeFile);
    }
    toggleMenu(!menu);
  };

  // Render More actions menu
  const renderMenu = () => (
    <div styleName="preview__menu__wrap" onClick={(e) => e.stopPropagation()}>
      <Menu
        styleName="preview__menu"
        visibility={menu ? 'visible' : 'hidden'}
        onClick={({ value }) => handleMenuClick({ value })}
        onClose={() => toggleMenu(!menu)}
      >
        <MenuItem
          leftIcon="link"
          label={t(mes.copyLink)}
          value="link"
          visibility={isMobile ? 'visible' : 'hidden'}
        />
        <MenuItem
          leftIcon="download"
          label={t(mes.download)}
          value="download"
          visibility={isMobile ? 'visible' : 'hidden'}
        />
        {actions.map((action) => (
          <MenuItem key={action.value} leftIcon={action.icon} {...action} />
        ))}
      </Menu>
    </div>
  );

  const renderFile = (file) => {
    if (file.type.indexOf('image') !== -1) {
      return (
        <img
          id="preview"
          src={activeFilePath}
          alt={file.title}
          width={file.width}
          height={file.height}
        />
      );
    }

    if (activeFilePath && !isLoaded) {
      getLoadingStatus();
    }

    const fileType = activeFile.extension;
    switch (fileType) {
      case 'zip':
        return (
          <div styleName="preview__file">
            <img id={file.id} src={ZipImg} alt="" />
          </div>
        );
      case 'pdf':
      case 'docx':
      case 'mp4':
      case 'webm':
      case 'mp3':
      case 'xlsx':
      case 'csv':
        return (
          <div
            styleName={cn(activeFile.styleName, { loaded: isLoaded })}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!isLoaded ? (
              <div styleName="preview__loader">
                <ProgressBar type="circle" size="large" />
              </div>
            ) : null}
            <FileViewer
              fileType={activeFile.extension}
              filePath={activeFilePath}
            />
          </div>
        );
      default:
        return fileType ? (
          <div styleName="preview__file">
            <Icon type="picture" size="large" />
          </div>
        ) : null;
    }
  };

  const renderNavigationControls = () => {
    const isFirstItem = activeFileIndex === 0;
    const isLastItem = activeFileIndex === files.length - 1;
    return (
      <div styleName="preview__navigation" onClick={(e) => e.stopPropagation()}>
        <div>
          {!isFirstItem ? (
            <Tooltip topLevel value={t(mes.prev)}>
              <div styleName="preview__actions__icon" onClick={toPrevFile}>
                <Icon type="arrow" size="medium" />
              </div>
            </Tooltip>
          ) : null}
        </div>
        <div>
          {!isLastItem ? (
            <Tooltip topLevel value={t(mes.next)}>
              <div
                styleName={cn('preview__actions__icon', 'arrowDown')}
                onClick={toNextFile}
              >
                <Icon type="arrow" size="medium" />
              </div>
            </Tooltip>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div styleName="preview" onClick={onClose}>
      <div styleName="preview__header">
        <div styleName="preview__header__title">
          <div styleName="preview__actions__icon" onClick={onClose}>
            <Icon type="close" size="small" />
          </div>
          <Label styleName="preview__title" value={activeFile.title} />
        </div>
        <div styleName="preview__actions" onClick={(e) => e.stopPropagation()}>
          <Tooltip topLevel value={t(mes.copyLink)}>
            <div
              styleName={cn('preview__actions__icon', { hidden: isMobile })}
              onClick={(e) => {
                e.stopPropagation();
                handleCopyLink();
              }}
            >
              <Icon type="link" size="medium" />
            </div>
          </Tooltip>
          <Tooltip topLevel value={t(mes.download)}>
            <div
              styleName={cn('preview__actions__icon', {
                hidden: isMobile,
                noMargin: !isMobile && !actions.length,
              })}
              onClick={(e) => {
                e.stopPropagation();
                downloadFile();
              }}
            >
              <Icon type="download" size="medium" />
            </div>
          </Tooltip>
          {isMobile || actions.length ? (
            <div>
              <div
                styleName={cn('preview__actions__icon', 'noMargin')}
                onClick={() => toggleMenu(!menu)}
              >
                <Icon type="more" size="medium" />
              </div>
              {renderMenu()}
            </div>
          ) : null}
        </div>
      </div>
      {files.length > 1 ? renderNavigationControls() : null}
      {renderFile(activeFile)}
    </div>
  );
}

FilePreview.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default FilePreview;
