import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';
import { TextField } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import mes from '../../intl';
import './Edit.scss';

/**
 * Edit Constructor Component
 */
class Edit extends PureComponent {
  // Localization function
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  render() {
    const { id, title, placeholder, autoFocus, visibility, onChange } =
      this.props;
    return (
      <TextField
        id={id}
        styleName={cn('edit')}
        label={placeholder || this.i(mes.addTitle)}
        value={title || ''}
        autoFocus={autoFocus}
        visibility={visibility}
        onChange={(data) => {
          onChange({ fieldId: 'title', ...data });
        }}
      />
    );
  }
}

Edit.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  autoFocus: PropTypes.bool,
  visibility: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

Edit.contextTypes = {
  intl: intlShape,
};

export default injectIntl(Edit);
