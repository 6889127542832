import PropTypes from 'prop-types';
import React from 'react';
import { Utils, Icon } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import useIntl from 'useIntl';
import mes from './intl';
import FIELDS from '../../Fields';
import Edit from './Items/Edit';
import Select from './Items/Select';
import MultiSelect from './Items/MultiSelect';
import Multi from './Items/Multi';
import Calendar from './Items/Calendar';
import './Panel.scss';

const _ITEMS_ = {
  Edit,
  Select,
  MultiSelect,
  Multi,
  Calendar,
};
// Freeze the items fields settings object
const _FIELDS_ = AppUtils.deepFreeze(FIELDS);

function Panel(props) {
  const {
    sections,
    sectionIndex,
    itemIndex,
    class: className,
    type,
    options,
    extra,
    idNotChanged,
    errors = {},
    onChange,
    onShowPanel,
  } = props;
  const t = useIntl();
  const hasSource = extra && extra.optionsSource?.type !== 'manual';

  // Create an object with errors
  const makeError = ({ error, id: fieldId, errorId }) => {
    if (error) {
      errors[fieldId] = t(mes[errorId]) || t(mes.incorrect_data);
    } else {
      delete errors[fieldId];
    }
    return Object.keys(errors).length ? errors : undefined;
  };

  // Handler for changing extra fields
  const handleChangeExtra = ({ id, value }) => {
    const { minDate, maxDate } = value;
    const error = minDate && maxDate ? minDate > maxDate : false;
    const errors = makeError({
      error,
      id: 'maxDate',
      errorId: 'notValidMaxDate',
    });
    if (value.static) {
      // calendar date is static => reset other checkboxes
      onChange({
        id: 'extra',
        value: { ...value, time: false, timeZone: false, dateRange: false },
      });
    } else {
      onChange({ id, value, errors });
    }
    if (value.multiline) {
      onChange({ id: 'type', value: 'text' });
    }
  };

  // Handler for changing Default value
  const handleChangeValue = ({ id, value, error }) => {
    let valueTitle;
    if (className === 'select') {
      valueTitle = Array.isArray(options)
        ? options.find((i) => i.value === value)
        : {};
    }
    // default value is optional => error only if field is non-empty
    const errors = makeError({
      error: value.length && error,
      id,
      errorId: 'incorrect_data',
    });
    onChange({
      id,
      value: valueTitle ? [valueTitle] : value,
      errors,
    });
  };

  // Handler for changing fields in the panel
  const handleChange = (data) => {
    const { id, value } = data;
    switch (id) {
      case 'extra':
        handleChangeExtra(data);
        break;
      case 'value':
        handleChangeValue(data);
        break;
      default:
        onChange(data);
        if (id === 'visibility' && value === 'disabled') {
          onChange({ id: 'required', value: 'false' });
        } else if (id === 'required' && value === 'true') {
          onChange({ id: 'visibility', value: 'visible' });
        }
        break;
    }
  };

  // Handler for ID input
  const handleIdChange = ({ id, value }) => {
    const error = sections.some((section) =>
      section.content.some(
        (item) =>
          value === item.id &&
          (item.sectionIndex !== sectionIndex || item.itemIndex !== itemIndex)
      )
    );
    const errors = makeError({ error, id, errorId: 'notUniqueId' });
    onChange({ id, value, errors });
  };

  const getCustomItemProps = (item) => {
    const customProps = { errors };
    switch (item.id) {
      case 'id':
        customProps.idNotChanged = idNotChanged;
        break;
      case 'type':
        customProps.visibility = extra?.multiline ? 'disabled' : 'visible';
        break;
      case 'value':
        customProps.visibility = hasSource ? 'disabled' : 'visible';
        customProps.type = className === 'edit' ? type : undefined;
        if (item.class === 'calendar') {
          customProps.time = extra?.time;
          customProps.timeZone = extra?.timeZone;
        }
        break;
      default:
        break;
    }
    return customProps;
  };

  const renderItems = (items) => {
    const rItems = [];
    for (const sourceItem of items) {
      if (!sourceItem.class) continue;
      const item = { ...sourceItem };
      const ItemName = Utils.toUpperLatter(item.class);
      const Item = _ITEMS_[ItemName];
      if (!Item) continue;
      const itemVal =
        item.id === 'required' && !props[item.id] ? 'false' : props[item.id];
      item.value = typeof itemVal === 'undefined' ? item.value : itemVal;
      if (item.options && item.options === 'DYNAMIC') {
        const selectOpt = Array.isArray(options)
          ? options.filter((i) => !!i.title)
          : [];
        selectOpt.unshift({ value: '', title: '' });
        item.options = selectOpt;
      }
      const customProps = getCustomItemProps(item);
      rItems.push(
        <Item
          key={`${sectionIndex}_${itemIndex}_${item.id}`}
          onChange={handleChange}
          handleIdChange={handleIdChange}
          makeError={makeError}
          {...item}
          {...customProps}
        />
      );
    }
    return rItems;
  };

  const renderSections = (className) => {
    const itemFields = _FIELDS_[className];
    const itemType = sections[sectionIndex]
      ? sections[sectionIndex].content[itemIndex].type
      : undefined;
    const showExtra = className !== 'edit' || !itemType || itemType === 'text';
    return Object.keys(itemFields).map((section, index) => {
      if (
        (section === 'options' && hasSource) ||
        (section === 'extra' && !showExtra)
      )
        return null;
      return (
        <div key={section} styleName="panel__section">
          <div styleName="panel__section__title">
            {t(mes[`panelTitle_${section}`])}
            {index === 0 ? (
              <Icon
                styleName="panel__section__title__close"
                type="close"
                onClick={onShowPanel}
              />
            ) : null}
          </div>
          <div styleName="panel__section__content">
            {renderItems(itemFields[section])}
          </div>
        </div>
      );
    });
  };

  return <div styleName="panel">{renderSections(className)}</div>;
}

Panel.propTypes = {
  class: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onShowPanel: PropTypes.func.isRequired,
  sections: PropTypes.array.isRequired,
  sectionIndex: PropTypes.number,
  itemIndex: PropTypes.number,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  extra: PropTypes.object,
  errors: PropTypes.object,
};

export default Panel;
