import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';
import { TextField } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import mes from '../../intl';
import './Label.scss';

/**
 * Label Constructor Component
 */
class Label extends PureComponent {
  // Localization function
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  render() {
    const { id, value, autoFocus, onChange } = this.props;
    return (
      <TextField
        id={id}
        styleName={cn('label')}
        label={this.i(mes.addLabel)}
        value={value}
        autoFocus={autoFocus}
        onChange={(data) => onChange({ fieldId: 'value', ...data })}
      />
    );
  }
}

Label.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
};

Label.contextTypes = {
  intl: intlShape,
};

export default injectIntl(Label);
