import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Popover, useObjectState } from 'mw-style-react';
import useAccountPairNames from '@control-front-end/app/src/hooks/accounts/useAccountPairNames';
import FilterAccPair from '../FilterAccPair';

export function FilterAccPairPopover({ onChange, children, ...props }) {
  return (
    <Popover
      anchors={{
        binding: Popover.ANCHOR.left_bottom,
        content: Popover.ANCHOR.left_top,
      }}
      content={({ onClose }) => (
        <FilterAccPair
          disableSystemAccounts={true}
          handleClose={onClose}
          handleSelect={(...args) => {
            onChange(...args);
            onClose(...args);
          }}
        />
      )}
      {...props}
    >
      {children}
    </Popover>
  );
}

function FilterAccPairSelect({
  nameId,
  currencyId,
  accountName,
  currencyName,
  className,
  style,
  onChange,
  popoverOnTop,
  popoverOptions,
  value: valueProp,
  ...props
}) {
  const [state, setObjState, setState] = useObjectState({
    nameId,
    currencyId,
    accountName,
    currencyName,
  });

  useEffect(() => {
    setObjState.nameId(nameId);
    setObjState.currencyId(currencyId);
  }, [nameId, currencyId]);

  useAccountPairNames({
    nameId,
    currencyId,
    skipRequest: state.accountName && state.currencyName,
    callback: (result) => {
      const newState = { ...state, ...result };
      setState(newState);
      if (onChange) onChange(newState);
    },
  });

  const value =
    state.accountName && state.currencyName
      ? `${state.accountName}, ${state.currencyName}`
      : null;

  return props.visibility === 'disabled' ? (
    <Select
      value={valueProp || value}
      bordered
      unspaced
      resettable
      onReset={() => {
        const newState = {};
        setState(newState);
        if (onChange) onChange(newState);
      }}
      {...props}
    />
  ) : (
    <FilterAccPairPopover
      onChange={({ value }) => {
        const newState = { ...state, ...value };
        setState(newState);
        if (onChange) onChange(newState);
      }}
      className={className}
      style={style}
      topLevel={popoverOnTop}
      {...popoverOptions}
    >
      <Select
        value={valueProp || value}
        bordered
        unspaced
        resettable
        onReset={() => {
          const newState = {};
          setState(newState);
          if (onChange) onChange(newState);
        }}
        {...props}
      />
    </FilterAccPairPopover>
  );
}

FilterAccPairSelect.defaultProps = {
  popoverOptions: {},
};

FilterAccPairSelect.propTypes = {
  value: PropTypes.string,
  nameId: PropTypes.string,
  currencyId: PropTypes.number,
  accountName: PropTypes.string,
  currencyName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  popoverOnTop: PropTypes.bool,
  popoverOptions: PropTypes.object,
};

export default FilterAccPairSelect;
