import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEL_MODAL,
  NOTIFY_LEVEL,
  SET_MODAL,
  SHOW_NOTIFY,
  UPDATE_USER_SETTINGS,
} from 'constants';
import { useIntl, useModal } from 'hooks';
import { getActiveAccount } from 'selectors';
import AppUtils from '@control-front-end/utils/utils';
import mes from '../../../components/BulkActionsHeader/intl';
import NoAccessItems from '../components/NoAccessItems';

/**
 * Hook for pagination of actors/forms list
 */
const useBulkActions = ({ objType, list }) => {
  const t = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const activeAccount = useSelector(getActiveAccount) || {};
  const [selectedItems, setSelectedItems] = useState([]);
  const { open: openManageAccountsTagsModal } = useModal('BulkManageTags', {});
  const { open: openAddAccountsModal } = useModal('CreateActorAccount', {
    accounts: [],
  });

  /**
   * Add to export
   */
  const handleExport = (list) => {
    const exportGraph = settings.exportGraph || { forms: [], actors: [] };
    const itemsToExport = list.map(
      ({ id, title, color, formId, formTitle }) => ({
        id,
        title,
        color,
        formId,
        formTitle,
        addedAt: new Date().getTime() / 1000,
      })
    );
    const exportObjKey = `${objType}s`;
    const exportList = exportGraph[exportObjKey] || [];
    const newExportList = exportList.concat(itemsToExport);
    const uniqueItems = AppUtils.uniqueObjArray(newExportList, 'id');
    dispatch({
      type: UPDATE_USER_SETTINGS.REQUEST,
      payload: {
        accId: activeAccount.id,
        exportGraph: { ...exportGraph, [exportObjKey]: uniqueItems },
      },
      callback: () => {
        const count = list.length;
        const notificationText =
          count > 1
            ? t(mes.itemsAddedToExport, { count })
            : t(mes.itemAddedToExport);
        dispatch({
          type: SHOW_NOTIFY.REQUEST,
          payload: {
            id: AppUtils.createRid(),
            type: NOTIFY_LEVEL.SUCCESS,
            label: notificationText,
          },
        });
      },
    });
  };

  /**
   * Modal window for bulk sharing
   */
  const handleShowManageAccessModal = (listToShare, callback) => {
    let accessObjType = objType;
    if (objType === 'form') accessObjType = 'formTemplate';
    else if (objType === 'currency') accessObjType = 'account';
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'BulkManageAccessRules',
        data: {
          objType: accessObjType,
          items: listToShare.map((i) => ({
            objType: accessObjType,
            id: i.objId || i.id,
            access: i.access || [],
            ownerId: i.user ? i.user.id : i.ownerId,
            formId: i.formId,
          })),
        },
        callback,
      },
    });
  };

  const showNoAccessModal = (editableList, listNoAccess, callback) => {
    const count = editableList.length;
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'noPermissions',
          content: (
            <NoAccessItems
              label={t(mes.cantEditSomeItems, {
                count: listNoAccess.length,
              })}
              list={listNoAccess}
            />
          ),
          buttons: [
            {
              label: count > 0 ? 'continueWithoutItems' : 'ok',
              onClick: () => {
                dispatch({ type: DEL_MODAL, payload: 'InfoModal' });
                if (count) callback();
              },
            },
          ],
        },
      },
    });
  };

  /**
   * Update access in selected items
   */
  const updateSelectedItemsAccess = ({ editableList, rules, callback }) => {
    if (!callback) return;
    const copyList = editableList.slice();
    for (const obj of rules) {
      const findIndex = copyList.findIndex(
        (i) => i.objId === obj.id || i.id === obj.id
      );
      if (findIndex === -1) continue;
      const updatedItem = { ...copyList[findIndex] };
      copyList.splice(findIndex, 1, { ...updatedItem, access: obj.access });
    }
    callback(copyList);
  };

  const analyzeAccess = (bulkList) => {
    const editableList = [];
    const noAccessList = [];
    bulkList.forEach((item) => {
      if (objType === 'accName' || (item.privs && item.privs.modify)) {
        editableList.push({ ...item, type: objType });
      } else {
        noAccessList.push({ ...item, type: objType });
      }
    });
    return { editableList, noAccessList };
  };

  /**
   * Bulk grant access to objects
   */
  const handleShare = (bulkShareList, callback) => {
    const { editableList, noAccessList } = analyzeAccess(
      bulkShareList || selectedItems
    );
    const shareFunc = () =>
      handleShowManageAccessModal(editableList, (rules) =>
        updateSelectedItemsAccess({
          editableList,
          rules,
          callback: (listWithNewRules) => {
            if (callback) callback(listWithNewRules);
            setSelectedItems(listWithNewRules);
          },
        })
      );
    if (noAccessList.length) {
      showNoAccessModal(editableList, noAccessList, shareFunc);
    } else {
      shareFunc();
    }
  };

  /**
   * Bulk add accounts
   */
  const handleAddAccounts = (bulkAccList = selectedItems) => {
    const { editableList, noAccessList } = analyzeAccess(bulkAccList);
    const bulkFunc = () =>
      openAddAccountsModal({ actors: editableList.map((i) => i.id) });
    if (noAccessList.length) {
      showNoAccessModal(editableList, noAccessList, bulkFunc);
    } else {
      bulkFunc();
    }
  };

  /**
   * Bulk assign tags to accounts
   */
  const handleManageTags = (bulkList) => {
    const { editableList, noAccessList } = analyzeAccess(bulkList);
    const bulkFunc = () =>
      openManageAccountsTagsModal({ accounts: editableList });
    if (noAccessList.length) {
      showNoAccessModal(editableList, noAccessList, bulkFunc);
    } else {
      bulkFunc();
    }
  };

  /**
   * List items selection handler
   */
  const handleSelection = (id, selected, shiftPressed) => {
    const clickedItemIndex = list.findIndex((i) => i.id === id);
    if (clickedItemIndex === -1) return;
    if (!selected) {
      setSelectedItems(selectedItems.filter((item) => item.id !== id));
    } else if (shiftPressed) {
      const newSelectedItems = AppUtils.selectWithShift(
        list,
        selectedItems,
        clickedItemIndex
      );
      const addedSelection = newSelectedItems.filter(
        (i) => !selectedItems.find((j) => j.id === i.id)
      );
      setSelectedItems(selectedItems.concat(addedSelection));
    } else {
      setSelectedItems([...selectedItems, list[clickedItemIndex]]);
    }
  };

  return {
    selectedItems,
    setSelectedItems,
    updateSelectedItemsAccess,
    handleExport,
    handleShare,
    handleManageTags,
    handleAddAccounts,
    handleSelection,
  };
};

export default useBulkActions;
