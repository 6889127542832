import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import {
  cr,
  TextField,
  Button,
  Stack,
  Modal,
  ModalButtons,
  ModalContent,
  Space,
  Select,
  Label,
  MenuItem,
} from 'mw-style-react';
import {
  CREATE_ACTOR,
  UPDATE_ACTOR,
  GET_ACTOR,
} from '@control-front-end/common/constants/graphActors';
import AppUtils from '@control-front-end/utils/utils';
import FormUtils from '@control-front-end/utils/formUtils';
import getTranslation from '@control-front-end/utils/getTranslation';
import {
  GET_FORMS_TREE,
  KEY_FORM_PREFIX,
} from '@control-front-end/common/constants/forms';
import { INCOME_TYPE } from '@control-front-end/common/constants/actorAccounts';
import cn from 'classnames';
import Toggle from '@control-front-end/common/components/Toggle';
import SelectUsers from '@control-front-end/common/components/SelectUsers';
import ErrorBanner from '@control-front-end/common/components/ErrorBanner';
import {
  ActorGeneralFields,
  ActorModalIdChip,
  FilterAccPairSelect,
} from 'components';
import ConditionBlocks from './components/ConditionBlocks';
import SelectForms from './components/SelectForms';
import VisibleColumns from './components/VisibleColumns';
import DateRange from './components/DateRange';
import BalanceDateRange from './components/BalanceDateRange';
import ActorsStatus from './components/ActorsStatus';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './CreateActorsFilter.scss'; // NOSONAR

const EXCLUDED_CLASSES = [...FormUtils.getNoValueClass(), 'upload'];

function SpacedTitle({ ...props }) {
  return (
    <Space bottom size={Space.SIZE.xsmall}>
      <Label color={Label.COLOR.black} fontWeight="semibold" {...props} />
    </Space>
  );
}

const STATUS_TYPES = ['verified', 'rejected', 'pending', 'null'];

const BALANCE_ORDER = { ASC: 'ASC', DESC: 'DESC' };
const DISABLE_BALANCE_RANGE = true;

const TRANSLATIONS = {
  [BALANCE_ORDER.ASC]: mes.ascOrder,
  [BALANCE_ORDER.DESC]: mes.descOrder,
  [INCOME_TYPE.total]: mes.total,
  [INCOME_TYPE.credit]: mes.credit,
  [INCOME_TYPE.debit]: mes.debit,
};

const BALANCE_ORDER_OPTIONS = Object.values(BALANCE_ORDER).map((i) => ({
  value: i,
  label: getTranslation(TRANSLATIONS[i]),
}));

const INCOME_TYPE_OPTIONS = Object.values(INCOME_TYPE).map((i) => ({
  value: i,
  label: getTranslation(TRANSLATIONS[i]),
}));

const mapFilterData = {
  stateToServer: (filter) => {
    const copyFilter = AppUtils.omitBy(filter, AppUtils.isNil);
    if (copyFilter.status && copyFilter.status.length === STATUS_TYPES.length) {
      delete copyFilter.status;
    }

    if (Object.values(BALANCE_ORDER).includes(filter.orderBy)) {
      copyFilter.orderValue = copyFilter.orderBy;
      delete copyFilter.orderBy;
    }

    copyFilter.amountFrom =
      copyFilter.amountFrom === '' ? null : copyFilter.amountFrom;
    copyFilter.amountTo =
      copyFilter.amountTo === '' ? null : copyFilter.amountTo;

    // Skipp passing "total" income type, because it's default
    if (copyFilter.incomeType === INCOME_TYPE.total) {
      delete copyFilter.incomeType;
    }
    return copyFilter;
  },
  serverToState: (filter) => {
    const copyFilter = { ...filter };
    if (!copyFilter.selectedForms) {
      copyFilter.selectedForms = copyFilter.formId ? [copyFilter.formId] : [];
    }

    if (Object.values(BALANCE_ORDER).includes(filter.orderValue)) {
      copyFilter.orderBy = copyFilter.orderValue;
      delete copyFilter.orderValue;
    }
    return copyFilter;
  },
};

function CreateActorsFilter(props) {
  const { data, visibility, onClose, callback } = props;
  const { actorId, onSaveActor } = data;
  const t = useIntl();
  const dispatch = useDispatch();
  const systemForms = useSelector((state) => state.systemForms);
  const formView = useSelector((state) => state.formView);
  const [conditionRerender, setConditionRerender] = useState(0);
  const [filter, setFilters] = useState({
    formId: null,
    selectedForms: [],
    incomeType: INCOME_TYPE.total,
    amountFrom: null,
    amountTo: null,
    accountNameId: null,
    currencyId: null,
    orderBy: null,
    status: STATUS_TYPES,
    q: null,
  });

  const [visibleColumns, setVisibleColumns] = useState([]);
  const [accountPairSelected, setAccountPairSelected] = useState(false);

  const [errors, setErrors] = useState({});
  const [actorFilter, setActorFilter] = useState({});
  const [uatTree, setUatTree] = useState({});
  const [uatError, setUatError] = useState(null);
  const [formFields, setFormFields] = useState([]);
  const [failedSubmitAttempt, setFailedSubmitAttempt] = useState(false);

  const orderByBalance = Object.values(BALANCE_ORDER).includes(filter.orderBy);

  const makeFieldKey = (key, fId) => {
    if (key.includes(KEY_FORM_PREFIX) || +fId === +filter.formId) return key;
    return `${KEY_FORM_PREFIX}${fId}:${key}`;
  };

  const handleChangeFilterField = useCallback(({ id, value, error }) => {
    setFailedSubmitAttempt(false); // After filters changing give an opportunity to submit again
    setFilters((prevState) => ({ ...prevState, [id]: value }));
    setErrors((prevState) => ({ ...prevState, [id]: error || null }));
  }, []);

  const updateFilter = useCallback((filterUpdate = {}, errorsUpdate = {}) => {
    setFailedSubmitAttempt(false); // After filters changing give an opportunity to submit again
    setFilters((prevState) => ({ ...prevState, ...filterUpdate }));
    setErrors((prevState) => ({ ...prevState, ...errorsUpdate }));
  }, []);

  useEffect(() => {
    if (actorId) {
      dispatch({
        type: GET_ACTOR.REQUEST,
        payload: { id: actorId },
        callback: (actor) => {
          const { filter = '{}', fields = '[]' } = actor.data;

          const initialFilter = mapFilterData.serverToState(JSON.parse(filter));
          updateFilter(initialFilter);

          setAccountPairSelected(
            Boolean(initialFilter?.accountNameId && initialFilter?.currencyId)
          );
          setVisibleColumns(JSON.parse(fields));
          setActorFilter(actor);
        },
        errorCallback: () => setActorFilter({ accessDenied: true }),
      });
    }
  }, [actorId]);

  useEffect(() => {
    /**
     * Save the form tree in state
     */
    const handleSetUat = (graph) => {
      const forms = {};
      for (const i of graph) {
        if (i.data.type !== 'node') continue;
        if (i.data.accessDenied)
          i.data.title = `${i.data.title} (Access denied)`;
        forms[i.data.id] = i.data;
      }
      setUatTree(forms);
    };

    const firstSelectedForm = filter.selectedForms[0];
    if (filter.formId && firstSelectedForm) {
      dispatch({
        type: GET_FORMS_TREE.REQUEST,
        payload: { formId: firstSelectedForm },
        callback: handleSetUat,
        errorCallback: ({ message }) => setUatError(message),
      });
    }
  }, [filter.formId]);

  /**
   * Get all fields of selected forms
   */
  useEffect(() => {
    const itemsFields = [];
    for (const fId of filter.selectedForms) {
      const item = uatTree[fId];
      if (!item) continue;
      const newFormItems = FormUtils.getFormItems(item.form);
      for (const f of newFormItems) {
        if (EXCLUDED_CLASSES.includes(f.class)) continue;
        f.key = makeFieldKey(f.id, item.id);
        f.formId = item.id;
        f.formColor = item.color;
        f.formTitle = item.title;
        itemsFields.push(f);
      }
    }
    setFormFields(itemsFields);
  }, [uatTree, filter.selectedForms.length]);

  // Without any account selected there is no sance to define an amount limits
  useEffect(() => {
    if (!accountPairSelected) {
      updateFilter({ amountFrom: null, amountTo: null });
    }
  }, [accountPairSelected]);

  useEffect(() => {
    if (orderByBalance) {
      updateFilter({ from: null, to: null });
    }
  }, [orderByBalance]);

  /**
   * Create filter
   */
  const handleSaveAndSearch = () => {
    if (errors?.q) {
      setFailedSubmitAttempt(true);
      return;
    }

    /**
     * Remove unnecessary fields from columns
     */
    const filtredVisibleColumns = (copyFilter, visibleColumns) => {
      return visibleColumns.filter((i) => {
        const parsedKey = FormUtils.parseFormItemKey(i);
        return (
          !parsedKey.formId ||
          copyFilter.selectedForms.includes(+parsedKey.formId)
        );
      });
    };

    const copyFilter = mapFilterData.stateToServer(filter);
    const filtersFormId = systemForms.actorfilters.id;
    dispatch({
      type: actorId ? UPDATE_ACTOR.REQUEST : CREATE_ACTOR.REQUEST,
      payload: {
        id: actorId,
        title: actorFilter.title,
        picture: actorFilter.picture,
        color: actorFilter.color,
        formId: filtersFormId,
        formData: {
          filter: JSON.stringify(copyFilter),
          fields: JSON.stringify(
            filtredVisibleColumns(copyFilter, visibleColumns)
          ),
        },
        manageLayer: false,
      },
      callback: (actor) => {
        onClose();
        if (callback) callback(actor);
        if (onSaveActor) onSaveActor(filtersFormId, actor, actor.data);
      },
    });
  };

  return (
    <Modal
      id="createActorsFilterModal"
      styleName="m.modal l.modal"
      size="xlarge"
      onClose={onClose}
      label={t(mes[`${actorId ? 'update' : 'create'}Filter`])}
      visibility={visibility}
    >
      <ActorModalIdChip
        actorId={actorId}
        formId={systemForms.actorfilters.id}
      />
      <ModalContent styleName="m.modal__content l.content">
        <div styleName="l.general">
          <ActorGeneralFields
            title={actorFilter.title || ''}
            pictureUrl={actorFilter.pictureUrl}
            colors={[
              { type: 'actor', color: actorFilter.color },
              { type: 'form', color: formView.form?.color || null },
            ]}
            showOptional={false}
            onChange={(actorData) =>
              setActorFilter((prevActor) => ({ ...prevActor, ...actorData }))
            }
          />
        </div>
        <div styleName="l.filter">
          <div styleName={cn('l.filter__form', { disabled: uatError })}>
            {uatError ? (
              <Stack>
                <ErrorBanner
                  mainText={t(mes.mainTextActorFilter)}
                  extraText={t(mes.extraTextActorFilter)}
                />
                <TextField
                  value={uatError}
                  bordered={true}
                  label={t(mes.actorForms)}
                  onChange={() => {}}
                  visibility="disabled"
                />
              </Stack>
            ) : (
              <SelectForms
                formId={+filter.formId}
                selectedForms={filter.selectedForms}
                uatTree={uatTree}
                error={errors.formId}
                onChange={handleChangeFilterField}
              />
            )}
            <ConditionBlocks
              key={conditionRerender}
              formId={filter.formId}
              q={filter.q}
              formFields={formFields}
              makeFieldKey={makeFieldKey}
              onChange={handleChangeFilterField}
              onRemoveCondition={() => setConditionRerender((i) => i + 1)}
              displayErrors={failedSubmitAttempt}
              readOnly={!!uatError}
            />
            <VisibleColumns
              visibleColumns={visibleColumns}
              formFields={formFields}
              makeFieldKey={makeFieldKey}
              onChange={(v) => setVisibleColumns(v)}
            />
          </div>
          <Toggle isOpen={true} title={t(mes.advancedSettings)}>
            <Space top bottom>
              <Stack horizontal className={l.flexWrap}>
                <DateRange
                  key={`dateRange_${filter.orderBy}`}
                  from={filter.from}
                  to={filter.to}
                  orderBy={filter.orderBy}
                  errors={errors}
                  onChange={(filterUpdate) => {
                    if (filterUpdate.id === 'orderBy' && orderByBalance) {
                      updateFilter({
                        orderBy: filterUpdate.value,
                        from: null,
                        to: null,
                      });
                      return;
                    }
                    handleChangeFilterField(filterUpdate);
                  }}
                  extraOrderByOptions={BALANCE_ORDER_OPTIONS.map((item) => ({
                    ...item,
                    visibility: accountPairSelected ? 'visible' : 'disabled',
                  }))}
                  disabledFields={
                    orderByBalance ? ['period', 'from', 'to'] : []
                  }
                />
                <div className={l.column}>
                  <FilterAccPairSelect
                    label={<SpacedTitle value={t(mes.account)} />}
                    onChange={({ nameId: accountNameId, currencyId }) => {
                      if (
                        accountNameId === filter.accountNameId &&
                        currencyId === filter.currencyId
                      ) {
                        return;
                      }

                      updateFilter({
                        accountNameId,
                        currencyId,
                      });
                      setAccountPairSelected(
                        Boolean(accountNameId && currencyId)
                      );
                    }}
                    nameId={filter.accountNameId}
                    currencyId={filter.currencyId}
                  />
                </div>
                <Select
                  label={<SpacedTitle value={t(mes.type)} />}
                  bordered
                  unspaced
                  className={l.column}
                  value={filter.incomeType}
                  onChange={({ value }) => updateFilter({ incomeType: value })}
                >
                  {Object.values(INCOME_TYPE_OPTIONS).map(
                    ({ value, ...rest }) => (
                      <MenuItem key={value} value={value} {...rest} />
                    )
                  )}
                </Select>
                {cr([
                  accountPairSelected,
                  <>
                    <TextField
                      type="float"
                      bordered
                      unspaced
                      value={filter.amountFrom}
                      label={
                        <SpacedTitle
                          fontWeight="normal"
                          value={
                            <>
                              <b>{t(mes.balanceFrom)} </b>
                              {t(mes.optional)}
                            </>
                          }
                        />
                      }
                      className={l.column}
                      onChange={({ value }) =>
                        updateFilter({ amountFrom: value })
                      }
                    />
                    <TextField
                      type="float"
                      bordered
                      unspaced
                      value={filter.amountTo}
                      label={
                        <SpacedTitle
                          fontWeight="normal"
                          value={
                            <>
                              <b>{t(mes.balanceTo)} </b>
                              {t(mes.optional)}
                            </>
                          }
                        />
                      }
                      className={l.column}
                      onChange={({ value }) =>
                        updateFilter({ amountTo: value })
                      }
                    />
                    {cr([
                      !DISABLE_BALANCE_RANGE,
                      <BalanceDateRange
                        periodAmountFrom={filter.periodAmountFrom}
                        periodAmountTo={filter.periodAmountTo}
                        errors={errors}
                        onChange={handleChangeFilterField}
                      />,
                    ])}
                  </>,
                ])}
                <div styleName="l.filter__owner">
                  <SelectUsers
                    id="owner"
                    bordered
                    unspaced
                    multiselect={false}
                    hideCreateBtn={true}
                    fullModel={true}
                    label={<SpacedTitle value={t(mes.owner)} />}
                    placeholder={t(mes.enterOwnerName)}
                    value={filter.owner}
                    exclude={[]}
                    resettable
                    onChange={handleChangeFilterField}
                    onReset={() =>
                      handleChangeFilterField({ id: 'owner', value: [] })
                    }
                  />
                </div>
                <ActorsStatus
                  statuses={filter.status || STATUS_TYPES}
                  statusTypes={STATUS_TYPES}
                  onChange={handleChangeFilterField}
                />
              </Stack>
            </Space>
          </Toggle>
        </div>
      </ModalContent>
      <ModalButtons styleName="l.buttons">
        <Button
          label={t(mes.saveAndSearch)}
          size="smallplus"
          onClick={handleSaveAndSearch}
          disabled={filter.selectedForms.length === 0}
        />
      </ModalButtons>
    </Modal>
  );
}

CreateActorsFilter.propTypes = {
  data: PropTypes.object,
  callback: PropTypes.func,
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateActorsFilter;
