import {
  call,
  takeLatest,
  select,
  put,
  delay,
  actionChannel,
  take,
} from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import {
  RequestStatus,
  GET_ASYNC_TASKS,
  CREATE_ASYNC_TASK,
  DELETE_ASYNC_TASK,
  SET_ASYNC_TASKS_REQ_STATUS,
  UPDATE_ASYNC_TASK,
  WS_EXPORT,
  WS_IMPORT,
  ASYNC_TASK_UPDATE_DELAY,
  CANCELLABLE_TASK_STATUS_LIST,
} from 'constants';
import AppUtils from '@control-front-end/utils/utils';

/**
 * Create task model
 */
function* makeTasksModel(models) {
  const config = yield select((state) => state.config);
  const tasksModels = Array.isArray(models) ? models : [models];
  for (const model of tasksModels) {
    if (model.user) {
      model.user.avatar = AppUtils.makeUserAvatar(model.user, config);
    }
    try {
      model.details = JSON.parse(model.details);
    } catch (err) {
      model.details = null;
    }
  }
  return tasksModels;
}

/**
 * Get list of asynchronous tasks
 */
function* getAsyncTasks({ payload, callback }) {
  const { name, loadMore } = payload;
  const { list, limit, offset, endList, reqStatus } = yield select(
    (state) => state.tasks
  );
  if (reqStatus !== RequestStatus.SUCCESS || endList) return;
  const accounts = yield select((state) => state.accounts);
  const accId = payload.accId || accounts.active;
  // Set loading progress flag
  yield put({ type: SET_ASYNC_TASKS_REQ_STATUS, payload: 'inProgress' });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/tasks/list/${accId}/${name}`,
    queryParams: { limit, offset },
  });
  // Set loading completion flag
  yield put({ type: SET_ASYNC_TASKS_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  const newTasksList = yield call(makeTasksModel, data.data);
  const fullTasksList = loadMore ? list.concat(newTasksList) : newTasksList;
  yield put({
    type: GET_ASYNC_TASKS.SUCCESS,
    payload: {
      name,
      list: fullTasksList,
      offset: offset + limit,
      endList: !data.data.length,
    },
  });
  if (callback) callback(data.data);
}

/**
 * Create asynchronous task
 */
function* createAsyncTask({ payload, callback }) {
  const { name, taskBody } = payload;
  const { list, offset } = yield select((state) => state.tasks);
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/tasks/${accId}/${name}`,
    body: taskBody,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const newList = structuredClone(list);
  const [newTask] = yield call(makeTasksModel, data.data);
  newList.unshift(newTask);
  yield put({
    type: CREATE_ASYNC_TASK.SUCCESS,
    payload: {
      list: newList,
      offset: offset + 1,
    },
  });
  if (callback) callback(data);
}

/**
 * Cancel asynchronous task
 */
function* removeAsyncTask({ payload: taskId, callback }) {
  const { list } = yield select((state) => state.tasks);
  const currentTask = list.find((i) => i.id === taskId);
  if (currentTask && !CANCELLABLE_TASK_STATUS_LIST.includes(currentTask.status))
    return;
  const { data, result } = yield call(api, {
    method: 'delete',
    url: `/tasks/${taskId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const findTaskIndex = list.findIndex((i) => i.id === taskId);
  const newList = structuredClone(list);
  const [canceledTask] = yield call(makeTasksModel, data.data);
  newList.splice(findTaskIndex, 1, canceledTask);
  yield put({
    type: DELETE_ASYNC_TASK.SUCCESS,
    payload: {
      list: newList,
    },
  });
  if (callback) callback();
}

/**
 * Real-time changes for export/import tasks
 */
function* wsAsyncTaskProgress({ type, payload }) {
  const { name, list } = yield select((state) => state.tasks);
  const taskType = type.split('_')[1];
  if (name !== taskType.toLowerCase()) return;
  const { taskId, percent, status, details } = payload;
  const newList = structuredClone(list);
  const taskIndex = newList.findIndex((i) => i.id === taskId);
  if (taskIndex === -1) return;
  const findTask = newList[taskIndex];
  const updatedTask = {
    ...findTask,
    percent,
    status,
    details,
  };
  newList.splice(taskIndex, 1, updatedTask);
  yield put({
    type: UPDATE_ASYNC_TASK.SUCCESS,
    payload: { list: newList },
  });
}

/**
 * Sequential processing of all updates for import tasks
 */
export function* wsImportWatcherSaga() {
  const importReqChannel = yield actionChannel(WS_IMPORT);
  // prettier-ignore
  while (true) { // NOSONAR
    const action = yield take(importReqChannel);
    yield call(wsAsyncTaskProgress, action);
    yield delay(ASYNC_TASK_UPDATE_DELAY);
  }
}

/**
 * Sequential processing of all updates for export tasks
 */
export function* wsExportWatcherSaga() {
  const exportReqChannel = yield actionChannel(WS_EXPORT);
  // prettier-ignore
  while (true) { // NOSONAR
    const action = yield take(exportReqChannel);
    yield call(wsAsyncTaskProgress, action);
    yield delay(ASYNC_TASK_UPDATE_DELAY);
  }
}

function* tasks() {
  yield takeLatest(GET_ASYNC_TASKS.REQUEST, getAsyncTasks);
  yield takeLatest(CREATE_ASYNC_TASK.REQUEST, createAsyncTask);
  yield takeLatest(DELETE_ASYNC_TASK.REQUEST, removeAsyncTask);
}

export default tasks;
