import { createSelector } from '@reduxjs/toolkit';
import AppUtils from '@control-front-end/utils/utils';

const getReaction = (state, props) => {
  const reactions = state.reactions;
  return AppUtils.recursiveFind(reactions.list, props.id);
};

export const makeGetReaction = createSelector([getReaction], (r) => r);

export const getIsEventActor = createSelector(
  [(state) => state.actorView, (state) => state.systemForms],
  (actorView, systemForms) => systemForms.events.id === actorView.formId
);

export const getIsWidgetActor = createSelector(
  [(state) => state.actorView, (state) => state.systemForms],
  (actorView, systemForms) =>
    systemForms.widgets.id === actorView.formId ||
    systemForms.commentswidgets.id === actorView.formId
);
export const getIsScriptActor = createSelector(
  [(state) => state.actorView, (state) => state.systemForms],
  (actorView, systemForms) => actorView.formId === systemForms.scripts.id
);

export const getIsUserInMeeting = createSelector(
  [(state) => state.actorView?.meetingParticipants],
  (meetingParticipants = []) => {
    return (userId) => {
      const user = meetingParticipants.find((i) => i.id === userId);
      return !!user;
    };
  }
);
