import {
  all,
  call,
  put,
  delay,
  select,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import { RequestStatus } from 'constants';
import {
  GET_LAYER,
  LAYER_ACCESS,
  CLOSE_LAYER,
  MOVE_LAYER,
  GET_LAYER_BALANCE,
  GET_ACTOR_LAYER,
  GET_TREE_LAYER,
  SET_LAYER_PICTURE,
  SET_LAYER_SETTINGS,
  GET_ALL_LAYERS,
  GET_LAYERS_COMMON_ACTORS,
  MAKE_AUTO_LAYER_PAGE,
  SET_MAX_LAYER_NUMBER,
  GET_TRANSFERS_EDGES,
  GET_LAYER_STATS,
  TYPE_LAYER,
  ADD_LAYER,
} from '@control-front-end/common/constants/graphLayers';
import { DateUtils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import api from '@control-front-end/common/sagas/api';
import { uploadBase64 } from '@control-front-end/common/sagas/attachUtils';
import history from '@control-front-end/app/src/store/history';
import {
  makeGraphModels,
  sendReducerMsg,
  setActorsBalances,
  excludeAccessDenied,
  makeAutoLayerModel,
} from '../../../../sagas/graph/graphHelpers';
import { getActors } from '../../../../sagas/graph/graphNodes';
import { getGraphFolderLayers } from '../../../../sagas/graph/graphFolders';
import {
  getActiveLayer,
  makeLayerModel,
  normalizeTransfersEdgesWeight,
  updateLayerProp,
  updateLayerProps,
} from './layerHelpers';
import { manageLayerElements } from './layerElements';

/**
 * Remove layer from store
 */
export function* removeLayerFromStore({ layerId, type }) {
  const graphL = yield select((state) => state.graphLayers);
  const copyList = graphL.list
    .slice()
    .filter(({ isSystem, isStatic }) => !(isSystem && isStatic));
  const layerIndex = copyList.findIndex((i) => i.id === layerId);
  if (layerIndex === -1) return;
  copyList.splice(layerIndex, 1);
  let newActiveLayer = graphL.active;
  let newGraphUrl;
  if (layerId === graphL.active) {
    const { active: accId } = yield select((state) => state.accounts);
    let prevLayerIndex = layerIndex - 1;
    prevLayerIndex = prevLayerIndex < 0 ? 0 : prevLayerIndex;
    // Switch to another previous layer or to the list of actors
    if (!copyList.length) {
      newGraphUrl = `/actors_graph/${accId}/list`;
    } else {
      const prevLayer = copyList[prevLayerIndex];
      const { typeLayer } = prevLayer;
      newActiveLayer = prevLayer.id;
      if (typeLayer === 'trees') {
        const sp = newActiveLayer.split('_');
        newGraphUrl = `/actors_graph/${accId}/graph/${graphL.graphFolderId}/${typeLayer}/${sp[0]}/${sp[1]}`;
      } else {
        newGraphUrl = `/actors_graph/${accId}/graph/${graphL.graphFolderId}/${typeLayer}/${newActiveLayer}`;
      }
    }
  }
  if (newGraphUrl) history.replace(AppUtils.makeUrl(newGraphUrl));
  yield put({ type, payload: { list: copyList, active: newActiveLayer } });
  return copyList;
}

export function* addLayerToStore({ payload }) {
  const { layer, graphFolderId } = payload;
  const graphL = yield select((state) => state.graphLayers);

  const copyList = structuredClone(graphL.list);

  const existingLayerIndex = copyList.findIndex((i) => i.id === layer.id);

  if (existingLayerIndex !== -1) {
    copyList.splice(existingLayerIndex, 1, layer);
  } else {
    copyList.push({ ...layer, graphFolderId });
  }

  yield put({
    type: GET_ALL_LAYERS.SUCCESS,
    payload: {
      list: copyList,
      active: graphL.active,
    },
  });

  return copyList;
}

/**
 * Update layer settings
 */
function* setLayerSettings({ payload }) {
  const { layerId, settings } = payload;
  const list = yield call(updateLayerProps, { layerId, props: settings });
  yield put({ type: SET_LAYER_SETTINGS.SUCCESS, payload: { list } });
}

/**
 * Get common actors with linked layer
 */
function* getCommonActors({ payload, callback }) {
  const { sourceLayerId, targetLayerId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/graph_layers/common_actors/${sourceLayerId}/${targetLayerId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: GET_LAYERS_COMMON_ACTORS.SUCCESS });
  const { nodes } = yield call(makeGraphModels, {
    nodes: data.data.nodes,
    edges: [],
  });
  if (callback) callback(nodes);
}

/**
 * Check if the layer is new
 */
function* getLayerIsNew(layer) {
  const auth = yield select((state) => state.auth);
  const { createdAt, updatedAt, nodes = [], user = {} } = layer;
  const isOwnLayer = auth.id === user.id;
  const now = DateUtils.unixtime();
  return (
    isOwnLayer &&
    createdAt === updatedAt &&
    createdAt - now <= 60 &&
    !nodes.length
  );
}

/**
 * Get layer
 */
function* getLayer({ payload, callback, errorCallback }) {
  const { layerId, graphFolderId, addToLayers } = payload;
  yield put({ type: SET_MAX_LAYER_NUMBER, payload: 0 });
  const graphL = yield select((state) => state.graphLayers);
  const findLayerIndex = graphL.list.findIndex((i) => i.id === layerId);
  const copyList = structuredClone(graphL.list);
  let layer = copyList[findLayerIndex];
  let replaceIndex;
  let prevNodes;
  let prevEdges;
  const isEmptyLayer = layer && (!layer.nodes || !layer.nodes.length);
  // If the layer is not in the store or the layer has no nodes, or requests another type of layer,
  // then we make a request to get the layer otherwise we get the layer from the store
  if (findLayerIndex === -1 || isEmptyLayer) {
    const { result, data } = yield call(api, {
      method: 'get',
      url: `/actors/${layerId}`,
      handleErrCodes: [403],
    });

    if (result !== RequestStatus.SUCCESS) {
      if (errorCallback && data.statusCode === 403) {
        errorCallback(data);
        yield put({
          type: GET_LAYER.SUCCESS,
          payload: { list: copyList, active: null, graphFolderId },
        });
      }
      return;
    }
    replaceIndex = layer ? findLayerIndex : copyList.length + 1;
    layer = { ...layer, ...data.data };
    const graphEls = excludeAccessDenied(layer);
    prevNodes = graphEls.nodes;
    prevEdges = graphEls.edges;
    layer.typeLayer = 'layers';
  } else {
    replaceIndex = findLayerIndex;
    prevNodes = layer.nodes
      .filter((i) => i.status !== 'removed')
      .map((i) => i.data);
    prevEdges = layer.edges
      .filter((i) => i.status !== 'removed' && !i.data.transfers)
      .map((i) => i.data);
  }
  yield call(makeLayerModel, layer);
  const isNew = yield call(getLayerIsNew, layer);
  const { nodes, edges } = yield call(makeGraphModels, {
    nodes: prevNodes,
    edges: prevEdges,
    isTree:
      layer.data.type === 'tree' ||
      layer.typeLayer === 'trees' ||
      layer.typeLayer === 'actors',
  });
  if (addToLayers) {
    copyList.splice(replaceIndex, 1, {
      ...layer,
      graphFolderId,
      key: AppUtils.udid(),
      nodes,
      edges,
      isNew: layer.isNew || isNew,
    });
    const payloadRes = { list: copyList, active: layer.id };
    if (graphFolderId) payloadRes.graphFolderId = graphFolderId;
    yield put({ type: GET_LAYER.SUCCESS, payload: payloadRes });
  }
  if (callback) callback({ ...layer, nodes, edges });
}

/**
 * Change access rights to the layer
 */
function* manageLayerAccess({ payload }) {
  const { layerId, access } = payload;
  const list = yield call(updateLayerProp, {
    layerId,
    propId: 'access',
    value: access,
  });
  yield put({
    type: LAYER_ACCESS.SUCCESS,
    payload: { list },
  });
}

/**
 * Close layer
 */
function* closeLayer({ payload }) {
  const { layerId } = payload;
  yield call(removeLayerFromStore, { layerId, type: GET_ALL_LAYERS.SUCCESS });
}

/**
 * Move layer left/right
 */
function* moveLayer({ payload }) {
  const { layerId, side } = payload;
  const graphL = yield select((state) => state.graphLayers);
  const index = graphL.list.findIndex((i) => i.id === layerId);
  const copyList = graphL.list.slice(0);
  const newIndex = side === 'right' ? index + 1 : index - 1;
  if (newIndex < 0 || newIndex === graphL.list.length) return;
  [copyList[newIndex], copyList[index]] = [copyList[index], copyList[newIndex]];
  yield put({ type: MOVE_LAYER.SUCCESS, payload: { list: copyList } });
}

/**
 * Get balances of nested layer nodes
 */
function* getExpandedLayersBalance({
  nodes,
  currencyId,
  nameId,
  accountType,
  from,
  to,
}) {
  const layerActors = nodes.filter((i) => !!i.data.layerSettings.expand);
  if (!layerActors.length) return [];
  const reqs = [];
  for (const i of layerActors) {
    reqs.push(
      call(api, {
        method: 'get',
        url: `/graph_layers/balance/${i.data.actorId}`,
        queryParams: { currencyId, nameId, accountType, from, to },
      })
    );
  }
  const resp = yield all(reqs);
  return resp.reduce((prev, cur) => prev.concat(cur.data.data), []);
}

/**
 * Get balance of actors on the layer
 */
function* getLayerBalance({ payload, callback }) {
  const {
    layerId,
    currencyId,
    nameId,
    accountType = 'fact',
    from,
    to,
    currencyParams,
  } = payload;
  const graphL = yield select((state) => state.graphLayers);
  const layer = graphL.list.find((i) => i.id === layerId);
  if (!layer) return;
  let copyNodes = structuredClone(layer.nodes);
  if (currencyId && nameId) {
    const { result, data } = yield call(api, {
      method: 'get',
      url: `/graph_layers/balance/${layerId}`,
      queryParams: { currencyId, nameId, accountType, from, to },
    });
    if (result !== RequestStatus.SUCCESS) return;
    const expandBalances = yield getExpandedLayersBalance({
      nodes: copyNodes,
      currencyId,
      nameId,
      accountType,
      from,
      to,
    });
    const balances = data.data.concat(expandBalances);
    setActorsBalances({ balances, nodes: copyNodes, currencyParams });
  } else {
    copyNodes = copyNodes.map((node) => {
      if (node.data.type === 'node') {
        const nodeData = { ...node.data, balance: null };
        delete nodeData.balanceVector;
        return { ...node, status: 'updated', data: nodeData };
      }
      return node;
    });
  }
  yield sendReducerMsg({
    layerId,
    type: GET_LAYER_BALANCE.SUCCESS,
    payload: { nodes: copyNodes },
  });
  if (callback) callback();
}

function* setLayerPicture({ payload, callback }) {
  const { layerId, picture } = payload;
  const uploadedFile = yield call(uploadBase64, {
    payload: {
      file: picture,
      originalName: 'lalaPicture',
    },
  });

  if (!uploadedFile) return;

  const { result, data } = yield call(api, {
    method: 'put',
    url: `/graph_layers/picture/${layerId}`,
    body: { picture: uploadedFile.fileName },
    handleErrCodes: [403],
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data);
}

/**
 * Get actor layer or its tree layer
 */
function* getActorOrTreeLayer({ payload, callback, typeLayer, type }) {
  yield put({ type: SET_MAX_LAYER_NUMBER, payload: 0 });
  const graphL = yield select((state) => state.graphLayers);
  const edgeTypes = yield select((state) => state.edgeTypes);
  const accounts = yield select((state) => state.accounts);
  const { edgeType, actorId } = payload;
  const edgeTypeM = edgeTypes.find((i) => i.name === edgeType) || {};
  const edgeTypeId = !payload.edgeTypeId ? edgeTypeM.id : payload.edgeTypeId;
  payload.edgeTypeId = edgeTypeId;

  const fId = typeLayer === 'trees' ? `${actorId}_${edgeTypeId}` : actorId;
  const findLayerIndex = graphL.list.findIndex((i) => i.id === fId);
  const fLayer = graphL.list[findLayerIndex];
  const replaceIndex = fLayer ? findLayerIndex : graphL.list.length + 1;
  const actorsLayer = yield call(getActors, { payload, typeLayer });
  if (!actorsLayer) return;
  const id =
    typeLayer === 'trees'
      ? `${actorsLayer.activeElement}_${edgeTypeId}`
      : actorsLayer.activeElement;
  const createdAt = DateUtils.unixtime();
  const findActor = actorsLayer.nodes.find(
    (i) => i.id === actorsLayer.activeElement
  );

  let access = [];
  actorsLayer.nodes.forEach((i) => {
    const a = i.data.access;
    if (a) access = access.concat(a);
  });
  access = AppUtils.uniqueObjArray(access, 'userId');

  let name = findActor.data.title;
  if (actorsLayer.treeEdgeTypeInfo) {
    const { accountName, accountCurrency } = actorsLayer.treeEdgeTypeInfo;
    name = `${name}: ${(accountName || {}).name} (${
      (accountCurrency || {}).name
    })`;
  }

  const layer = {
    id,
    accId: accounts.active,
    title: name,
    type: typeLayer === 'trees' ? 'tree' : 'graph',
    ownerId: findActor.data.user.id,
    ownerName: findActor.data.user.nick,
    ownerType: 'user',
    createdAt,
    updatedAt: createdAt,
    typeLayer,
    isCustom: true,
    access,
    privs: {
      view: true,
      modify: true,
      remove: true,
    },
    ...actorsLayer,
  };
  yield call(makeLayerModel, layer);
  const copyList = graphL.list.slice();
  copyList.splice(replaceIndex, 1, layer);
  yield put({
    type,
    payload: { list: copyList, active: id },
  });
  if (callback) callback(layer);
}

/**
 * Get actor layer
 */
function* getActorLayer({ payload, callback }) {
  yield call(getActorOrTreeLayer, {
    payload,
    callback,
    typeLayer: 'actors',
    type: GET_ACTOR_LAYER.SUCCESS,
  });
}

/**
 * Get actor tree layer
 */
function* getTreeLayer({ payload, callback }) {
  yield call(getActorOrTreeLayer, {
    payload,
    callback,
    typeLayer: 'trees',
    type: GET_TREE_LAYER.SUCCESS,
  });
}

/**
 * Get list of layers
 */
function* getSimulatorLayers({ payload, callback }) {
  const { graphFolderId, limit } = payload;
  const graphLayers = yield select((state) => state.graphLayers);
  if (graphLayers.graphFolderId === graphFolderId) {
    callback(graphLayers.list);
    return;
  }
  const layers = yield call(getGraphFolderLayers, {
    payload: { graphFolderId, limit, starred: true },
  });
  yield makeLayerModel(layers, 'layers');
  yield put({
    type: GET_ALL_LAYERS.SUCCESS,
    payload: { list: layers, graphFolderId },
  });
  callback(layers);
}

function* makeAutoLayerPage({ payload, callback }) {
  const { type, root = {} } = payload;
  const stateId = type === 'actors_bag' ? 'formList' : 'actorsList';
  const itemsState = yield select((state) => state[stateId]);
  if (!itemsState) return;
  const { list, total } = itemsState;
  const autoLayer = yield call(makeAutoLayerModel, {
    id: type,
    list,
    root,
    total,
  });
  yield put({
    type: MAKE_AUTO_LAYER_PAGE.SUCCESS,
    payload: {
      list: [autoLayer],
      active: autoLayer.id,
      graphFolderId: null,
    },
  });
  if (callback) callback();
}

/**
 * Get transfers edges
 */
function* getTransfersEdges({ payload }) {
  const {
    layerId,
    nameId,
    currencyId,
    currencyName,
    currencyParams,
    from,
    to,
  } = payload;
  let trsEdges = [];
  if (nameId && currencyId) {
    const res = yield call(api, {
      method: 'get',
      url: `/graph_layers/transfers_edges/${layerId}`,
      queryParams: { nameId, currencyId, from, to },
      handleErrCodes: [403],
    });
    if (res.result !== RequestStatus.SUCCESS) return;
    trsEdges = res.data.data;
  }
  const layer = yield getActiveLayer();
  if (!layer) return;
  const nodesMap = layer.nodes.reduce((p, node) => {
    if (node.data.isTrace) return p;
    p[node.data.actorId] = node.data.laId;
    return p;
  }, {});
  const transfersEdges = layer.edges.filter((i) => i.data.transfers);
  const p = { layerId, withReq: false, subscribeBalances: false };
  const deleteBody = [];
  transfersEdges.forEach((i) => {
    deleteBody.push({
      action: 'delete',
      data: { id: i.id, model: i, type: 'edge' },
    });
  });
  yield call(manageLayerElements, { payload: { ...p, body: deleteBody } });
  const maxIndex = yield select((state) => state.layerActorsNumbers);
  const newMaxIndex = maxIndex - deleteBody.length;
  yield put({ type: SET_MAX_LAYER_NUMBER, payload: newMaxIndex || 0 });
  const mapWeight = normalizeTransfersEdgesWeight(trsEdges);
  const createBody = [];
  trsEdges.forEach((i) => {
    const formattedAmount = AppUtils.formattedAmount(i.weight, currencyParams);
    createBody.push({
      action: 'create',
      data: {
        id: i.id,
        model: {
          ...i,
          name: `${formattedAmount} ${currencyName || ''}`,
          laIdSource: nodesMap[i.source],
          laIdTarget: nodesMap[i.target],
          targetArrow: 'triangle',
          width: mapWeight[i.id],
          isNonInteractive: true,
          classes: ['transfers'],
        },
        type: 'edge',
      },
    });
  });
  yield delay(100);
  yield call(manageLayerElements, { payload: { ...p, body: createBody } });
  yield put({ type: GET_TRANSFERS_EDGES.SUCCESS });
}

/**
 * Get layer stats
 */
function* getLayerStats({ payload, callback }) {
  const { id, typeLayer, nodes = [], edges = [] } = payload;
  let nodesCount = nodes.length;
  let edgesCount = edges.length;
  if (typeLayer === TYPE_LAYER.layers) {
    const { result, data } = yield call(api, {
      method: 'get',
      url: `/graph_layers/stats/${id}`,
    });
    if (result !== RequestStatus.SUCCESS) return;
    nodesCount = data.data.nodes;
    edgesCount = data.data.edges;
  }
  if (callback) callback({ nodesCount, edgesCount });
  yield put({
    type: GET_LAYER_STATS.SUCCESS,
    payload: { nodesCount, edgesCount },
  });
}

function* layerManage() {
  yield takeLatest(GET_LAYER.REQUEST, getLayer);
  yield takeEvery(SET_LAYER_SETTINGS.REQUEST, setLayerSettings);
  yield takeEvery(LAYER_ACCESS.REQUEST, manageLayerAccess);
  yield takeEvery(MOVE_LAYER.REQUEST, moveLayer);
  yield takeEvery(CLOSE_LAYER.REQUEST, closeLayer);
  yield takeEvery(ADD_LAYER.REQUEST, addLayerToStore);
  yield takeLatest(GET_LAYER_BALANCE.REQUEST, getLayerBalance);
  yield takeLatest(GET_ACTOR_LAYER.REQUEST, getActorLayer);
  yield takeLatest(GET_TREE_LAYER.REQUEST, getTreeLayer);
  yield takeLatest(SET_LAYER_PICTURE.REQUEST, setLayerPicture);
  yield takeLatest(GET_ALL_LAYERS.REQUEST, getSimulatorLayers);
  yield takeLatest(GET_LAYERS_COMMON_ACTORS.REQUEST, getCommonActors);
  yield takeLatest(MAKE_AUTO_LAYER_PAGE.REQUEST, makeAutoLayerPage);
  yield takeLatest(GET_TRANSFERS_EDGES.REQUEST, getTransfersEdges);
  yield throttle(1000, GET_LAYER_STATS.REQUEST, getLayerStats);
}

export default layerManage;
