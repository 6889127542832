import { createReducer } from '@reduxjs/toolkit';
import {
  GET_TRANSCRIPTION,
  CLEAR_TRANSCRIPTIONS,
  SET_TRANSCRIPTIONS_REQ_STATUS,
} from '@control-front-end/common/constants/meeting';

const initialState = {
  list: [],
  limit: 30,
  offset: 0,
  endList: false,
  reqStatus: 'success',
};

export default createReducer(initialState, {
  [GET_TRANSCRIPTION.SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  [SET_TRANSCRIPTIONS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_TRANSCRIPTIONS]() {
    return initialState;
  },
});
