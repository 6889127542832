import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { TextField } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import Edit from '../Edit/Edit';
import mes from '../../intl';
import './Calendar.scss';

/**
 * Calendar Constructor Component
 */
class Calendar extends PureComponent {
  // Localization function
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  render() {
    return (
      <div styleName="calendar">
        <div styleName="calendar__title">
          <Edit {...this.props} />
        </div>
        <div styleName="calendar__main">
          <TextField
            leftIcon="calendar"
            visibility="disabled"
            placeholder={this.i(mes.calendarConstr)}
          />
        </div>
      </div>
    );
  }
}

Calendar.defaultProps = {
  extra: {},
};

Calendar.propTypes = {};

Calendar.contextTypes = {
  intl: intlShape,
};

export default injectIntl(Calendar);
