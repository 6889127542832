import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Label, Utils } from 'mw-style-react';
import './FormsErrors.scss';
import useIntl from 'useIntl';
import cn from 'classnames';
import mes from './intl';

/**
 * Navigation block for form field errors
 */
function FormsErrors({ formsErrors, containerEl }) {
  const t = useIntl();
  const [currFormErrorIndex, setCurrFormErrorIndex] = useState(0);
  const [showErrors, toggleErrors] = useState(true);

  const handleScrollToSelectedItem = (index) => {
    if (!formsErrors.length) return;
    setTimeout(() => {
      const elToScroll = document.getElementById(formsErrors[index].id);
      if (!elToScroll) return;
      const itemsPos = elToScroll.offsetTop;
      const parentToggleEl = elToScroll.closest('div[id^="toggle"]');
      // recalculate the position of the field with an error if the section is collapsed
      if (!itemsPos && parentToggleEl) {
        parentToggleEl.firstElementChild.click();
        handleScrollToSelectedItem(index);
        return;
      }
      const errorLabelEl = elToScroll.querySelector('[class~="error"]');
      const errorLabelPos = errorLabelEl ? errorLabelEl.offsetTop : 0;
      const scrollPos = itemsPos + errorLabelPos;
      Utils.scrollTo(containerEl, scrollPos, 200);
    }, 500);
  };

  useEffect(() => {
    setCurrFormErrorIndex(0);
    handleScrollToSelectedItem(0);
  }, []);

  useEffect(() => {
    if (formsErrors) setCurrFormErrorIndex(0);
  }, [formsErrors]);

  const goToPrevError = () => {
    if (currFormErrorIndex) {
      const newIndex = currFormErrorIndex - 1;
      setCurrFormErrorIndex(newIndex);
      handleScrollToSelectedItem(newIndex);
    }
  };

  const goToNextError = () => {
    if (currFormErrorIndex < formsErrors.length - 1) {
      const newIndex = currFormErrorIndex + 1;
      setCurrFormErrorIndex(newIndex);
      handleScrollToSelectedItem(newIndex);
    }
  };

  return showErrors ? (
    <div styleName="f__errors">
      <div>
        <Icon
          styleName="f__errors__close"
          type="close"
          size="small"
          onClick={() => toggleErrors(false)}
        />
        <Label
          value={
            formsErrors.length > 1
              ? t(mes.fieldsErrorsFound, { count: formsErrors.length })
              : t(mes.fieldErrorFound)
          }
        />
        <span styleName="f__errors__divider">|</span>
        <Label
          styleName="f__errors__field__name"
          value={`${currFormErrorIndex + 1} - ${
            (formsErrors[currFormErrorIndex] || formsErrors[0]).title
          }`}
        />
      </div>
      <div styleName="f__errors__controls">
        <div
          styleName={cn('f__errors__controls__icon', {
            disabled: !currFormErrorIndex,
          })}
          onClick={goToPrevError}
        >
          <Icon
            type="arrow"
            size="small"
            visibility={!currFormErrorIndex ? 'disabled' : 'visible'}
          />
        </div>
        <div
          styleName={cn('f__errors__controls__icon', {
            disabled: currFormErrorIndex === formsErrors.length - 1,
          })}
          onClick={goToNextError}
        >
          <Icon
            type="arrow"
            size="small"
            visibility={
              currFormErrorIndex === formsErrors.length - 1
                ? 'disabled'
                : 'visible'
            }
          />
        </div>
      </div>
    </div>
  ) : null;
}

FormsErrors.propTypes = {
  formsErrors: PropTypes.array.isRequired,
  containerEl: PropTypes.object.isRequired,
};

export default FormsErrors;
