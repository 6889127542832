import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Yup from 'yup';
import {
  TextField,
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
  Divider,
  withForm,
  DateUtils,
  Select,
  Label,
  Calendar,
  Utils,
  Chip,
  MenuItem,
  Banner,
} from 'mw-style-react';
import {
  DATE_FORMAT_3,
  DATE_FORMAT_4,
  NOTIFY_LEVEL,
  SHOW_NOTIFY,
} from 'constants';
import {
  ADD_TRANSACTION,
  CREATE_TRANSACTION,
  GET_ACCOUNT,
  ACCOUNT_TYPE,
} from '@control-front-end/common/constants/actorAccounts';
import AppUtils from '@control-front-end/utils/utils';
import useIntl from 'useIntl';
import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import cn from 'classnames';
import { CopyIdChip } from 'components';
import TransactionStatus from '../../../TransactionStatus';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './CreateCorrectiveTransaction.scss'; // NOSONAR

function CreateCorrectiveTransactionModal(props) {
  const {
    data,
    values,
    handleSubmit,
    handleOnChange,
    visibility,
    onClose,
    isSubmit,
    errors,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const { actor, account, transaction } = data;
  const acc = account[data.incomeType];
  const { currencyType: type, precision, symbol, currencyName } = acc;
  const currencyParams = { type, precision, symbol };
  const [privs, setPrivs] = useState(null);
  const noAccountAccess = privs && !privs.modify;

  useEffect(() => {
    dispatch({
      type: GET_ACCOUNT.REQUEST,
      payload: {
        id: transaction.accountId,
        withPrivs: true,
      },
      callback: ({ privs }) => setPrivs(privs),
    });
  }, [transaction.accountId]);

  const setSymbolLimit = (e) => {
    if (e.target.type === 'number') {
      e.target.value = e.target.value.slice(0, 17);
    } else {
      e.target.value = e.target.value.slice(0, 160);
    }
  };

  /**
   * Get corrected amount
   */
  const getCorrectedAmount = (currentAmount) => {
    const amount = parseFloat(values.amount || 0);
    return values.sign === '+'
      ? currentAmount + amount
      : currentAmount - amount;
  };

  return (
    <Modal
      styleName="m.modal"
      size="medium"
      onClose={onClose}
      label={t(mes.makeCorrectiveTransaction)}
      visibility={visibility}
    >
      <ModalContent id="modalContent" styleName="m.modal__content l.corrective">
        <div styleName="l.corrective__transaction">
          <div styleName="l.corrective__block">
            <Label
              styleName="l.corrective__block__label"
              value={t(mes.actorNameId)}
            />
            <div styleName="l.corrective__block__row">
              <ActorAvatar
                size="small"
                type="compact"
                pictureUrl={actor.pictureUrl}
                colorFilled={true}
                colors={actor.allColors}
              />
              <div styleName="l.corrective__block__col">
                <Label fontWeight="semibold" value={actor.title} />
                <CopyIdChip fontSize="small" type="actor" id={actor.id} />
              </div>
            </div>
          </div>
          <div styleName="l.corrective__block">
            <Label
              styleName="l.corrective__block__label"
              value={t(mes.accNameId)}
            />
            <div styleName="l.corrective__block__col">
              <Label
                value={`${account.accountName}, ${account.currencyName}`}
              />
              <CopyIdChip
                fontSize="small"
                type="account"
                id={transaction.accountId}
              />
            </div>
          </div>
          <div styleName="l.corrective__block">
            {DateUtils.toDate(transaction.originalDate / 1000, DATE_FORMAT_3)}
          </div>
          <div styleName="l.corrective__block">
            <div styleName="l.corrective__block__row">
              <TransactionStatus
                type={transaction.type}
                isDone={transaction.isDone}
              />
              <Chip
                styleName={cn(
                  'l.corrective__transaction__type',
                  acc.incomeType
                )}
                label={Utils.capitalize(acc.incomeType)}
                size="small"
                closeIcon={false}
                fontWeight="normal"
                type="rectangular"
              />
            </div>
          </div>
        </div>
        <Divider styleName="l.corrective__block__divider" />
        {noAccountAccess ? (
          <Banner
            size="medium"
            error={true}
            value={t(mes.noPairManageAccess)}
          />
        ) : null}
        <TextField
          styleName="m.textfield l.corrective__date"
          size="large"
          bordered={true}
          label={t(mes.originalDate)}
          value={
            values.originalDate
              ? DateUtils.toDate(values.originalDate, DATE_FORMAT_4)
              : ''
          }
          calendar={() => (
            <Calendar
              id="originalDate"
              size="small"
              time={true}
              value={{ startDate: values.originalDate }}
              onChange={({ id, value }) =>
                handleOnChange({ id, value: value.startDate })
              }
            />
          )}
        />
        <div styleName="l.corrective__amount">
          <TextField
            styleName="l.readOnly"
            label={t(mes.transactionValue)}
            bordered={true}
            value={`${AppUtils.formattedAmount(
              data.transaction.amount,
              currencyParams
            )} ${currencyName}`}
          />
          <Select
            id="sign"
            styleName="m.select l.corrective__sign"
            value={values.sign}
            bordered={true}
            onChange={handleOnChange}
          >
            <MenuItem label="+" value="+" />
            <MenuItem label="-" value="-" />
          </Select>
          <TextField
            id="amount"
            styleName="l.corrective__textfield m.textfield"
            type="float"
            label={t(mes.correctiveValue)}
            error={!!errors.amount}
            helperText={errors.amount}
            bordered={true}
            autoFocus={true}
            value={values.amount}
            onChange={handleOnChange}
          />
          =
          <TextField
            styleName="l.readOnly"
            label={t(mes.afterCorrection)}
            bordered={true}
            value={`${AppUtils.formattedAmount(
              getCorrectedAmount(transaction.amount),
              currencyParams
            )} ${currencyName}`}
          />
        </div>
        <Divider styleName="l.corrective__block__divider" />
        <div styleName="l.corrective__amount">
          <TextField
            styleName="l.readOnly"
            label={t(mes.currentBalance)}
            bordered={true}
            value={`${AppUtils.formattedAmount(
              transaction.accountAmount,
              currencyParams
            )} ${currencyName}`}
          />
          <Label value="→" />
          <TextField
            styleName="l.readOnly"
            label={t(mes.balanceCorrected)}
            bordered={true}
            value={`${AppUtils.formattedAmount(
              getCorrectedAmount(transaction.accountAmount),
              currencyParams
            )} ${currencyName}`}
          />
        </div>
        <TextField
          id="comment"
          styleName="m.textfield l.corrective__comment"
          size="large"
          label={t(mes.comment)}
          bordered={true}
          multiline={true}
          multilineType="text"
          rows={2}
          value={values.comment || ''}
          error={!!errors.comment}
          helperText={t(mes[errors.comment])}
          onChange={handleOnChange}
          onKeyPress={setSymbolLimit}
        />
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={t(mes.makeTransaction)}
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit || noAccountAccess ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

CreateCorrectiveTransactionModal.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
};

const CreateCorrectiveTransaction = withForm(
  {
    mapPropsToValues(props) {
      const formData = props.data || {};
      return {
        incomeType: formData.incomeType,
        accountType: formData.accountType,
        originalDate: DateUtils.unixtime(),
        amount: 0,
        sign: '+',
        comment: '',
      };
    },
    yup: Yup.object().shape({
      amount: Yup.number().notOneOf([0], 'not_zero').required('field_required'),
    }),
    handleSubmit: (values, dispatch, formActions, props) => {
      const { transaction, actor, account, incomeType, accountType } =
        props.data;
      const { sign, amount, comment, originalDate } = values;
      const { id: parentId, valueType = ACCOUNT_TYPE.number } = transaction;
      const acc = account[incomeType];
      const key = `${actor.id}+${account.nameId}+${account.currencyId}`;
      const fAmount = sign === '+' ? amount : -amount;
      const corTransaction = {
        parentId,
        amount: parseFloat(fAmount),
        originalDate: originalDate * 1000,
        comment,
      };
      formActions.onClose = props.onClose;
      dispatch({
        type: CREATE_TRANSACTION.REQUEST,
        payload: {
          id: acc.id,
          key,
          incomeType,
          accountType,
          transaction: corTransaction,
        },
        callback: (newTransaction) => {
          dispatch({
            type: SHOW_NOTIFY.REQUEST,
            payload: {
              id: AppUtils.createRid(),
              type: NOTIFY_LEVEL.SUCCESS,
              label: 'Transaction has been successfully created.',
            },
          });
          dispatch({
            type: ADD_TRANSACTION.REQUEST,
            payload: {
              incomeType,
              accountType,
              transaction: { ...newTransaction, valueType },
              actor,
              account,
            },
          });
          if (props.callback) props.callback(newTransaction);
        },
        formActions,
      });
    },
  },
  CreateCorrectiveTransactionModal
);

export default CreateCorrectiveTransaction;
