import React, { Fragment, useRef, useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useIntl from 'useIntl';
import {
  Chip,
  Clipboard,
  Icon,
  Label,
  PortalWrapper,
  Tooltip,
  Divider,
  Stack,
  Button,
  TextField,
} from 'mw-style-react';
import cn from 'classnames';
import AppUtils from '@control-front-end/utils/utils';
import FormUtils from '@control-front-end/utils/formUtils';
import Toggle from '@control-front-end/common/components/Toggle';
import { SET_MODAL } from 'constants';
import { useNotifications } from 'hooks';
import history from '@control-front-end/app/src/store/history';
import SmartChipPreview from '../SmartChipPreview';
import mes from './intl';
import './ActorCard.scss';

/**
 * Display actor data in tabular form
 * @returns {*}
 * @constructor
 */
function ActorCard(props) {
  const {
    type = 'default',
    wrapSections = false,
    id,
    title,
    forms = [],
    accessDenied,
  } = props;
  const dispatch = useDispatch();
  const t = useIntl();
  const cardContentRef = useRef();
  const singleSection =
    forms.length === 1 && forms[0].sections && forms[0].sections.length === 1;
  const [openAll, toggleOpenAll] = useState(singleSection);
  const [actionsEl, setActionsEl] = useState(null);
  const accId = AppUtils.getAccountId(history.location.pathname);

  const rootForm = useMemo(() => forms.find((f) => !f.parentId), [forms]);

  const { showNotification } = useNotifications();

  useEffect(() => {
    if (!cardContentRef.current) return;
    const cardEl = ReactDOM.findDOMNode(cardContentRef.current);
    const container = cardEl.closest('#actorViewContainer');
    if (!container) return;
    const el = container.querySelector('#portalActions');
    setActionsEl(el);
  }, [cardContentRef.current]);

  /**
   * Show the history of actor's field changes
   */
  const handleShowFieldHistory = (itemId, formId, isRoot) => {
    const field = isRoot
      ? `data.${itemId}`
      : `data.__form__${formId}:${itemId}`;
    const fields = FormUtils.makeHistoryFields(forms);
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'ChangesHistory',
        data: { objType: 'actor', objId: id, title, fields, field },
      },
    });
  };

  /**
   * Copy the field key to the clipboard
   */
  const handleCopyFieldKey = (key, { isRoot, id }) => {
    const fullFieldKey = isRoot ? key : `__form__${id}:${key}`;
    Clipboard.copy(fullFieldKey);
    showNotification('success', t(mes.keyCopied));
  };

  /**
   * View file preview
   */
  const showPreview = (file) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'FilePreview',
        data: {
          id: file.id,
          fileName: file.fileName,
          files: [file],
        },
      },
    });
  };

  const renderFilePreview = (file) => {
    const { title: fileTitle, type: fileType, fileName } = file;
    const filePath = AppUtils.makeAppUrl(`/download/${fileName}`);
    const isImage = fileType && fileType.indexOf('image') !== -1;
    return (
      <div styleName="card__field__file" onClick={() => showPreview(file)}>
        {isImage ? (
          <div styleName="card__field__file__img__wrap">
            <img styleName="card__field__file__img" src={filePath} alt="" />
          </div>
        ) : (
          <Icon type="file" />
        )}
        <Label value={fileTitle} />
      </div>
    );
  };

  const uploadedFileValue = (item) => {
    const filesList = Array.isArray(item.value) ? item.value : [];
    return filesList.map((file) => renderFilePreview(file));
  };

  const imageValue = (item) => {
    const file = {
      id: item.id,
      type: 'image',
      filePath: item.value,
      fileName: item.title,
    };
    return (
      <img
        styleName="card__field__img"
        src={item.value}
        alt={item.title}
        onClick={() => showPreview(file)}
      />
    );
  };

  const renderValue = (item, onShowHistory, onCopyKey, key, fieldKey) => {
    let value;
    if (item.class === 'upload') {
      value = uploadedFileValue(item);
    } else if (item.class === 'image') {
      value = imageValue(item);
    } else if (item?.extra?.multiline) {
      value = (
        <TextField
          bordered
          unspaced
          multiline
          multilineType="text"
          rows={item?.extra?.rows}
          readOnly
          value={item.value}
        />
      );
    } else {
      value =
        FormUtils.getFormItemValue(item, accId, {
          data: { id, key: fieldKey, formId: rootForm.id },
        }) || '-';
    }
    return (
      <div key={key} styleName={cn('card__field', { multiCol: !!item.row })}>
        <div styleName="card__field__label">
          <Label
            fontSize="xsmall"
            overflow={Label.OVERFLOW.break}
            value={item.title}
          />
          <Stack.H styleName="card__field__actions" size="none">
            <Tooltip value={t(mes.fieldHistory)}>
              <Button
                onClick={onShowHistory}
                type="quinary"
                size="xxsmall"
                icon={<Icon type="history" size="small" />}
              />
            </Tooltip>
            <Tooltip value={item?.id || ''}>
              <Button
                onClick={onCopyKey}
                type="quinary"
                size="xxsmall"
                icon={<Icon type="key" size="small" />}
              />
            </Tooltip>
          </Stack.H>
        </div>
        {value}
      </div>
    );
  };

  const renderSectionItems = ({ content, form, onShowHistory, onCopyKey }) => {
    const items = [];
    const rows = [];
    content.forEach((item, index) => {
      const rowId = item.row;
      if (!rowId) {
        const value = renderValue(
          item,
          () => onShowHistory(item.id),
          () => onCopyKey(item.id),
          index,
          form.isRoot ? item.id : `__form__${form.id}:${item.id}`
        );
        items.push(
          <div key={item.id} styleName="card__section__item">
            {value}
          </div>
        );
      } else if (!rows.includes(rowId)) {
        const cols = [];
        const rowItems = content.filter((i) => i.row === rowId);
        const count = rowItems.length;
        const width = `calc(${100 / count}% - ${(12 * (count - 1)) / count}px)`;
        rowItems.forEach((rItem, index) => {
          const value = renderValue(
            rItem,
            () => onShowHistory(rItem.id),
            () => onCopyKey(rItem.id),
            index,
            form.isRoot ? rItem.id : `__form__${form.id}:${rItem.id}`
          );
          cols.push(
            <div
              key={rItem.id}
              styleName="card__section__item"
              style={{ width }}
            >
              {value}
            </div>
          );
        });
        items.push(
          <div key={rowId} styleName="card__section__row">
            {cols}
          </div>
        );
        rows.push(rowId);
      }
    });
    return items;
  };

  const renderActorChip = () => (
    <Tooltip value={t(mes.copyId)}>
      <Chip
        styleName="card__content__chip"
        label={`ID: ${id.split('-')[0]}`}
        fontWeight="normal"
        icon="copy"
        closeIcon={false}
        type="rectangular"
        onClick={() => {
          Clipboard.copy(id);
          showNotification('success', t(mes.defaultIdCopied));
        }}
      />
    </Tooltip>
  );

  const renderSection = ({ key, content, form, onShowHistory, onCopyKey }) => (
    <div key={key} styleName="card__section">
      {renderSectionItems({ content, form, onShowHistory, onCopyKey })}
    </div>
  );

  const renderContent = () => {
    if (accessDenied) {
      return (
        <div styleName="card__content__accessDenied">
          <Label value={t(mes.accessDeniedActor)} />
        </div>
      );
    }

    return (
      <div styleName="card__content__forms">
        {forms.map((f) => (
          <Fragment key={`${id}_${f.id}`}>
            <div styleName="card__content__title">
              <Label
                fontWeight="semibold"
                value={
                  <Tooltip value={f.title}>
                    <span>{f.title}</span>
                  </Tooltip>
                }
              />
            </div>
            {f.sections
              ? f.sections.map((section, index) =>
                  wrapSections && section.content && section.content.length ? (
                    <Toggle
                      key={`${f.id}_${index}`}
                      theme="grey"
                      children={renderSection({
                        form: f,
                        content: section.content,
                        onShowHistory: (itemId) =>
                          handleShowFieldHistory(itemId, f.id, f.isRoot),
                        onCopyKey: (itemId) => handleCopyFieldKey(itemId, f),
                      })}
                      title={section.title || `Section ${index + 1}`}
                      isOpen={openAll}
                    />
                  ) : (
                    renderSection({
                      key: `${f.id}_${index}`,
                      content: section.content,
                      onShowHistory: (itemId) =>
                        handleShowFieldHistory(itemId, f.id, f.isRoot),
                      onCopyKey: (itemId) => handleCopyFieldKey(itemId, f),
                    })
                  )
                )
              : null}
            <Divider styleName="card__content__divider" />
          </Fragment>
        ))}
        {wrapSections && actionsEl ? (
          <PortalWrapper root={actionsEl} position="inherit">
            <Tooltip value={openAll ? t(mes.closeAll) : t(mes.openAll)}>
              <Icon
                styleName={cn('card__section__openall', { open: openAll })}
                type="arrows"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleOpenAll((prevAll) => !prevAll);
                }}
              />
            </Tooltip>
          </PortalWrapper>
        ) : null}
      </div>
    );
  };

  return (
    <div styleName={cn('card', type)}>
      <SmartChipPreview accId={accId} containerRef={cardContentRef} />
      <div styleName="card__wrap">
        <div
          ref={cardContentRef}
          styleName={cn('card__content', { wrap: !!wrapSections })}
        >
          {type === 'modal' ? renderActorChip() : null}
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

ActorCard.propTypes = {
  type: PropTypes.oneOf(['default', 'panel', 'modal', 'popup', 'iframe']),
  forms: PropTypes.array,
  id: PropTypes.string.isRequired,
  accessDenied: PropTypes.bool,
  wrapSections: PropTypes.bool,
};

export default ActorCard;
