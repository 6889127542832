// eslint-disable-next-line import/no-cycle
import { HOT_KEYS } from '@control-front-end/common/constants';
import AppUtils from '../utils';

export default {
  isDeleteBtn(e) {
    return (
      (AppUtils.isMacLike() && e.keyCode === HOT_KEYS.Backspace) ||
      e.code === HOT_KEYS.Delete
    );
  },

  isEditableElement(element) {
    const { tagName, isContentEditable } = element;
    return tagName === 'INPUT' || tagName === 'TEXTAREA' || isContentEditable;
  },

  ignoreHotKeys() {
    return this.isEditableElement(document.activeElement);
  },

  isCtrlOrMetaKey(e) {
    return e.ctrlKey || e.metaKey;
  },
};
