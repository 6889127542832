import { Utils } from 'mw-style-react';

export const GET_SMART_FORMS_TEMPLATES = Utils.createRequestTypes(
  'GET_SMART_FORMS_TEMPLATES'
);
export const CREATE_SMART_FORM = Utils.createRequestTypes('CREATE_SMART_FORM');

export const SMART_FORM_GRAPH_REF_PREFIX = 'smartForm_graph_actor@';

export const SMART_FORM_INSTALL_TIMEOUT = 60000;
export const CHECK_SMART_FORM_INSTALL_INTERVAL = 500;

export const SMART_FORM_INSTALL_TIMEOUT_ERROR =
  'Timeout. Something went wrong, please try again';
