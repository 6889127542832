import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'mw-style-react';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import mes from './intl';
import './ActorsNoAccess.scss';

/**
 * Component for displaying actors without access
 */
function ActorsNoAccess({ list, action = 'remove' }) {
  const t = useIntl();
  const count = list.length;

  const renderDiffItem = (item) => (
    <div key={item.id} styleName="actorsNoAccess__line">
      <Label fontWeight="semibold" value={`- ${item.title}`} />
    </div>
  );

  const renderContent = () => {
    const elsGroup = AppUtils.groupBy(list, 'type');
    return (
      <>
        {elsGroup.node ? (
          <div styleName="actorsNoAccess__list__block">
            <Label value={`${t(mes.actors)}:`} />
            {elsGroup.node.map((actor) => renderDiffItem(actor))}
          </div>
        ) : null}
        {elsGroup.edge ? (
          <div styleName="actorsNoAccess__list__block">
            <Label value={`${t(mes.actorsLinks)}:`} />
            {elsGroup.edge.map((edge) => renderDiffItem(edge))}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div styleName="actorsNoAccess">
      {action === 'remove' ? (
        <Label value={t(mes.itemsWontDeleted, { count })} />
      ) : (
        <Label value={t(mes.itemsWontEdited, { count })} />
      )}
      <div styleName="actorsNoAccess__list">{renderContent()}</div>
    </div>
  );
}

ActorsNoAccess.propTypes = {
  list: PropTypes.array.isRequired,
  action: PropTypes.oneOf(['modify', 'remove']),
};

export default ActorsNoAccess;
