import { call, put } from 'redux-saga/effects';
import { CREATE_EDGE } from '@control-front-end/common/constants/graphActors';
import { NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import { getGraphEls, makeGraphModels } from './graphHelpers';

/**
 * Recreate the same connections, but with a different edgeTypeId
 */
export function* createNewEdges(edges, edgeTypeId) {
  for (const i of edges) {
    const { laIdSource, laIdTarget, source, target, name } = i;
    const subscribeBalances = false;
    yield put({
      type: CREATE_EDGE.REQUEST,
      payload: {
        source,
        target,
        laIdSource,
        laIdTarget,
        edgeTypeId,
        name,
        subscribeBalances,
      },
    });
  }
}

/**
 * Collapse grouped actors into a real graph
 */
export function* removeGroupTplActors({ nodes = [], edges = [] }) {
  const graphNodes = yield call(getGraphEls, 'nodes');
  edges = JSON.parse(JSON.stringify(edges));
  nodes = JSON.parse(JSON.stringify(nodes));
  for (const i of edges) {
    if (i.realSourceActorId) {
      i.sourceActorId = i.realSourceActorId;
      i.source = i.realSourceActorId;
      delete i.realSourceActorId;
    } else if (i.source.indexOf('_nodeMore') !== -1) {
      const sourceNode = graphNodes.find((n) => n.id === i.source);
      i.sourceActorId = sourceNode.data.actorId;
      i.source = sourceNode.data.actorId;
    }
  }
  return { nodes, edges };
}

/**
 * Graph elements after pasting
 */
export function* getGraphElsAfterPaste({
  nodes,
  edges,
  typeLayer,
  sameEdgeType,
  isTree,
}) {
  const copyNodes = yield call(getGraphEls, 'nodes');
  const { nodes: graphNodes, edges: graphEdges } = yield call(makeGraphModels, {
    nodes,
    edges,
    isTree,
  });
  if (typeLayer === 'layers') {
    copyNodes.push(...graphNodes);
  } else {
    const noDuplicateNodes = graphNodes.filter(
      (n) => !copyNodes.find((i) => i.id === n.id)
    );
    copyNodes.push(...noDuplicateNodes);
  }
  if (!sameEdgeType) return { nodes: copyNodes };
  const copyEdges = yield call(getGraphEls, 'edges');
  if (typeLayer === 'actors') {
    const hiddenEdges = yield getGraphEls('hiddenEdges');
    const visibleEdges = graphEdges.filter(
      (i) => !hiddenEdges.includes(i.data.edgeId)
    );
    copyEdges.push(...visibleEdges);
  } else if (typeLayer === 'layers') {
    copyEdges.push(...graphEdges);
  }
  return { nodes: copyNodes, edges: copyEdges };
}

/**
 * Show notification of error on graph
 */
export function* showNotifyGraphError({ errorId, label }) {
  yield put({
    type: SHOW_NOTIFY.REQUEST,
    payload: {
      id: errorId,
      type: NOTIFY_LEVEL.ERROR,
      label,
    },
  });
}
