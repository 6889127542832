import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import AppUtils from '@control-front-end/utils/utils';
import './ImagePreview.scss';

class ImagePreview extends PureComponent {
  constructor(props) {
    super(props);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(e) {
    const { onClose } = this.props;
    const isEsc = e.key === 'Escape';
    if (isEsc) onClose();
  }

  // Localization function
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  render() {
    const { onClose, data } = this.props;
    return (
      <div styleName="imgModal" onClick={onClose}>
        <img alt="" src={data.src} width={data.width} height={data.height} />
      </div>
    );
  }
}

ImagePreview.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object.isRequired,
};

ImagePreview.contextTypes = {
  intl: intlShape,
};

export default injectIntl(ImagePreview);
