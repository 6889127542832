import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { mergeWith } from 'lodash';
import { useDispatch } from 'react-redux';
import { Menu, Card, Icon, Button, Tooltip, Popover } from 'mw-style-react';

import { useIntl, useGetGraphCoord, useNotifications } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import ResizableCells from '@control-front-end/common/components/ResizableCells';
import {
  EXP_NODE,
  GRAPH_NODE_SIZE,
} from '@control-front-end/common/constants/graphActors';
import { GRAPH_DISCOVERY } from '@control-front-end/common/constants/graphLayers';
import Dashboard from '@control-front-end/common/components/Dashboard';
import useGridExpNodesSnap from '@control-front-end/app/src/components/GraphEngine/useGridExpNodesSnap';
import ContextMenuItem from '@control-front-end/app/src/routes/ActorsGraph/components/ContextMenuItem';
import mes from './intl';
import scss from './ExpandedChart.scss';

const expandSizeType = EXP_NODE.sizeType.chart;

/**
 * Component to render dashboard actor on layer
 * @param el
 * @param graph
 * @param isLayerReadOnly
 * @param handleRemoveNode
 * @param handleRenameNode
 * @param handleMakeActiveElement
 * @param handleSaveActorLayerSettings
 * @returns {JSX.Element}
 * @constructor
 */
function ExpandedChart({
  el,
  graph,
  isLayerReadOnly = false,
  handleRemoveNode,
  handleRenameNode,
  handleMakeActiveElement,
  handleSaveActorLayerSettings,
}) {
  const t = useIntl();
  const rootRef = useRef();
  const dashboardContainerRef = useRef();

  const dragRef = useRef();
  const cellsWithNumberRef = useRef();
  const elementPosition = { ...el.position() };
  const [transformPosition, setTransformPosition] = useState(elementPosition);
  const [zoomPanParams, setZoomPanParams] = useState({});
  const nodeId = el.id();
  const {
    actorId,
    laId,
    layerSettings = {},
    privs,
    readOnly,
    title = '',
  } = el.data();
  const canEdit = !readOnly && privs && privs.modify;
  const [menu, toggleMenu] = useState(false);

  const dispatch = useDispatch();
  const { showNotification } = useNotifications();

  const closeMenu = async () => toggleMenu(false);

  useEffect(() => {
    setTransformPosition(elementPosition);
  }, [elementPosition.x, elementPosition.y]);

  /**
   * Open actors panel
   */
  const handleOpenPanel = () => {
    handleMakeActiveElement({
      e: { target: el },
      graph,
      extra: { openPanel: true },
    });
    closeMenu();
  };

  /**
   * Chart node position change
   */
  const handleMoveNavigator = () => {
    setTransformPosition({ ...el.position() });
  };

  /**
   * Pin/unpin chart node
   */
  const handlePinNode = () => {
    handleSaveActorLayerSettings({
      id: nodeId,
      settings: {
        pin: !layerSettings.pin,
      },
    });
    closeMenu();
  };

  const handleCollapseChart = () => {
    handleSaveActorLayerSettings(
      {
        id: nodeId,
        settings: { expand: false },
      },
      () =>
        dispatch({
          type: GRAPH_DISCOVERY.EXPAND_NODE.REQUEST,
          expand: false,
          position: el.position(),
          offset: layerSettings.offset,
        })
    );
  };

  /**
   * Save chart size after resize stop
   */
  const handleResizeStop = (e, { size: newSize, offset }) => {
    if (
      newSize?.height < EXP_NODE.size[expandSizeType].height ||
      newSize?.width < EXP_NODE.size[expandSizeType].width
    ) {
      handleCollapseChart();
      return;
    }
    if (newSize?.width)
      cellsWithNumberRef?.current?.cellsWithNumberChanged(
        newSize.width / GRAPH_NODE_SIZE
      );
    handleSaveActorLayerSettings(
      {
        id: nodeId,
        settings: {
          height: Math.round(newSize.height),
          width: Math.round(newSize.width),
          offset,
        },
      },
      () =>
        dispatch({
          type: GRAPH_DISCOVERY.EXPAND_NODE.REQUEST,
          expand: layerSettings.expand,
          position: el.position(),
          offset: mergeWith(
            { ...offset },
            layerSettings.offset || EXP_NODE.offset.none,
            (a, b) => a - b
          ),
        })
    );
  };

  const onExtraCellsCountChanged = (extraCellsCount) => {
    const newWidth =
      extraCellsCount * GRAPH_NODE_SIZE + EXP_NODE.size[expandSizeType].width;
    handleSaveActorLayerSettings({
      id: nodeId,
      settings: {
        height: layerSettings.height,
        width: newWidth,
        offset: layerSettings.offset,
      },
    });
  };

  const handleOnMoveUp = () => {
    setTransformPosition({ ...el.position() });
  };

  const handleOnMoveDown = () => {
    const expN = document.querySelectorAll('.chart');
    expN.forEach((node) => node.style.removeProperty('z-index'));
    if (rootRef.current) rootRef.current.style['z-index'] = 1;
  };

  useGridExpNodesSnap({
    cy: graph,
    node: el,
    ref: dragRef,
    isDraggable: !layerSettings.pin,
    onDrag: handleMoveNavigator,
    onUp: handleOnMoveUp,
    onDown: handleOnMoveDown,
  });

  const handleZoomPanChange = useCallback(
    ({ pan, zoom }) => {
      setZoomPanParams({ pan, zoom });
    },
    [setZoomPanParams]
  );

  useGetGraphCoord(graph, handleZoomPanChange);

  /**
   * Render menu of layer actor
   */
  const renderMenu = () => {
    const canRemove = el.data('privs').remove;
    return (
      <Menu
        size="small"
        onClick={() => closeMenu()}
        onClose={() => setTimeout(closeMenu, 10)}
      >
        <ContextMenuItem
          icon="panel_view"
          label={t(mes.openActorPanel)}
          handleClick={handleOpenPanel}
        />
        <ContextMenuItem
          icon="download"
          label={t(mes.exportToPng)}
          handleClick={async () => {
            await closeMenu();
            AppUtils.exportToPng({
              rootElement: dashboardContainerRef.current,
              title,
              onSuccess: () =>
                showNotification('success', t(mes.exportSuccessText)),
            });
          }}
        />
        <ContextMenuItem
          icon="copy"
          label={t(mes.copyImage)}
          handleClick={async () => {
            await closeMenu();
            AppUtils.copyToClipboardPng({
              rootElement: dashboardContainerRef.current,
              closeMenu,
              onSuccess: () => showNotification('success', t(mes.copiedText)),
            });
          }}
        />
        <ContextMenuItem
          icon="trash"
          label={t(mes.remove)}
          visibility={!canRemove ? 'disabled' : 'visible'}
          handleClick={() => handleRemoveNode(el.id(), readOnly)}
        />
      </Menu>
    );
  };

  return (
    <ResizableCells
      initialSize={{
        width: layerSettings.width || EXP_NODE.size[expandSizeType].width,
        height: layerSettings.height || EXP_NODE.size[expandSizeType].height,
      }}
      width={layerSettings.width || EXP_NODE.size[expandSizeType].width}
      resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
      onResizeStop={handleResizeStop}
      minConstraints={[GRAPH_NODE_SIZE, GRAPH_NODE_SIZE]}
      position={{ ...transformPosition }}
      offset={layerSettings.offset || EXP_NODE.offset[expandSizeType]}
    >
      <Card forwardRef={rootRef} styleName="chart" className="chart">
        <Dashboard
          actorId={actorId}
          laId={laId}
          headerRef={dragRef}
          draggable={!layerSettings.pin}
          canEdit={canEdit}
          showEditButton
          zoomPanParams={zoomPanParams}
          title={title}
          onExtraCellsCountChanged={onExtraCellsCountChanged}
          cellsWithNumberRef={cellsWithNumberRef}
          dashboardContainerRef={dashboardContainerRef}
          onTitleChange={(value) =>
            handleRenameNode({ id: nodeId, title: value })
          }
          actions={
            <>
              <Popover
                anchors={{
                  binding: Popover.ANCHOR.right_top,
                  content: Popover.ANCHOR.right_top,
                }}
                content={menu ? renderMenu() : null}
              >
                <Tooltip value={t(mes.more)}>
                  <div data-draggable="false" onClick={() => toggleMenu(!menu)}>
                    <Icon type="more" />
                  </div>
                </Tooltip>
              </Popover>
              <Tooltip
                key={`${nodeId}_${layerSettings.pin ? 'unpin' : 'pin'}`}
                value={layerSettings.pin ? t(mes.unpin) : t(mes.pin)}
              >
                <Button
                  size="small"
                  icon="pin"
                  rounded
                  className={cn(scss.pinButton, {
                    [scss.pinned]: layerSettings.pin,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handlePinNode();
                  }}
                />
              </Tooltip>
              <Tooltip value={t(mes.collapse)}>
                <div
                  data-draggable="false"
                  onClick={(e) => {
                    if (isLayerReadOnly) return;
                    e.stopPropagation();
                    handleCollapseChart();
                  }}
                >
                  <Icon size="small" type="close" />
                </div>
              </Tooltip>
            </>
          }
        />
      </Card>
    </ResizableCells>
  );
}

ExpandedChart.propTypes = {
  el: PropTypes.object.isRequired,
  graph: PropTypes.object,
  isLayerReadOnly: PropTypes.bool,
  handleRemoveNode: PropTypes.func.isRequired,
  handleRenameNode: PropTypes.func.isRequired,
  handleMakeActiveElement: PropTypes.func.isRequired,
  handleSaveActorLayerSettings: PropTypes.func.isRequired,
};

export default ExpandedChart;
